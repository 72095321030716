import { EventEmitter, Injectable, Output, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

export enum VisibilityState {
  PAGE_HIDDEN,
  PAGE_VISIBLE
}

@Injectable()
export class VisibilityService {

  constructor(@Inject(DOCUMENT) private document: Document & {msHidden?: boolean; webkitHidden?: boolean}) {
    this.setupVisibilityChange();
  }

  @Output() message$: EventEmitter<VisibilityState> = new EventEmitter();

  private handleVisibilityChange(hidden: string) {
    if (this.document[hidden]) {
      this.message$.emit(VisibilityState.PAGE_HIDDEN);
    } else {
      this.message$.emit(VisibilityState.PAGE_VISIBLE);
    }
  }

  public setupVisibilityChange() {
    // Set the name of the hidden property and the change event for visibility
    var hidden: string;
    var visibilityChange: string;
    if (typeof this.document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
      hidden = "hidden";
      visibilityChange = "visibilitychange";
    } else if (typeof this.document.msHidden !== "undefined") {
      hidden = "msHidden";
      visibilityChange = "msvisibilitychange";
    } else if (typeof this.document.webkitHidden !== "undefined") {
      hidden = "webkitHidden";
      visibilityChange = "webkitvisibilitychange";
    }

    // Warn if the browser doesn't support addEventListener or the Page Visibility API
    if (typeof document.addEventListener === "undefined" || hidden === undefined) {
      console.log("Page visibility is not supported in this browser.");
    } else {
      // Handle page visibility change
      document.addEventListener(visibilityChange, () => this.handleVisibilityChange(hidden), false);
    }
  }
}