<div class="container-fluid container-horiz-padded container-vert-padded no-gutters">

  <div class="row">
    <div class="col-1 col-md-3 col-lg-2 buyer-link-container d-none d-md-block">
      <div class="fixed">
        <div><a routerLink="/buyers" fragment="auction-format" class="buyer-link buyer-link-focused">Auction Format</a></div>
        <div class="mt-3"><a routerLink="/buyers" fragment="when-you-win" class="buyer-link">When You Win</a></div>
        <div><a routerLink="/buyers" fragment="delivery-payment" class="buyer-link buyer-link-focused">Payment</a></div>
        <div><a routerLink="/buyers" fragment="terms-and-conditions" class="buyer-link buyer-link-focused">Terms & Conditions</a></div>
        <div><a routerLink="/buyers" fragment="privacy" class="buyer-link buyer-link-focused">Privacy</a></div>
      </div>
    </div>
    <div class="col-11 col-md-9 col-lg-10">
      <app-section-header class="anchor" id="auction-format" type="primary">Auction Format</app-section-header>

      <p>We use the horse race style closing format.</p>

      <p>When the auction time expires, all lots will close simultaneously. Extended bidding will continue after the auction closes until there are no bids on any lot for a time interval of between 5 and 1 minutes.</p>

      <p>A few benefits to this style of auction are: </p>

      <ol>
        <li>the buyer has the option to switch to any lot(s) in the offering if he/she is outbid on his/her current choice; </li>
        <li>the buyer does not have to deal with the lot order of the sale because all lots are offered and bid on simultaneously; and </li>
        <li>the buyer has more choice and more time to make their decision.</li>
      </ol>

      <p><strong>Extended Bidding</strong> means that the auction is not declared closed until there has been a set period of inactivity on all lots. If there is any bidding during the inactivity period, the auction is extended. Time remaining is displayed at the auction and lot level. Even when the bidding is extened, there will be no bids accepted after 11:59 in the time zone of the auction.</p>

      <p>Examples:</p>
      <ol>
        <li>Auction Closes at 7pm. There is no bidding from 6:55 pm to 7pm. All lots are declared closed and the sale ends at 7pm.</li>
        <li>Auction closes at 7pm. There is a bid placed at 6:58pm. The Auction is extended 5 additional minutes. No more bidding occurs. All lots are declared closed and the sale ends at 7:03pm.</li>
        <li>Auction Closes at 7pm. There is a bid placed at 6:58pm. The Auction is extended by 5 minutes until 7:03 pm. Another bid is placed at 7:02 pm. The Auction is extended by 5 minutes until 7:07 pm. No more bids are placed. All lots are declared closed and the sale ends at 7:07 pm        </li>
      </ol>

      <app-section-header class="anchor" id="when-you-win" type="primary">When You Win</app-section-header>
      <img src="../../../assets/icons/when-you-win.png" />

      <p class="mt-2">When the sale ends, the highest bidder wins the lot and pays for the item. Remember, a bid is a binding contract. When you bid on an item in an auction, you're committing to buy it if you win.</p>

      <app-section-header class="anchor" id="delivery-payment" type="primary">Payment</app-section-header>

      <p>Payments must be coordinated with the seller upon auction close. Failure to pay may mean being denied access to all future auctions on G2 Land Auctions.</p>

      <app-section-header class="anchor" id="terms-and-conditions" type="primary">Terms & Conditions</app-section-header>

      <p>
        The general Terms and Conditions for the G2 Live LLC Auction Platform may be superceded by a terms addendum for each sale and seller. Please read and review the Addendum posted on the website for Seller.
      </p>
      
      <p>
        <a class="link-text" href="https://mossycreekfarmsindy.com/wp-content/uploads/2021/10/auction-terms-addendum-corrected-final-10-13-21.pdf" target="_blank">ADDENDUM TO AUCTION TERMS (EXTERNAL LINK)</a>
      </p>
      
      <p>
        “Seller” shall mean the party who is placing the lot(s) or merchandise for sale.<br>
        “Bidder” shall mean the party submitting an offer to purchase the lot(s) or merchandise for sale.<br>
         “Buyer” shall mean “Bidder” who has submitted the successful offer to purchase the lot(s) or merchandise for sale.<br>
        “Sale Manager/Management” shall mean G2 Live LLC.<br>
      </p>

      <p>
        G2 Live LLC makes no warranty, express or implied, as to the information used to describe lots or any other merchandise sold on this site. All lots and merchandise are presented as described by the Seller. LOTS ARE SOLD AS IS, WHERE IS AND WITH ALL FAULTS. Bidder acknowledges that all lots were available for inspection prior to the auction and, by these terms, it is the Bidder’s responsibility to personally inspect all lots that he/she or it may purchase. Failure on part of the Bidder to inspect lots, or otherwise be fully informed as to the nature, quality and condition of lots purchased, will not constitute grounds for any type of refund or termination of the sale agreement between the Buyer and Seller. All lots and merchandise sold become the property of Buyer at the conclusion of the auction. Sale Management is not responsible for the condition of lots sold and purchased at the time of sale.
      </p>        
      <p>
        THE IMPLIED WARRANTIES OF MERCHANTABILITY, SUITABILITY, AND FITNESS FOR A PARTICULAR PURPOSE, AND ALL OTHER WARRANTIES, EITHER EXPRESS OR IMPLIED, ARE SPECIFICALLY EXCLUDED from this contract for sale and this transaction and shall NOT apply to the merchandise that is the subject of this sale and this transaction. Buyers hereby agree to indemnify and hold G2 Live LLC, its members, parent subsidiaries, affiliates, officers and employees, harmless from any claim or demand, including reasonable attorney’s fees, against any and all causes of action which relate to the condition of the lots, merchandise or the descriptions thereof sold on the G2 Live LLC website online auction platform.
      </p>

      <h6>Bidding</h6>
      <p>
        All bidding and payments are in U.S. currency unless otherwise stated in Terms and Conditions or on the home page of that particular sale. Bidding is done in increments, which may vary by both species and individual lot(s) for each sale. by lot, or during a sale. A Bidder may be outbid at any time before the close of an auction. Bidders will NOT automatically receive notification if they have been outbid; thus, careful monitoring of the auction is encouraged when bidding. Unless otherwise indicated, lots and merchandise offered on the G2 Live LLC site platform will sell subject to a reserve price established by the Seller. Both the Seller and sale management may bid on the Seller’s behalf in an amount not to exceed the reserve price. Seller is under no obligation to sell lots or merchandise if a bid does not meet the minimum acceptable price set by Seller.
      </p>

      <p>
        Bidder is responsible for any bids placed under his/her or its account, his/her/their username and password. The security of a username and password is the sole responsibility of the Bidder. If at anytime a Bidder’s account password is compromised for any reason, the Bidder shall be responsible for choosing a new password.
      </p>

      <p>
        Placing a bid represents a valid offer between the Bidder and Seller. Once a bid is submitted it cannot be retracted. If a Bidder places the winning bid, the offer then becomes a binding contract to purchase the lot(s) or merchandise, and the Bidder is obligated to buy the lot(s) or merchandise at the final bidding price. Fraudulent bids can and will be pursued in a court of law. The Seller and Sale Manager reserve the right to accept or reject any and all bids unless otherwise stated.
      </p>

      <p>
        In the case of a software malfunction on the part of the Sale Management, the sale will be stopped and all Bidders notified. Handling of the situation thereafter will be at the sole discretion of the Sale Manager. In the event the auction is cancelled, G2 Live LLC shall not charge any party for any fees and all bids shall be considered cancelled.      
      </p>

      <h6>Payment</h6>
      <p>
        When bidding ends, all sales shall be considered final and the Bidder who submitted the highest bid shall then become the Buyer of the lot(s). Payment must be coordinated with the Seller per the auction terms addendum list on the Seller’s site.      
      </p>

      <h6>Sale Close</h6>
      <p>
        All auctions hosted by G2 Live LLC are conducted with extended bidding in a horserace-style closing format, unless otherwise stated. If there is any bidding activity in the last 5 minutes of an auction, that auction will enter into extended bidding. Extended bidding time increments will start at 5 minutes, however, the Seller may shorten that time duration at any point during extended bidding. are set by the Sale Manager and Seller, and may change during a live auction. Extended bidding will continue until there has been no bidding activity for the duration of the set extended bidding time increment. At that time, the sale will close and all lots will be declared sold to the highest bidder. Both the Sale Manager and Seller reserve the right to extend or end bidding at any time.      
      </p>

      <h6>Withdrawal of Lots</h6>
      <p>
        Occasionally, lots may need to be removed from an online sale that has already been posted. The Seller reserves the right to withdraw lots at any time before or during the auction. In the event the Seller withdraws a lot during an auction, all bids on that lot shall be considered withdrawn.
      </p>

      <h6>Liability</h6>
      <p>
        Sale Management shall not be liable for lost profit or special, incidental or consequential damages out of or in connection with any sale transaction. Bidders, Buyers and Sellers hereby agree to indemnify and hold G2 Live LLC, its members, parent subsidiaries, affiliates, officers and employees, harmless from any claim or demand, including reasonable attorney’s fees, made by any third party due to or arising out of Bidder’s, Buyer’s or Seller’s use of the this service, the violation of these terms of service, for any information transmitted by any of the aforementioned on the G2 Live LLC sales site.
      </p>

      <p>
        Sale Management has the sole and final discretion to determine winning bidders and, in the event of a dispute between parties, the decision of the Sale Manager is final. Sale Manager reserves the right to reopen bidding to settle matters related to disputed bids or ties. Sale Manager reserves the right to postpone or cancel a sale for any reason.
      </p>

      <p>
        Sale Manager does not guarantee continuous, uninterrupted or secure access to online sales services, and operation of the online sale site may be interfered with by numerous factors outside of Sale Management’s control. Furthermore, due to the nature of online services, Sale Manager cannot guarantee or confirm the authentic identity of Bidders, Buyer or Sellers and shall have no liability for any false or misleading information transmitted by the aforementioned on the G2 Live LLC auction site.
      </p>

      <p>
        Sale Manager is not responsible for any accidents or injury related to the sale or purchase of any lot(s) sold.
      </p>

      <p>
        Sale Manager may refuse services to anyone at anytime at its sole discretion. If a Bidder provides false information, such as a false name or fraudulent contact information, he/she or it will be suspended or permanently banned from G2 Live LLC sales.      
      </p>

      <app-section-header class="anchor" id="privacy" type="primary">Privacy</app-section-header>

      <p>
        To bid, buy or sell on the G2 Live LLC site, limited personal information must be submitted at the time of account creation and sign up. By signing up on the site, users consent to the collection of this information. G2 Live LLC will never give or sell this information to an outside individual, group or company. 
      </p>

      <p>
        All personal information provided will be used only by G2 Live LLC, and the Seller(s) from whom the Bidder/Buyer makes purchases from or bids in Seller(s) sales. The Bidder’s information will be used to maintain records for G2 Live LLC, as well as to communicate and provide services to the Bidder.
      </p>

      <p>
        The e-mail addresses collected are used to create user accounts and to contact Bidders, Buyers and Sellers registered with G2 Live LLC. Use of this private information by anyone else for any purpose is strictly prohibited and will result in appropriate legal action.
      </p>

      <p>
        G2 Live LLC website contains links to outside websites; G2 Live LLC has no control over or responsibility for the content and/or privacy practices of these sites.      
      </p>


    </div>
  </div>
</div>
