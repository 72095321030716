import { Component, OnInit, Input } from '@angular/core';
import { LotModel, LineageHierarchyDetail } from '../../model/auction.model';
import * as $ from 'jquery';


@Component({
  selector: 'app-lot-lineage-detail',
  templateUrl: './lot-lineage-detail.component.html',
  styleUrls: ['./lot-lineage-detail.component.scss']
})
export class LotLineageDetailComponent implements OnInit {
  
  @Input() detail:LineageHierarchyDetail = undefined;

  constructor() { }

  ngOnInit(): void {
    
  }
  
  getConnectorClass(){
    return this.detail.parentAvail ? 'connector-with-parent' : 'connector-with-no-parent';
  }
  
  getConnectorWidthStyle(){
    var div1 = $('#lineagedetailcontainer');
    var width = "" + div1.width()/2 + "px";
    var styles = {'--connector-width' : width};
    return styles;
  }
  
  getStyleStr(){
    var div1 = $('#lineagedetailcontainer');
    var width = "" + div1.width()/2;
    var styleStr = "--connector-width: " + width + "px";
    return styleStr;
  }
  
  getRegUrl(){
    return this.detail.url != undefined && this.detail.url.length > 0 ? this.detail.url : "";
  }
  
}
