import { Component, OnInit, ViewEncapsulation, ViewChild, OnDestroy, ApplicationRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { AuctionModelService, AuctionModel, LotModel } from '../../model/auction.model';
import { Seller } from '../../model/seller.model';
import { AuthService } from '../../services/auth.service';
import { AuctionChangeEventType } from '../../API.service'
import { NgImageSliderComponent } from 'ng-image-slider';
import { VisibilityService, VisibilityState } from 'src/app/services/visibility.service';
import { EventService } from 'src/app/services/event.service';
import { ConnectivityService, ConnectivityState } from 'src/app/services/connectivity.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-auctionlotdetails',
  templateUrl: './auctionlotdetails.component.html',
  styleUrls: ['./auctionlotdetails.component.scss']
})
export class AuctionlotdetailsComponent implements OnInit {
  errorMsg = "";
  processing: boolean = false;
  private auctionId: string = null;
  private lotId: string = "";
  lotNum:number = 0;
  lot:LotModel = undefined;
  auction:AuctionModel = undefined;
  sub;
  // subscriptionKey = undefined;
  lotSeller:Seller = undefined;

  previewMode:boolean = false;
  _PREVIEW_ROUTE = "/previewauction";
  @ViewChild('imageslider') slider: NgImageSliderComponent;


  constructor(private activatedRoute:ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              public auctionModel: AuctionModelService,
              private visibilityService: VisibilityService,
              private connectivityService: ConnectivityService,
              private eventService: EventService,
              private location: Location,
              private appRef: ApplicationRef) {  }

  ngOnInit(): void {
    this.isLoggedOn();

    var urlParms = this.location.path().split('/');
    this.auctionId = urlParms[urlParms.length - 2];
    this.lotId = urlParms[urlParms.length - 1];

    if (this.router.url.startsWith(this._PREVIEW_ROUTE)) {
      this.previewMode = true;
    }
    this.loadLot();

    this.visibilityService.message$.subscribe(async event => {
      if (event === VisibilityState.PAGE_HIDDEN) {
        this.eventService.createEvent({ message: "hide-timers" });

        this.ngOnDestroy();
      }
      if (event === VisibilityState.PAGE_VISIBLE) {
        var urlParms = this.location.path().split('/');
        this.auctionId = urlParms[urlParms.length - 2];
        this.lotId = urlParms[urlParms.length - 1];

        await this.loadLot();
        this.appRef.tick();
        this.eventService.createEvent({ message: "show-timers" });
      }
    });

    this.connectivityService.message$.subscribe(async event => {
      if (event === ConnectivityState.OFFLINE) {
        this.eventService.createEvent({ message: "hide-timers" });
      }
      if (event === ConnectivityState.ONLINE) {
        await this.loadLot();
        this.appRef.tick();
        this.eventService.createEvent({ message: "show-timers" });
      }
    });
  }

  ngOnDestroy() {

  }

  async loadLot(){
    this.processing = true;
    try {

      if(this.auctionModel.activeAuction == undefined){
        await this.loadAuction();
      }
      this.auction = this.auctionModel.activeAuction;
      this.lot = await this.auctionModel.loadLot(this.auctionId,this.lotId);
      // console.log('loadAuction',this.auction);
      // console.log('loadLot',this.lot);
      this.setUserBiddingStatus();
      this.loadSellerOwner();
      await this.startPolling();
      this.processing = false;
    }
    catch(err){
      this.processing = false;
      console.log('error while retrieving lot',err);
    }
  }

  async loadAuction(){
    this.processing = true;
    try {
      await this.auctionModel.loadAuction(this.auctionId);
    }
    catch(err){
      console.log('error while retrieving auction',err);
    }
  }

  getVideoLink(){
    return this.lot.externalVideoUrl != undefined && this.lot.externalVideoUrl.length > 0 ? this.lot.externalVideoUrl : "";
  }

  isSupportedVideoLink(): boolean {
    const videoLink = this.lot.externalVideoUrl;
    return videoLink && !videoLink.includes("vimeo");
  }

  loadSellerOwner(){
    this.lotSeller = undefined;
    if(this.lot.businessAccountId != this.auction.businessAccountId){
      this.lotSeller = this.auction.businesses.find((el)=>{return el.accountId==this.lot.businessAccountId});
    }
  }

  async reloadProfile(){
    this.processing = true;
    if(!this.authService.isAuthenticated()){
      return;
    }

    try {
      await this.authService.activeUser.me();
      this.processing = false;
    }
    catch(err){
      this.processing = false;
      console.log('error while retrieving auction',err);
    }
  }

  getAuctionUrl() {
    if (!this.lot) return '/auctions';

    if (this.previewMode) {
      return ['/previewauction', this.lot.auctionId];
    }
    else {
      return ['/auctions', this.lot.auctionId];
    }
  }

  async isLoggedOn(){
    let user = await this.authService.getAuthenticatedUser();
  }

  async receiveMessage($event) {
    if($event['message']=='signinsuccessful'){
      this.setUserBiddingStatus();
    }
  }

  async startPolling(){
    try {
      var localThis = this;
      await this.auctionModel.subscribeToAuctionUpdates(this.lot.auctionId,localThis,this.handleBidNotification);
      // this.subscriptionKey = subscription['key'];
    }
    catch(err){
      this.errorMsg = "System error while starting bid subscription.";
      console.log('error while starting bid subscription',err);
    }
  }

  handleBidNotification(updatedData,localThis){
    // console.log('handleBidNotification',bidData);
    localThis.updateLotAfterAuctionUpdate(updatedData);
  }

  updateLotAfterAuctionUpdate(updatedData){
    // console.log('updateLotAfterAuctionUpdate',updatedData);
    if ([AuctionChangeEventType.LOT, AuctionChangeEventType.ALL].includes(updatedData.eventType)) {
      const bidData = updatedData.lotData;

      if(bidData.lotId == this.lot.lotId && bidData.auctionId == this.lot.auctionId){
        this.lot.bidCount = bidData.bidCount;
        this.lot.bidIncrement = bidData.bidIncrement;
        this.lot.bidderAccountId = bidData.bidderAccountId;
        this.lot.bidderId = bidData.bidderId;
        this.lot.currentBid = bidData.currentBid;
        this.lot.nextBid = bidData.nextBid;
        this.lot.startBid = bidData.startBid;
        this.lot.biddingDisabled = bidData.biddingDisabled;

        if(this.authService.isAuthenticated()){
            this.lot.winMsg = "";
            this.lot.outBidMsg = "";

            let bidderInfo = this.authService.activeUser.activeBidderIds.find((el)=>{return (el.auctionId == bidData.auctionId && el.lotId == bidData.lotId)});

            if(bidData.bidderAccountId == this.authService.activeUser.accountId && bidderInfo != undefined){
              this.lot.winMsg = LotModel.WINNING_BID_STR;
            }
            else if(bidData.bidderAccountId == this.authService.activeUser.accountId && bidderInfo == undefined){
              this.lot.winMsg = LotModel.WINNING_BID_STR;
              this.reloadProfile();
            }
            else if (bidData.bidderAccountId != this.authService.activeUser.accountId && bidderInfo != undefined){
              this.lot.outBidMsg = LotModel.OUT_BID_STR;
            }
          }
        }
    }
    if ([AuctionChangeEventType.AUCTION, AuctionChangeEventType.ALL].includes(updatedData.eventType)) {
      const activeAuction = this.auctionModel.activeAuction;
      const auctionData = updatedData.auctionData;

      if (activeAuction.auctionId === updatedData.auctionId) {
        Object.keys(auctionData).forEach(key => activeAuction[key] = auctionData[key] );
      }
      const auctionToUpdate = this.auctionModel.openAuctionsList.find(auction => auction.auctionId === updatedData.auctionId);
      if (auctionToUpdate) {
        Object.keys(auctionData).forEach(key => auctionToUpdate[key] = auctionData[key]);
      }

    }
  }

  setUserBiddingStatus(){
    if(this.authService.isAuthenticated()){
      this.lot.winMsg = "";
      this.lot.outBidMsg = "";
      let bidderInfo = this.authService.activeUser.activeBidderIds.find((el)=>{return (el.auctionId == this.lot.auctionId && el.lotId == this.lot.lotId)});
      if(bidderInfo != undefined){
        if(bidderInfo.bidderId == this.lot.bidderId){
          this.lot.winMsg = LotModel.WINNING_BID_STR;
        }
        else{
          this.lot.outBidMsg = LotModel.OUT_BID_STR;
        }
      }
    }
  }

  getEPDLabel(){
    var returnValue = "Actual EPDs and Indexes";
    if(this.lot != undefined && this.lot.actualEPD != undefined && this.lot.actualEPD == false){
      returnValue = "Projected EPDs and Indexes";
    }
    return returnValue;
  }

  epdsExist(){
    return this.lot.epdsExist();
  }

  lineageHierachyExists(){
    return this.lot.lineageHierachyExists();
  }

  getSellerBusinessName(){
    if(this.lotSeller != undefined){
      return this.lotSeller.businessName != undefined ? this.lotSeller.businessName : "";
    }
    else {
      return this.auction.primaryBusiness != undefined && this.auction.primaryBusiness.businessName != undefined ? this.auction.primaryBusiness.businessName : "";
    }
  }

  getSellerAddressOne(){
    if(this.lotSeller != undefined){
      return (this.lotSeller.physicalAddress != undefined &&
              this.lotSeller.physicalAddress[0] != undefined) ?
              this.lotSeller.physicalAddress[0] : "";
    }
    else {
      return (this.auction.primaryBusiness.physicalAddress != undefined &&
              this.auction.primaryBusiness.physicalAddress[0] != undefined) ?
              this.auction.primaryBusiness.physicalAddress[0] : "";

    }
  }

  getSellerAddressTwo(){
    if(this.lotSeller != undefined){
      return (this.lotSeller.physicalAddress != undefined &&
              this.lotSeller.physicalAddress[1] != undefined) ?
              this.lotSeller.physicalAddress[1] : "";
    }
    else {
      return (this.auction.primaryBusiness.physicalAddress != undefined &&
              this.auction.primaryBusiness.physicalAddress[1] != undefined) ?
              this.auction.primaryBusiness.physicalAddress[1] : "";
    }
  }

  // getSellerPhone(){
  //   return this.auction.primaryBusiness != undefined && this.auction.primaryBusiness.businessName != undefined ? this.auction.primaryBusiness.businessName : "";
  // }

  getPhysicalLocation(){
    if(this.lotSeller != undefined){
      return this.lotSeller.getDisplayablePhysicalLocation();
    }
    else {
      return this.auction.primaryBusiness != undefined ? this.auction.primaryBusiness.getDisplayablePhysicalLocation() : "";
    }
  }

  getSellerPhoneNumber(){
    var returnValue = "";
    if(this.lotSeller != undefined){
      if (this.lotSeller.businessPhoneNumbers != undefined &&
          this.lotSeller.businessPhoneNumbers.length > 0){
            returnValue = this.lotSeller.businessPhoneNumbers[0].phone;
            var preferredPhoneIndex = this.lotSeller.businessPhoneNumbers.findIndex((el)=>{return el.preferred == true })
            if(preferredPhoneIndex >= 0){
              returnValue = this.lotSeller.businessPhoneNumbers[preferredPhoneIndex].phone;
            }
      }
    }
    else {
      if (this.auction.primaryBusiness != undefined &&
          this.auction.primaryBusiness.businessPhoneNumbers != undefined &&
          this.auction.primaryBusiness.businessPhoneNumbers.length > 0){
            returnValue = this.auction.primaryBusiness.businessPhoneNumbers[0].phone;
            var preferredPhoneIndex = this.auction.primaryBusiness.businessPhoneNumbers.findIndex((el)=>{return el.preferred == true })
            if(preferredPhoneIndex >= 0){
              returnValue = this.auction.primaryBusiness.businessPhoneNumbers[preferredPhoneIndex].phone;
            }
      }

    }

    return returnValue;
  }

  // getSellerContactPhoneNumber(){
  //   var returnValue = "";
  //   if (this.auction.sellerPhoneNumbers != undefined &&
  //       this.auction.sellerPhoneNumbers.length > 0){
  //         returnValue = this.auction.sellerPhoneNumbers[0].phone;
  //         var preferredPhoneIndex = this.auction.sellerPhoneNumbers.findIndex((el)=>{return el.preferred == true })
  //         if(preferredPhoneIndex >= 0){
  //           returnValue = this.auction.sellerPhoneNumbers[preferredPhoneIndex].phone;
  //         }
  //   }
  //   return returnValue;
  // }

  // sellerContactPhoneNumbersExist(){
  //   return this.auction.sellerPhoneNumbers != undefined && this.auction.sellerPhoneNumbers.length > 0;
  // }

  getSellerEmail(){
    if(this.lotSeller != undefined){
      return this.lotSeller.email != undefined ? this.lotSeller.email : "";
    }
    else {
      return this.auction.primaryBusiness != undefined && this.auction.primaryBusiness.email != undefined ? this.auction.primaryBusiness.email : "";
    }
  }

  getSellerMailToUrl(){
    let subject = this.auction.title != undefined ? "?subject=" + this.auction.title : "";
    return ("mailto:" + this.getSellerEmail() + subject);
  }

  getSellerWebSite(){
    if(this.lotSeller != undefined){
      return this.lotSeller.businessWebSite != undefined ? this.lotSeller.businessWebSite : "";
    }
    else {
      return this.auction.primaryBusiness != undefined && this.auction.primaryBusiness.businessWebSite != undefined ? this.auction.primaryBusiness.businessWebSite : "";
    }
  }

  getSellerWebSiteUrl(){
    let returnValue = this.getSellerWebSite();
    if(returnValue.length > 0){
      var isHttp = returnValue.indexOf("http://") != -1 || returnValue.indexOf("https://") != -1 ? true : false;
      returnValue = isHttp ? returnValue : "https://" + returnValue;
    }
    return returnValue;
  }



} /* End export class */
