import { Component, OnInit, Input } from '@angular/core';
import { AuctionModel } from '../../model/auction.model';
import { isEmpty } from '../../utils/object.util';
@Component({
  selector: 'app-auctionsaleinformation',
  templateUrl: './auctionsaleinformation.component.html',
  styleUrls: ['./auctionsaleinformation.component.scss']
})
export class AuctionsaleinformationComponent implements OnInit {
  @Input() auction:AuctionModel;

  constructor() { }

  ngOnInit(): void {
    // console.log('auction',this.auction);
  }

  getSalesRepMailToUrl(){
    let subject = this.auction.title != undefined ? "?subject=" + this.auction.title : "";
    return (this.auction.salesRepEmail != undefined ? "mailto:" + this.auction.salesRepEmail + subject : "");
  }

  getSalesBannerURL(){
    let returnValue = "";
    if(this.auction.salesInfoBannerKeys != undefined){
      for(let value of this.auction.salesInfoBannerKeys){
        returnValue = this.auction.getSalesLogoUrl(value);
        break;
      }
    }
    return returnValue;
  }

  getSellerContactName(){
    return this.auction.sellerContactName != undefined ? this.auction.sellerContactName : "";
  }

  getSellerContactEmail(){
    return this.auction.sellerEmail != undefined ? this.auction.sellerEmail : "";
  }

  getSellerContactMailToUrl(){
    let subject = this.auction.title != undefined ? "?subject=" + this.auction.title : "";
    return (this.auction.sellerEmail != undefined ? "mailto:" + this.auction.sellerEmail + subject : "");
  }


  getSellerBusinessName(){
    return this.auction.primaryBusiness != undefined && this.auction.primaryBusiness.businessName != undefined ? this.auction.primaryBusiness.businessName : "";
  }

  getSellerAddressOne(){
    return (this.auction.primaryBusiness.physicalAddress != undefined &&
            this.auction.primaryBusiness.physicalAddress[0] != undefined) ?
            this.auction.primaryBusiness.physicalAddress[0] : "";
  }

  getSellerAddressTwo(){
    return (this.auction.primaryBusiness.physicalAddress != undefined &&
            this.auction.primaryBusiness.physicalAddress[1] != undefined) ?
            this.auction.primaryBusiness.physicalAddress[1] : "";

  }

  getSellerPhone(){
    return this.auction.primaryBusiness != undefined && this.auction.primaryBusiness.businessName != undefined ? this.auction.primaryBusiness.businessName : "";
  }

  getPhysicalLocation(){
    return this.auction.primaryBusiness != undefined ? this.auction.primaryBusiness.getDisplayablePhysicalLocation() : "";
  }

  isLocationVisible() {
    return this.getPhysicalLocation().length > 0 && (this.auction.businesses || []).length == 0;
  }

  getSellerPhoneNumber(){
    var returnValue = "";
    if (this.auction.primaryBusiness != undefined &&
        this.auction.primaryBusiness.businessPhoneNumbers != undefined &&
        this.auction.primaryBusiness.businessPhoneNumbers.length > 0){
          returnValue = this.auction.primaryBusiness.businessPhoneNumbers[0].phone;
          var preferredPhoneIndex = this.auction.primaryBusiness.businessPhoneNumbers.findIndex((el)=>{return el.preferred == true })
          if(preferredPhoneIndex >= 0){
            returnValue = this.auction.primaryBusiness.businessPhoneNumbers[preferredPhoneIndex].phone;
          }
    }
    return returnValue;
  }

  getSellerPhoneNumbers() {
    const primaryBusiness = this.auction.primaryBusiness;
    return primaryBusiness && primaryBusiness.businessPhoneNumbers || [];
  }

  getSellerContactPhoneNumbers() {
    let phoneNumbers = [];
    if (this.auction.useSellerContactInfo) {
      const primaryBusiness = this.auction.primaryBusiness;
      phoneNumbers = primaryBusiness && primaryBusiness.businessPhoneNumbers || [];
    } else {
      phoneNumbers = this.auction.sellerPhoneNumbers || [];
    }
    return phoneNumbers;
  }

  // getSellerContactPhoneNumber(){
  //   var returnValue = "";
  //   if (this.auction.sellerPhoneNumbers != undefined &&
  //       this.auction.sellerPhoneNumbers.length > 0){
  //         returnValue = this.auction.sellerPhoneNumbers[0].phone;
  //         var preferredPhoneIndex = this.auction.sellerPhoneNumbers.findIndex((el)=>{return el.preferred == true })
  //         if(preferredPhoneIndex >= 0){
  //           returnValue = this.auction.sellerPhoneNumbers[preferredPhoneIndex].phone;
  //         }
  //   }
  //   return returnValue;
  // }

  sellerContactPhoneNumbersExist(){
    return this.auction.sellerPhoneNumbers != undefined && this.auction.sellerPhoneNumbers.length > 0;
  }

  getSellerEmail(){
    return this.auction.primaryBusiness != undefined && this.auction.primaryBusiness.email != undefined ? this.auction.primaryBusiness.email : "";
  }

  getSellerMailToUrl(){
    let subject = this.auction.title != undefined ? "?subject=" + this.auction.title : "";
    return ("mailto:" + this.getSellerEmail() + subject);
  }

  getSellerWebSite(){
    return this.auction.primaryBusiness != undefined && this.auction.primaryBusiness.businessWebSite != undefined ? this.auction.primaryBusiness.businessWebSite : "";
  }

  getSellerWebSiteUrl(){
    let returnValue = this.auction.primaryBusiness != undefined && this.auction.primaryBusiness.businessWebSite != undefined ? this.auction.primaryBusiness.businessWebSite : "";
    if(returnValue.length > 0){
      var isHttp = returnValue.indexOf("http://") != -1 || returnValue.indexOf("https://") != -1 ? true : false;
      returnValue = isHttp ? returnValue : "https://" + returnValue;
    }
    return returnValue;
  }

  isConsignmentSale(){
    return (this.auction.businesses != undefined && this.auction.businesses.length > 0)
  }

  getSalesInfoNotes(){
    return this.auction.salesInfoNotes != undefined ? this.auction.salesInfoNotes.trim() : "";
  }

  saleOverviewExists() {
    const saleOverview = (this.auction.saleOverview || []).join("");
    return !isEmpty(saleOverview);
  }
}
