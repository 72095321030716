import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Timezones } from '../../services/timezones';


import { AuctionModel } from '../../model/auction.model';
import * as moment from 'moment';

@Component({
  selector: 'app-auction-card',
  templateUrl: './auction-card.component.html',
  styleUrls: ['./auction-card.component.scss']
})
export class AuctionCardComponent implements OnInit {
  @Input() auction:AuctionModel;
  @Input() featured: boolean = false;
  timezones;
  timezoneRecord;

  _AUCTIONLIVECARD_STYLE = {"padding-top":"0px"};
  _AUCTIONNOTLIVECARD_STYLE = {};

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.timezones = Timezones.timezones;
    this.timezoneRecord = this.timezones.find((el)=>{return el.timezone == this.auction.timezone});
    if(this.timezoneRecord == undefined){
      this.timezoneRecord = this.timezones.find((el)=>{return el.timezone == "US/Eastern"});
    }
  }

  getFormattedEndDate(){
      var date = Timezones.getDateWithTimezoneApplied_moment(this.auction.endTime, this.timezoneRecord.timezone);
      return Timezones.readableDateTimeMoment(date) + " " + this.timezoneRecord['short'];
  }

  onViewDetails(){
    this.router.navigate(['/auctions', this.auction.auctionId]);
  }

  getCardStyle(){
    return this.auction.isLive() || this.auction.isClosed() ? this._AUCTIONLIVECARD_STYLE : this._AUCTIONNOTLIVECARD_STYLE;
  }

  getBannerText() {
    return this.auction.isLive() ? "SALE OPEN" : "CLOSED";
  }

  auctionActive() {

  }


}

