<div class="loading-shade" *ngIf="processing"></div>

<div class="container-fluid auction-details-container">

  <div class="container-horiz-padded auction-details-breadcrumb py-2">
    <app-back-nav-button backUrl="/auctions" text="Auctions"></app-back-nav-button>
  </div>

  <div>
    <app-auction-details-banner #banner [auction]="auction"></app-auction-details-banner>
  </div>

  <div class="row container-horiz-padded">
      <div class="col">
          <nb-tabset (changeTab)="selectTab($event)" *ngIf="lotsPosted()" class="auction-tabset">
            <nb-tab tabTitle="LOTS" class="auction-tab"></nb-tab>
            <nb-tab tabTitle="SALE INFORMATION" class="auction-tab"></nb-tab>
          </nb-tabset>

          <nb-tabset (changeTab)="selectTab($event)" *ngIf="!lotsPosted() && saleInfoPosted()">
            <nb-tab tabTitle="SALE INFORMATION" class="auction-tab"></nb-tab>
          </nb-tabset>
      </div>
  </div>

  <div class="row container-horiz-padded">
      <div class="col">
          <app-lotslist #lotslist *ngIf="isPageDisplayed(1) && lotsPosted()" [auction]="auction" [authenticated]="authService.isAuthenticated()" [auctionRunning]="isLive()" [previewMode]="previewMode" (messageEvent)="receiveMessage($event)"></app-lotslist>
          <app-auctionsaleinformation #auctionsalesinformation *ngIf="isPageDisplayed(2) && saleInfoPosted()" [auction]="auction" (messageEvent)="receiveMessage($event)"></app-auctionsaleinformation>
      </div>
  </div>
</div>