<div class="d-none d-sm-none d-md-block">
  <div class="row no-gutters mt-2">
    <div class="col-md-1 pl-2">
      {{ lineItem.lotNum }}
    </div>

    <div class="col-md-3">
      {{ lineItem.lotName }}
    </div>

    <div class="col-md-2">
      {{ lineItem.lotCategory }}
    </div>

    <div class="col-md-2 text-right">
      {{ lineItem.quantity }}
    </div>

    <div class="col-md-2 text-right">
      ${{ unitPrice }}
    </div>

    <div class="col-md-2 text-right pr-2">
      ${{ totalPrice }}
    </div>
  </div>
</div>

<dl class="row no-gutters mt-2 d-block d-sm-block d-md-none">
  <dt class="col">LOT #</dt>
  <dd class="col">{{ lineItem.lotNum }}</dd>

  <dt class="col">NAME</dt>
  <dd class="col">{{ lineItem.lotName }}</dd>

  <dt class="col">CATEGORY</dt>
  <dd class="col">{{ lineItem.lotCategory }}</dd>

  <dt class="col">QUANTITY</dt>
  <dd class="col">{{ lineItem.quantity }}</dd>

  <dt class="col">PRICE</dt>
  <dd class="col">${{ lineItem.unitPrice }}</dd>

  <dt class="col">TOTAL</dt>
  <dd class="col">${{ lineItem.totalPrice }}</dd>
</dl>