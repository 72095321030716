<div *ngIf="!processing">
  <span class="content-text" *ngIf="auctionStatus && !(['CLOSED', 'SETTLED'].includes(auctionStatus))">The auction is not yet closed. This tab will populate with information when the auction is in the closed or settled stage.</span>

  <div class="container-fluid" *ngIf="auctionReport && auctionStatus && (auctionStatus === 'CLOSED' || auctionStatus === 'SETTLED')">
    
    <!--<div class="d-flex flex-row justify-content-between align-items-center gross-sales-container1">-->
    <!--  <div class="d-flex flex-row">-->
    <!--    <div class="gross-sales-text">Total Gross Sales</div>-->
    <!--  </div>-->
    <!--</div>    -->

    <!--<div class="d-flex flex-row justify-content-between align-items-center gross-sales-container2">-->
    <!--  <div class="d-flex flex-row">-->
    <!--    <div class="gross-sales-text-value">${{ getTotalGrossSales() }}</div>-->
    <!--  </div>-->
    <!--</div>    -->
    
    <h6 class="lot-header mt-3">Sale Lots</h6>

    <app-auction-report-sold-lot-group [soldLineItems]="filterByStatus(auctionReport.lineItems, 'SOLD')"></app-auction-report-sold-lot-group>

    <h6 class="lot-header mt-4">No Sale Lots</h6>

    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col" class="table-header-text-link" style="width: 4rem;">LOT #</th>
            <th scope="col" class="table-header-text-link" style="width: 18.75rem;">NAME</th>
            <th scope="col" class="table-header-text-link" style="width: 10rem;">FINAL BID</th>
            <th scope="col" class="table-header-text-link" style="width: 12rem;">WINNING BIDDER</th>
            <th scope="col" class="table-header-text-link">QTY</th>
            <th scope="col" class="table-header-text-link">FINAL PRICE</th>
            <th scope="col" class="table-header-text-link">SALE/NO SALE</th>
          </tr>
        </thead>
        <tbody>
            <tr app-auction-report-lot-detail *ngFor="let item of filterByStatus(auctionReport.lineItems, 'UNSOLD')" [lot]="item" [auctionStatus]="auctionStatus"></tr>
        </tbody>
      </table>
    </div>
  </div>
</div>