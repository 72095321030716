<td>
  <div class="column-text">{{ lot.lotNum }}</div>
</td>

<td>
  <span class="column-text"><a [href]="getLotUrl()" target="_blank">{{ lot.title }}</a></span>
</td>

<td class="text-right">
  <span class="column-text">${{ lot.finalBid }}</span>
</td>

<td class="text-right">
  <span class="column-text">{{ lot.quantity }}</span>
</td>

<td class="text-right">
  <span class="column-text">{{ formattedAdjustment() }}</span>
</td>

<td class="text-right">
  <span class="column-text">${{ lot.finalPrice }}</span>
</td>