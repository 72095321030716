<div class="loading-shade" *ngIf="processing"></div>

<div class="container-fluid container-horiz-padded container-vert-padded">

    <div class="row mb-2">
        <div class="col">
          <app-section-header id="buying-info" type="primary">Account Settings</app-section-header>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-8 label-text">
            <h6>Account</h6>
        </div>
    </div>

    <div class="row">
      <div class="col-md-8 label-text">
          USERNAME (EMAIL ADDRESS)
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-6">
        {{ getEmail() }}
      </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-8 label-text">
            PASSWORD
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-8">
            <button nbButton outline size="small" status="primary" (click)="changePassword()">CHANGE PASSWORD</button>
        </div>
    </div>

    <div class="row mb-1">
        <div class="col">
            <hr>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-8 label-text">
            <h6>Contact Info</h6>
        </div>
    </div>

    <div class="row">
        <div class="col-md-8 label-text">
            YOUR PHONE NUMBER
        </div>
    </div>

    <ng-container *ngIf="!changephonenumber">
        <div class="row mb-2">
          <div class="col-6">
            {{ getPhoneNumber() }}
          </div>
        </div>
        <div class="row mb-4">
          <div class="col">
            <button nbButton outline size="small" status="primary" (click)="changePhoneNumber()">CHANGE PHONE NUMBER</button>
          </div>
        </div>
    </ng-container>

    <ng-container *ngIf="changephonenumber">
        <div class="row mt-1 mb-2">
            <div class="col phone-number-field">
                <input mask="(000) 000-0000"
                       [dropSpecialCharacters]="false"
                       type="text"
                       nbInput
                       fullWidth
                       id="phone"
                       name="phone"
                       #phoneentry="ngModel"
                       [ngClass]="{'inputError':!validPhone()}"
                       maxlength="14"
                       [(ngModel)]="phone">
            </div>
        </div>
        <div class="row mb-2" *ngIf="!validPhone()">
          <div class="col">
            <div class="errorText">Enter a 10-digit phone number.</div>
          </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-8">
                <button nbButton [nbSpinner]="processing" nbSpinnerStatus="control" outline size="small" status="primary" (click)="updatePhoneNumber()" [disabled]="!validPhone()">UPDATE</button>
                <button class="ml-2" nbButton ghost size="small" status="primary" (click)="cancelPhoneNumber()">CANCEL</button>
            </div>
        </div>

    </ng-container>

    <div class="row">
        <div class="col-md-8 label-text">
            YOUR ADDRESS
        </div>
    </div>

    <ng-container *ngIf="!changeaddress">
        <div class="row">
            <div class="col info-text">
                {{ getAddress() }}
            </div>
        </div>
        <div class="row mb-2">
            <div class="col info-text">
                {{ getLocation() }}
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button nbButton outline size="small" status="primary" (click)="changeAddress()">UPDATE ADDRESS</button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="changeaddress">
        <div class="row mt-1 mb-2">
            <div class="col-md-8 address-field">
                <input #addressLine1Input
                       type="text"
                       nbInput
                       fullWidth
                       id="addressLine1"
                       name="addressLine1"
                       placeholder="Address Line 1"
                       [(ngModel)]="addressLine1">
            </div>
            <div class="col"></div>
        </div>

        <div class="row mb-2">
            <div class="col-md-8 address-field">
                <input type="text"
                       nbInput
                       fullWidth
                       id="addressLine2"
                       name="addressLine2"
                       placeholder="Address Line 2"
                       [(ngModel)]="addressLine2">
            </div>
            <div class="col"></div>
        </div>

        <div class="row mb-2">
            <div class="col-md-8 address-field">
                <input type="text"
                       nbInput
                       fullWidth
                       id="city"
                       name="city"
                       placeholder="Type your city"
                       [(ngModel)]="city">
            </div>
            <div class="col"></div>
        </div>

        <div class="row mb-2">
            <div class="col-md-8">
                <nb-select  placeholder="Select State"
                            name="state"
                            id="state"
                            [(ngModel)]="state">
                    <nb-option *ngFor="let item of states" [value]="item">{{ item }}</nb-option>
                </nb-select>

                <!--<input #stateautoinput-->
                <!--       type="text"-->
                <!--       nbInput-->
                <!--       fullWidth-->
                <!--       id="state"-->
                <!--       name="state"-->
                <!--       (input)="onStateChange()"-->
                <!--       [nbAutocomplete]="auto"-->
                <!--       placeholder="Type your state"-->
                <!--       [ngClass]="{'inputError':!validState()}"-->
                <!--       [(ngModel)]="state">-->
                <!--<div class="errorText" *ngIf="!validState()">Please select a state.</div>-->
                <!--<nb-autocomplete #auto (selectedChange)="onStateSelectedChange($event)">-->
                <!--    <nb-option *ngFor="let item of filteredOptions$ | async" [value]="item">{{ item }}</nb-option>-->
                <!--</nb-autocomplete>-->
            </div>
            <div class="col"></div>
        </div>

        <div class="row mb-2">
            <div class="col-md-8 zip-field">
                <input type="text"
                       nbInput
                       fullWidth
                       id="zip"
                       name="zip"
                       placeholder="Zip Code"
                       [ngClass]="{'inputError':!validZip()}"
                       maxlength="5"
                       [(ngModel)]="zip">
                <div class="errorText" *ngIf="!validZip()">Please enter a 5-digit zip code.</div>
            </div>
            <div class="col"></div>
        </div>

        <div class="row mb-3">
            <div class="col-md-8">
                <button nbButton [nbSpinner]="processing" nbSpinnerStatus="control" outline size="small" status="primary" (click)="updateAddress()" [disabled]="!validZip() || !validState()">UPDATE</button>
                <button class="ml-2" nbButton ghost size="small" status="primary" (click)="cancelAddress()">CANCEL</button>
            </div>
        </div>

    </ng-container>

    <div class="row mb-1">
        <div class="col">
            <hr>
        </div>
    </div>

    <ng-container *ngIf="false">
      <div class="row mb-2">
          <div class="col-md-8 label-text">
              Payment Info for Bidding
          </div>
      </div>

      <div class="row mb-3" *ngIf="false">
          <div class="col-md-4 info-text">
              {{ getCreditCardInfo() }}
          </div>
          <div class="col-md-4">
              <button nbButton outline size="small" status="primary" (click)="updateCreditCard()">UPDATE CREDIT CARD</button>
          </div>
          <div class="col"></div>
      </div>

      <div class="row mb-1">
          <div class="col">
              <hr>
          </div>
      </div>

      <div class="row mb-3">
          <div class="col-md-8 label-text">
              General Notifications
          </div>
      </div>

      <div class="row mb-3">
          <div class="col-md-8">
              <nb-toggle
                  id="playSoundWhenBidsArePlaced"
                  name="playSoundWhenBidsArePlaced"
                  (change)="updateNotifications($event)"
                  status="primary"
                  [(ngModel)]="playsound">Play sound when bids are placed
              </nb-toggle>
          </div>
      </div>

      <div class="row mb-1">
          <div class="col-md-8">
              <hr>
          </div>
      </div>
    </ng-container>

    <div class="row mb-3">
        <div class="col-md-8 label-text">
            <h6>Following Notifications</h6>
        </div>
    </div>

    <ng-container *ngIf="false">
      <div class="row mb-2">
          <div class="col-md-8">
              <nb-toggle
                  id="notifyWhenAuctionStarts"
                  name="notifyWhenAuctionStarts"
                  (change)="updateNotifications($event)"
                  status="primary"
                  [(ngModel)]="notifywhenauctionstarts">Notify me when the auction starts
              </nb-toggle>
          </div>
          <div class="col"></div>
      </div>

      <div class="row mb-2">
          <div class="col-md-8">
              <nb-toggle
                  id="notifyTwoHoursBeforeAuctionEnds"
                  name="notifyTwoHoursBeforeAuctionEnds"
                  (change)="updateNotifications($event)"
                  status="primary"
                  [(ngModel)]="notifytwohoursbeforeauctionends">Notify me 2 hours before the auction ends
              </nb-toggle>
          </div>
          <div class="col"></div>
      </div>
    </ng-container>

    <div class="row mb-2">
        <div class="col-md-8">
            <nb-toggle
                id="notifyWhenOutBid"
                name="notifyWhenOutBid"
                (change)="updateNotifications($event)"
                status="primary"
                [(ngModel)]="notifywhenoutbid">Notify me when I've been out-bid
            </nb-toggle>
        </div>
        <div class="col"></div>
    </div>

    <ng-container *ngIf="false">
      <div class="row mb-2">
          <div class="col-md-8">
              <nb-toggle
                  id="notifyWhenAuctionEnds"
                  name="notifyWhenAuctionEnds"
                  (change)="updateNotifications($event)"
                  status="primary"
                  [(ngModel)]="notifywhenauctionends">Notify me when the auction ends
              </nb-toggle>
          </div>
          <div class="col"></div>
      </div>

      <div class="row mb-2">
          <div class="col-md-8">
              <nb-toggle
                  id="notifyOfAuctionResults"
                  name="notifyOfAuctionResults"
                  (change)="updateNotifications($event)"
                  status="primary"
                  [(ngModel)]="notifyauctionresults">Notify me of the auction's results
              </nb-toggle>
          </div>
          <div class="col"></div>
      </div>
    </ng-container>

    <div class="row mb-5">
        <div class="col-md-8">
            <nb-toggle
                id="notifyByEmail"
                name="notifyByEmail"
                (change)="updateNotifications($event)"
                status="primary"
                [(ngModel)]="notifybyemail">Get these notifications also by email
            </nb-toggle>
        </div>
        <div class="col"></div>
    </div>

</div>