<div class="container-fluid">
            <div class="row mb-2">
                <div class="col-5 auction-label-text">
                    LOT {{ lot.lotNum }}
                </div>
                <div class="col-7 auction-label-text flex-right">
                    {{ getTime(lot.lastBidTime) }}
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-12 info-data-text" style="font-size:15px">
                    {{ lot.title }}
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-7 auction-label-text">
                    CURRENT BID
                </div>
                <div class="col-5 info-data-text flex-right" style="font-size:15px">
                    {{ getBidPrice() }}
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-6 auction-label-text">
                    BIDDER
                </div>
                <div class="col-6 info-data-text flex-right" style="font-size:15px">
                    {{ getBidder() }}
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-6 auction-label-text">
                    # BIDS
                </div>
                <div class="col-6 info-data-text flex-right" style="font-size:15px">
                    {{ getBidCount() }}
                </div>
            </div>
</div> <!-- fluid-container -->

