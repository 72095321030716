import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-header',
  templateUrl: './section-header.component.html',
  styleUrls: ['./section-header.component.scss'],
})
export class SectionHeaderComponent implements OnInit {

  constructor() { }

  @Input() class: string;
  @Input() underlineThickness: string = "normal";
  @Input() type?: string;

  ngOnInit(): void {

  }

}
