import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '../../services/auth.service';

import { NbDialogService } from '@nebular/theme';
import { AuthhomeComponent } from '../../auth/authhome/authhome.component';


@Component({
  selector: 'app-accountsettings',
  templateUrl: './accountsettings.component.html',
  styleUrls: ['./accountsettings.component.scss']
})
export class AccountsettingsComponent implements OnInit {
  errorMsg = "";
  processing: boolean = false;
  changephonenumber: boolean = false;
  changeaddress: boolean = false;
  filteredOptions$: Observable<string[]>;

  email: string = "";
  phone:string = "";
  addressLine1:string = "";
  addressLine2:string = "";
  city:string = "";
  state:string = "";
  zip:string = "";
  states:string[] = [];
  @ViewChild('stateautoinput') stateInput;
  @ViewChild('addressLine1Input') addressLine1Input;

  playsound:boolean;
  notifywhenauctionstarts:boolean;
  notifytwohoursbeforeauctionends:boolean;
  notifywhenoutbid:boolean;
  notifywhenauctionends:boolean;
  notifyauctionresults:boolean;
  notifybyemail:boolean;

  constructor(private authService:AuthService,
              private dialogService: NbDialogService) { }

  ngOnInit(): void {
    this.states = ["Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"];
    this.filteredOptions$ = of(this.states);
    this.setNotifications();
  }

  changePassword(){
    this.openAuth("changepassword");
  }

  changePhoneNumber(){
    this.phone = this.authService.activeUser.phone;
    this.changephonenumber = true;
  }

  async updatePhoneNumber(){
    await this._updatePhone();
    this.changephonenumber = false;
  }

  cancelPhoneNumber(){
    this.changephonenumber = false;
  }

  updateCreditCard(){

  }

  validZip(){
    var returnValue = true;
    if(this.zip != undefined && this.zip.length > 0){
      returnValue = /^\d{5}/g.test(this.zip);
    }
    return returnValue;
  }

  validPhone(){
    var returnValue = false;
    if(this.phone != undefined && this.phone.length == 14){
      returnValue = /^\(\d{3}\)\s\d{3}-\d{4}/g.test(this.phone);
    }
    return returnValue;
  }

  validState(){
    var returnValue = true;
    if(this.state != undefined && this.state.length > 0){
      return this.states.includes(this.state);
    }
    return returnValue;
  }

  changeAddress(){
    this.addressLine1 = this.authService.activeUser.physicalAddress[0] != undefined ? this.authService.activeUser.physicalAddress[0] : "";
    this.addressLine2 = this.authService.activeUser.physicalAddress[1] != undefined ? this.authService.activeUser.physicalAddress[1] : "";
    this.city = this.authService.activeUser.physicalCity;
    this.state = this.authService.activeUser.physicalState;
    this.zip = this.authService.activeUser.physicalZip;
    this.changeaddress = true;
    this.setAddressLine1Focus();
  }

  setAddressLine1Focus(){
    setTimeout(()=>{ // this will make the execution after the above boolean has changed
        this.addressLine1Input.nativeElement.focus();
      },0);
  }

  async updateAddress(){
    await this._updateAddress();
    this.changeaddress = false;
  }

  cancelAddress(){
    this.changeaddress = false;
  }

  openAuth(pageName){
    let localThis = this;
    this.dialogService.open(AuthhomeComponent, {
        hasScroll:false,
        closeOnBackdropClick:false,
        closeOnEsc:false,
        context:{
          authPageName:pageName
        }
      }).onClose.subscribe(event => localThis.processMsg(event));
  }

  processMsg(event){
    if(event != undefined){
      var msg = event['message'];
      if(msg== "changepassword"){
        this.openAuth("changepassword");
      }
    }
  }

  getPhoneNumber(){
    return (this.authService.activeUser == undefined || this.authService.activeUser.phone == undefined || this.authService.activeUser.phone.length==0) ? 'No phone on file' : this.authService.activeUser.phone;
  }

  getEmail() {
    return this.authService.activeUser.email;
  }

  getAddress(){
    if(this.authService.activeUser != undefined){
      var line1 = this.authService.activeUser.physicalAddress[0] != undefined ? this.authService.activeUser.physicalAddress[0] : "";
      var line2 = this.authService.activeUser.physicalAddress[1] != undefined ? this.authService.activeUser.physicalAddress[1] : "";
      var address = line1 + " " + line2;
      return (address.length > 1) ? address : 'No address on file';
    }
    else{
      return "";
    }
  }

  getLocation(){
    return (this.authService.activeUser == undefined || this.authService.activeUser.getDisplayablePhysicalLocation().length==0) ? '' : this.authService.activeUser.getDisplayablePhysicalLocation();
  }

  getCreditCardInfo(){
    return "No credit card on file";
  }

  async _updatePhone(){
    this.processing = true;
    this.authService.activeUser.phone = this.phone;
    try {
      await this.authService.activeUser.update();
      this.processing = false;
    }
    catch(err){
      this.processing = false;
      this.errorMsg = err;
    }
  }

  async _updateAddress(){
    this.processing = true;
    this.authService.activeUser.physicalAddress[0] = this.addressLine1;
    this.authService.activeUser.physicalAddress[1] = this.addressLine2;
    this.authService.activeUser.physicalCity = this.city;
    this.authService.activeUser.physicalState = this.state;
    this.authService.activeUser.physicalZip = this.zip;
    try {
      await this.authService.activeUser.update();
      this.processing = false;
    }
    catch(err){
      this.processing = false;
      this.errorMsg = err;
    }
  }

  setNotifications(){
    this.playsound = this.authService.activeUser.playSoundForBids();
    this.notifywhenauctionstarts = this.authService.activeUser.notifyWhenAuctionStarts();
    this.notifytwohoursbeforeauctionends = this.authService.activeUser.notifyTwoHoursBeforeAuctionEnds();
    this.notifywhenoutbid = this.authService.activeUser.notifyWhenOutBid();
    this.notifywhenauctionends = this.authService.activeUser.notifyWhenAuctionEnds();
    this.notifyauctionresults = this.authService.activeUser.notifyAuctionResults();
    this.notifybyemail = this.authService.activeUser.notifyByEmail();
  }

  async updateNotifications($event){
    await this._updateNotifications();
  }

  async _updateNotifications(){
    this.processing = true;
    var notificationUpdateStruct = this.getUpdateNotificationsStruct();
    this.authService.activeUser.setNotifications(notificationUpdateStruct);
    try {
      await this.authService.activeUser.update();
      this.processing = false;
    }
    catch(err){
      this.processing = false;
      this.errorMsg = err;
    }
  }

  getUpdateNotificationsStruct(){
    var returnValue = {};
    returnValue[this.authService.activeUser._NOTIFICATION_AUCTIONENDS] = this.notifywhenauctionends;
    returnValue[this.authService.activeUser._NOTIFICATION_PLAYSOUND] = this.playsound;
    returnValue[this.authService.activeUser._NOTIFICATION_AUCTIONSTARTS] = this.notifywhenauctionstarts;
    returnValue[this.authService.activeUser._NOTIFICATION_TWOHOURSBEFOREAUCTIONENDS] = this.notifytwohoursbeforeauctionends;
    returnValue[this.authService.activeUser._NOTIFICATION_OUTBID] = this.notifywhenoutbid;
    returnValue[this.authService.activeUser._NOTIFICATION_AUCTIONRESULTS] = this.notifyauctionresults;
    returnValue[this.authService.activeUser._NOTIFICATION_BYEMAIL] = this.notifybyemail;
    return returnValue;
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.states.filter(optionValue => optionValue.toLowerCase().includes(filterValue));
  }

  getFilteredOptions(value: string): Observable<string[]> {
    return of(value).pipe(
      map(filterString => this.filter(filterString)),
    );
  }

  onStateChange() {
    this.filteredOptions$ = this.getFilteredOptions(this.stateInput.nativeElement.value);
  }

  onStateSelectedChange($event) {
    this.filteredOptions$ = this.getFilteredOptions($event);
  }


}
