<div class="container-fluid container-horiz-padded container-vert-padded">
  <app-section-header><div class="page-header">Bidding Portal</div></app-section-header>

  <ng-container *ngIf="!authService.isAuthenticated(); else authenticated">
    <img src="../../../assets/icons/bidding-portal.png" />
    <p class="mt-2">Create a secure account and profile on our bidder portal and enjoy a quick-access hub to your auction wins and invoices.</p>
    <div class="sign-in-block mt-4">
      <h6>You are not signed in</h6>
      <button nbButton shape="round" size="large" status="basic" (click)="onSignIn()" class="mt-2 auth-button">SIGN IN</button>
    </div>

    <div class="sign-up-block mt-4">
      <strong>Don't have an account?</strong>
      <p class="mt-2">Sign up to get recommendations, place bids, subscribe to alerts and manage your settings.</p>
      <button nbButton shape="round" size="large" status="basic" (click)="onSignUp()" class="auth-button">SIGN UP</button>
    </div>
  </ng-container>

  <ng-template #authenticated>
    <div class="menu-container">
      <nb-tabset>
        <nb-tab tabTitle="WINS">
          <app-buyer-wins #wins></app-buyer-wins>
        </nb-tab>
      </nb-tabset>
    </div>
  </ng-template>
</div>