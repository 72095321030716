<div class="container-fluid">

    <nb-card class="auction-card" [ngClass]="{ 'auction-card-focus': featured }">

      <nb-card-body [ngStyle]="getCardStyle()">

        <ng-container *ngIf="auction.isLive() || auction.isClosed()">
                <div class="row">
                    <div class="col no-gutters">
                        <div class="flex-center">
                            <div class="flex-center" [ngClass]="{ 'happening-now': auction.isLive(), 'closed': auction.isClosed() }">{{ getBannerText() }}</div>
                        </div>
                    </div>
                </div>
        </ng-container>

        <div class="row mb-4 logo-container">
            <div class="col">
                <div class="flex-center">
                    <a class="logo flex-center" *ngIf="auction.saleInfoPosted()" [routerLink]="" (click)="onViewDetails()">
                        <img class="logo" src="{{ auction.getLogoUrl() }} ">
                    </a>
                    <img *ngIf="!auction.saleInfoPosted()" class="logo flex-center" src="{{ auction.getLogoUrl() }} ">
                </div>
            </div>
        </div>

        <div class="row mb-2">
            <div class="col">
                <div class="auction-title"> {{ auction.title }} </div>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col">
                <div class="auction-sale-end-date">Sale Ends: {{ getFormattedEndDate() }} </div>
            </div>
        </div>

        <div class="row mb-2">
            <div class="col link-text">
                <a *ngIf="auction.saleInfoPosted()" [routerLink]="" (click)="onViewDetails()">VIEW DETAILS</a>
            </div>
        </div>

      </nb-card-body>
    </nb-card>

</div>
