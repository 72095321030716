import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-authhome',
  templateUrl: './authhome.component.html',
  styleUrls: ['./authhome.component.scss']
})
export class AuthhomeComponent implements OnInit {

  authPageName:string = "login";
  authPageId = 1;
  authPageValues = {
    'signin':1,
    'changepassword':2,
    'forgotpassword':3,
    'signup':4
  };
  
  constructor(private authService: AuthService,
              protected ref: NbDialogRef<AuthhomeComponent>){}

  ngOnInit(): void {
    this.authPageId = this.authPageValues[this.authPageName];
  }
  
  async receiveMessage($event) {
    // console.log('receiveMessage',$event);
    this.ref.close($event);
  }

}
