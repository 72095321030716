import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule,  } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MarkdownModule } from 'ngx-markdown';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';


// import { RouterModule } from '@angular/router'; // we also need angular router for Nebular to function properly
import { NbAccordionModule, NbThemeModule } from '@nebular/theme';
import { NbLayoutModule } from '@nebular/theme';
import { NbTabsetModule } from '@nebular/theme';
import { NbButtonModule } from '@nebular/theme';
import { NbSpinnerModule } from '@nebular/theme';
import { NbCardModule } from '@nebular/theme';
import { NbCheckboxModule } from '@nebular/theme';
import { NbTooltipModule } from '@nebular/theme';
import { NbSelectModule } from '@nebular/theme';
import { NbInputModule } from '@nebular/theme';
import { NbMenuModule } from '@nebular/theme';
import { NbDialogModule } from '@nebular/theme';
import { NbContextMenuModule } from '@nebular/theme';
import { NbToggleModule } from '@nebular/theme';
import { NbPopoverModule } from '@nebular/theme';
import { NbFormFieldModule } from '@nebular/theme';
import { NbIconModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbStepperModule } from '@nebular/theme';
import { NbAutocompleteModule } from '@nebular/theme';

// import { AmplifyService, AmplifyModules } from 'aws-amplify-angular';
import Auth from '@aws-amplify/auth';
import {Amplify} from '@aws-amplify/core';
import API from '@aws-amplify/api';
// import Interactions from '@aws-amplify/interactions';
// import Storage from '@aws-amplify/storage';

import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { AuthService } from './services/auth.service';
import { AuthGuard, AuthAdminGuard, AuthSellerGuard } from './services/auth-guard.service';

import { AccountService, PortalUser } from './model/portaluser.model';
import { AuctionModelService } from './model/auction.model';
import { InvoiceModelService } from './model/invoice.model';

import { environment } from '../environments/environment';
import { DndDirective } from './dnd/dnd.directive';
import { SigninComponent } from './auth/signin/signin.component';
import { AuthhomeComponent } from './auth/authhome/authhome.component';
import { SignupComponent } from './auth/signup/signup.component';
import { ChangepasswordComponent } from './auth/changepassword/changepassword.component';
import { ForgotpasswordComponent } from './auth/forgotpassword/forgotpassword.component';
import { SettingshomeComponent } from './accounts/settingshome/settingshome.component';
import { AccountsettingsComponent } from './accounts/accountsettings/accountsettings.component';
import { SettingssidebarComponent } from './accounts/settingssidebar/settingssidebar.component';
import { BidsandsavesComponent } from './accounts/bidsandsaves/bidsandsaves.component';
import { FollowingComponent } from './accounts/following/following.component';
import { MysalesComponent } from './accounts/mysales/mysales.component';
import { AuctionsListComponent } from './auctions/auctions-list/auctions-list.component';
import { AuctionCardComponent } from './auctions/auction-card/auction-card.component';
import { AuctiondetailshomeComponent } from './auctiondetails/auctiondetailshome/auctiondetailshome.component';
import { AuctiondetailsheaderComponent } from './auctiondetails/auctiondetailsheader/auctiondetailsheader.component';
import { AuctionDetailsBannerComponent } from './auctiondetails/auction-details-banner/auction-details-banner.component';
import { AuctiondetailssocialComponent } from './auctiondetails/auctiondetailssocial/auctiondetailssocial.component';
import { LotslistComponent } from './auctiondetails/lotslist/lotslist.component';
import { LotdetailComponent } from './auctiondetails/lotdetail/lotdetail.component';
import { LotdetailtextComponent } from './auctiondetails/lotdetailtext/lotdetailtext.component';
import { AuctionsaleinformationComponent } from './auctiondetails/auctionsaleinformation/auctionsaleinformation.component';
import { BiddingComponent } from './auctiondetails/bidding/bidding.component';
import { AuctionlotdetailsComponent } from './auctiondetails/auctionlotdetails/auctionlotdetails.component';
import { ManageauctionsComponent } from './auctions/manageauctions/manageauctions.component';
import { AuctionslistComponent } from './auctions/auctionslist/auctionslist.component';
import { AuctiondetailComponent } from './auctions/auctiondetail/auctiondetail.component';
import { AuctionedithomeComponent } from './auctions/auctionedithome/auctionedithome.component';
import { GlobalStateService } from './services/globalstate.service';
import { AuctionBidsHomeComponent } from './auctions/auction-bids-home/auction-bids-home.component';
import { AuctionreportComponent } from './auctions/auctionreport/auctionreport.component';
import { AuctionReportLotListComponent } from './auctions/auction-report-lot-list/auction-report-lot-list.component';
import { AuctionReportLotDetailComponent } from './auctions/auction-report-lot-detail/auction-report-lot-detail.component';
import { LotDetailDescComponent } from './auctions/lot-detail-desc/lot-detail-desc.component';
import { LotCardComponent } from './auctions/lot-card/lot-card.component';
import { AuctionBidderListComponent } from './auctions/auction-bidder-list/auction-bidder-list.component';
import { AuctionBidderDetailComponent } from './auctions/auction-bidder-detail/auction-bidder-detail.component';


import { NgImageSliderModule } from 'ng-image-slider';
import { FooterComponent } from './footer/footer.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { InvoiceLineItemListComponent } from './invoice/invoice-line-item-list/invoice-line-item-list.component';
import { InvoiceLineItemComponent } from './invoice/invoice-line-item/invoice-line-item.component';
import { LotLineageComponent } from './auctiondetails/lot-lineage/lot-lineage.component';
import { LotLineageDetailComponent } from './auctiondetails/lot-lineage-detail/lot-lineage-detail.component';
import { LotEpdsComponent } from './auctiondetails/lot-epds/lot-epds.component';
import { SellerHomeComponent } from './sellers/seller-home/seller-home.component';
import { AuctionsHomeComponent } from './auctions/auctions-home/auctions-home.component';
import { AuctionTimeRemainingCounterComponent } from './auctions/auction-time-remaining-counter/auction-time-remaining-counter.component';
import { SectionHeaderComponent } from './common/section-header/section-header.component';

import { NgxMaskModule, IConfig } from 'ngx-mask';
import { BuyerHomeComponent } from './buyers/buyer-home/buyer-home.component';
import { BuyerWinsComponent } from './buyers/buyer-wins/buyer-wins.component';
import { BuyerBiddingPortalHomeComponent } from './buyers/buyer-bidding-portal-home/buyer-bidding-portal-home.component';
import { BuyerAuctionWinListComponent } from './buyers/buyer-auction-win-list/buyer-auction-win-list.component';
import { BuyerAuctionWinListDetailComponent } from './buyers/buyer-auction-win-list-detail/buyer-auction-win-list-detail.component'
import { CreditCardEntryFormComponent } from './payments/credit-card-entry-form/credit-card-entry-form.component';
import { BackNavButtonComponent } from './common/back-nav-button/back-nav-button.component';
import { EventService } from './services/event.service';
import { VisibilityService } from './services/visibility.service';
import { PlyrModule } from 'ngx-plyr';
import { NotFoundComponent } from './not-found/not-found.component';
import { AdCardComponent } from './ad-card/ad-card.component';
import { CommunityHomeComponent } from './community/community-home/community-home.component';
import { ConnectivityService } from './services/connectivity.service';

Amplify.configure(environment.awsconfig);
Auth.configure(environment.awsconfig);
API.configure(environment.awsconfig);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    DndDirective,
    SigninComponent,
    AuthhomeComponent,
    SignupComponent,
    ChangepasswordComponent,
    ForgotpasswordComponent,
    SettingshomeComponent,
    AccountsettingsComponent,
    SettingssidebarComponent,
    BidsandsavesComponent,
    FollowingComponent,
    MysalesComponent,
    AuctionsListComponent,
    AuctionCardComponent,
    AuctiondetailshomeComponent,
    AuctiondetailsheaderComponent,
    AuctionDetailsBannerComponent,
    AuctiondetailssocialComponent,
    LotslistComponent,
    LotdetailComponent,
    LotdetailtextComponent,
    AuctionsaleinformationComponent,
    BiddingComponent,
    AuctionlotdetailsComponent,
    FooterComponent,
    InvoiceComponent,
    InvoiceLineItemListComponent,
    InvoiceLineItemComponent,
    CreditCardEntryFormComponent,
    LotLineageComponent,
    LotLineageDetailComponent,
    LotEpdsComponent,
    SellerHomeComponent,
    AuctionsHomeComponent,
    AuctionTimeRemainingCounterComponent,
    SectionHeaderComponent,
    BuyerHomeComponent,
    BuyerWinsComponent,
    BuyerBiddingPortalHomeComponent,
    BuyerAuctionWinListComponent,
    BuyerAuctionWinListDetailComponent,
    BackNavButtonComponent,
    NotFoundComponent,
    AdCardComponent,
    CommunityHomeComponent,
    ManageauctionsComponent,
    AuctionslistComponent,
    AuctiondetailComponent,
    AuctionedithomeComponent,
    AuctionBidsHomeComponent,
    AuctionreportComponent,
    AuctionReportLotListComponent,
    AuctionReportLotDetailComponent,
    LotDetailDescComponent,
    LotCardComponent,
    AuctionBidderListComponent,
    AuctionBidderDetailComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    NbLayoutModule,
    NbThemeModule.forRoot({ name: 'g2live' }),
    NbMenuModule.forRoot(),
    NbDialogModule.forRoot(),
    NbContextMenuModule,
    NbTabsetModule,
    NbButtonModule,
    NbSpinnerModule,
    NbCardModule,
    NbCheckboxModule,
    NbTooltipModule,
    BrowserAnimationsModule,
    NbSelectModule,
    NbInputModule,
    NbToggleModule,
    NbPopoverModule,
    NbFormFieldModule,
    NbIconModule,
    NbEvaIconsModule,
    HttpClientModule,
    NbStepperModule,
    NgImageSliderModule,
    NbAccordionModule,
    MarkdownModule.forRoot(),
    NgxMaskModule.forRoot(),
    NbAutocompleteModule,
    PlyrModule
  ],
  entryComponents: [
    SigninComponent
  ],
  providers: [
    AuthService,
    AuthGuard,
    AuthAdminGuard,
    AuthSellerGuard,
    AuctionModelService,
    InvoiceModelService,
    AccountService,
    EventService,
    VisibilityService,
    ConnectivityService,
    GlobalStateService,
    PortalUser,
    // {
    //   provide: AmplifyService,
    //   useFactory:  () => {
    //     return AmplifyModules({
    //       Auth,
    //       // Storage,
    //       // Interactions
    //     });
    //   }
    // },

  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
