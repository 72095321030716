import { Injectable } from '@angular/core';

export enum EditModelType {
  AUCTION = "AUCTION",
  LOT = "LOT",
  SELLER = "SELLER",
  USER = "USER"
}


@Injectable()
export class GlobalStateService {
  
  public editNewInProgress:boolean = false;
  public editNewIsValid:boolean = false;
  public saveCallbackThis;
  public saveCallback;
  public routeCallbackThis;
  public routeCallback;
  public editType:EditModelType;

  constructor() {
  }
  
  init(){
    this.editNewInProgress = false;
    this.editNewIsValid = false;
    this.saveCallbackThis = undefined;
    this.saveCallback = undefined;
    this.routeCallback = undefined;
    this.routeCallbackThis = undefined;
    this.editType = undefined;
  }

}