import { Component, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { AuthhomeComponent } from 'src/app/auth/authhome/authhome.component';
import { PortalUser } from 'src/app/model/portaluser.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-buyer-bidding-portal-home',
  templateUrl: './buyer-bidding-portal-home.component.html',
  styleUrls: ['./buyer-bidding-portal-home.component.scss']
})
export class BuyerBiddingPortalHomeComponent implements OnInit {

  @ViewChild('wins') wins;

  constructor(public authService: AuthService,
    private dialogService: NbDialogService,
    private portalUserService: PortalUser) { }

  async ngOnInit() {
    await this.authService.getAuthenticatedUser();
    await this.portalUserService.me();
    if (this.wins) {
      this.wins.loadPageData();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.portalUserService) {
      if (this.wins) {
        this.wins.loadPageData();
      }
    }
  }

  public onSignIn() {
    this.openAuth("signin");
  }

  public onSignUp() {
    this.openAuth("signup");
  }

  private openAuth(pageName) {
    let localThis = this;
    this.dialogService.open(AuthhomeComponent, {
        hasScroll:false,
        closeOnBackdropClick:false,
        closeOnEsc:false,
        context:{
          authPageName: pageName
        }
      }).onClose.subscribe(event => localThis.processMsg(event));
  }

  processMsg(event) {
    if (!event) return;

    var msg = event['message'];
    if (["signinsuccessful", "signupcomplete"].includes(msg)) {
      if (this.wins) {
        this.wins.loadPageData();
      }
    }
  }
}
