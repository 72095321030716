import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { AuctionResultLineItem } from 'src/app/model/auction.model';
import moment from 'moment';
import { PaymentStatus } from 'src/app/API.service';

interface AuctionWinListing {
  [auctionKey: string]: {
    name: string;
    auctionStartDate: string;
    sellers: AuctionSeller
  }
}

interface AuctionSeller {
  [sellerKey: string]: {
    name: string;
    paymentStatus: PaymentStatus;
    paymentDate: string;
    lineItems: AuctionResultLineItem[];
  }
}

@Component({
  selector: 'app-buyer-auction-win-list',
  templateUrl: './buyer-auction-win-list.component.html',
  styleUrls: ['./buyer-auction-win-list.component.scss']
})
export class BuyerAuctionWinListComponent implements OnInit {

  @Input() lineItems: AuctionResultLineItem[] = [];

  public groupListing: AuctionWinListing = {};
  public loading: { [combinedAuctionSellerKey: string]: boolean } = {};

  constructor() { }

  ngOnInit(): void {
    this.groupListing = this.groupAuctionReportByAuctionAndSeller();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.lineItems && changes.lineItems.currentValue !== changes.lineItems.previousValue) {
      this.groupListing = this.groupAuctionReportByAuctionAndSeller();
    }
  }

  public auctionKeys() {
    return Object.keys(this.groupListing);
  }

  public sellerKeys(auctionKey: string) {
    if (!this.groupListing.hasOwnProperty(auctionKey)) return {};

    return Object.keys(this.groupListing[auctionKey].sellers);
  }

  private groupAuctionReportByAuctionAndSeller(): AuctionWinListing {
    var result = {} as AuctionWinListing;

    this.lineItems.forEach(item => {
      const auctionKey = item.auctionId || "Unknown";
      const sellerKey = item.sellerEmail || "Unknown";
      if (!result.hasOwnProperty(auctionKey)) {
        result[auctionKey] = {
          name: item.auctionName,
          auctionStartDate: item.auctionStartDate,
          sellers: {} as AuctionSeller
        }
      }
      const sellers = result[auctionKey].sellers;
      if (!sellers.hasOwnProperty(sellerKey)) {
        sellers[sellerKey] = {
          name: item.sellerName,
          paymentStatus: item.paymentStatus,
          paymentDate: this.getInvoicePaymentDate(item),
          lineItems: []
        }
      }
      sellers[sellerKey].lineItems.push(item);
    });

    return result;
  }

  public getInvoicePaymentDate(item: AuctionResultLineItem) {
    if (item.paymentStatus === PaymentStatus.AUTOMATED_PAID && item.invoice) {
      return item.invoice.datePaid;
    } else if ([PaymentStatus.DIRECT_PAID, PaymentStatus.PAID_SALE_REP].includes(item.paymentStatus)) {

    } else {
      return null;
    }
  }

  public formatAsMonthYear(dateString: string): string {
    const momentDate = moment(dateString);
    if (!momentDate.isValid()) return dateString;
    return momentDate.format("MM/DD hh:mma");
  }

  public invoiceCreated(auctionKey: string, sellerKey: string) {
    return this.groupListing[auctionKey].sellers[sellerKey].lineItems.length > 0 && this.groupListing[auctionKey].sellers[sellerKey].lineItems[0].invoiceId;
  }

  public invoicePaid(auctionKey: string, sellerKey: string) {
    return [PaymentStatus.AUTOMATED_PAID, PaymentStatus.PAID_SALE_REP].includes(this.groupListing[auctionKey].sellers[sellerKey].paymentStatus);
  }

  public onViewInvoice(auctionKey: string, sellerKey: string) {
    const invoiceId = this.groupListing[auctionKey].sellers[sellerKey].lineItems[0].invoiceId;
    window.open(
      `/invoice/${invoiceId}`,
      '_blank'
    );
  }

  public formatAuctionDate(auctionKey: string) {
    const momentDate = moment(this.groupListing[auctionKey].auctionStartDate);
    if (!momentDate.isValid()) return null;

    return momentDate.format("ddd, MMMM DD, YYYY")
  }
}
