import { Component, OnInit, Input } from '@angular/core';
import { LotModel } from '../../model/auction.model';

@Component({
  selector: 'app-lot-detail-desc',
  templateUrl: './lot-detail-desc.component.html',
  styleUrls: ['./lot-detail-desc.component.scss']
})
export class LotDetailDescComponent implements OnInit {
  @Input() lot:LotModel;

  constructor() { }

  ngOnInit(): void {
  }
  
  getCategory(){
    var returnValue = "";
    if(this.lot.category != undefined && this.lot.category.trim().length > 0){
      let lotCategory = LotModel.LOT_CATEGORIES.find((el)=>{return el.key == this.lot.category});
      returnValue = lotCategory != undefined ? lotCategory["value"] : "";
    }
    return returnValue;
  }
  
  getTitle(){
    return this.lot.title != undefined ? this.lot.title : "";
  }

  getLotNum(){
    return this.lot.lotNum != undefined ? this.lot.lotNum : "";
  }
  

}
