import { Component, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { PortalUser } from '../model/portaluser.model';
import { AuthService } from '../services/auth.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {


  @Output() messageEvent = new EventEmitter<any>();

  constructor( private authService: AuthService,
               private router: Router) { }

  ngOnInit(): void {
    this.router.navigate(['/auctions']);
  }

}
