<div class="container-fluid container-vert-padded" ng-cloak *ngIf="!loading">

  <div class="invoice-summary">
    <div class="row">
      <div class="col">
        <img src="../../assets/brand/logo.svg">
      </div>
    </div>
    <div class="row">
      <div class="col-sm-7 col-lg-8 pt-3">
        <h4 class="invoice-header">Invoice</h4>
        <div class="mt-3">Invoice #: {{ invoiceId }}</div>
        <div class="mt-2">Date: {{ invoiceDateFormatted }}</div>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-sm-7 col-lg-8">
        <h6 class="mt-2">Due Date: Due Upon Receipt</h6>
      </div>
      <div class="col-sm-5 col-lg-4 mt-3 mt-sm-0">
        <button nbButton status="primary" outline class="float-md-right" (click)="printInvoice()">Print<nb-icon icon="printer-outline"></nb-icon></button>
      </div>
    </div>
  </div>

  <hr />

  <div class="row">
    <div class="invoice-buyer-info col-md-6 col-12">
      <div class="invoice-section-header">Buyer Information</div>
      <dl class="row mt-2">
        <dt class="col-sm-3 data-label">Name</dt>
        <dd class="col-sm-9">{{ invoice.buyerName }}</dd>
        <dt class="col-sm-3 data-label">Address</dt>
        <dd class="col-sm-9">
          <ng-container *ngFor="let addressLine of invoice.buyerAddress">
            {{ addressLine }} <br />
          </ng-container>
        </dd>
        <dt class="col-sm-3 data-label">Phone number</dt>
        <dd class="col-sm-9">{{ invoice.buyerPhoneNumber }}</dd>
        <dt class="col-sm-3 data-label">Email</dt>
        <dd class="col-sm-9">{{ invoice.buyerEmail }}</dd>
      </dl>
    </div>

    <div class="invoice-sale-info col-md-6 col-12">
      <div class="invoice-section-header">Sale Information</div>
      <dl class="row mt-2">
        <dt class="col-sm-3 data-label">Auction Name</dt>
        <dd class="col-sm-9">{{ invoice.saleAuctionName }}</dd>
        <dt class="col-sm-3 data-label">Closing Date</dt>
        <dd class="col-sm-9">{{ saleAuctionClosingDateFormatted }}</dd>
        <dt class="col-sm-3 data-label">Seller</dt>
        <dd class="col-sm-9">{{ invoice.sellerName }}</dd>
        <dt class="col-sm-3 data-label">Phone Number</dt>
        <dd class="col-sm-9">{{ invoice.sellerPhoneNumber }}</dd>
        <dt class="col-sm-3 data-label">Email</dt>
        <dd class="col-sm-9">{{ invoice.sellerEmail }}</dd>
      </dl>
    </div>
  </div>

  <hr />

  <div class="row">
    <div class="invoice-seller-notes col">
      <div class="invoice-section-header">Seller Notes</div>
      <p class="mt-2">{{ invoice.sellerNotes ? invoice.sellerNotes : "None" }}</p>
    </div>
  </div>

  <hr />

  <div class="row">
    <div class="invoice-purchased-lots col">
      <div class="invoice-section-header">Purchased Lots</div>

      <div class="mt-3">
        <app-invoice-line-item-list [lineItems]="invoice.lineItems"></app-invoice-line-item-list>
      </div>
      <div class="d-flex flex-row justify-content-end mt-3">
        <div class="total-amount-header">Subtotal</div>
        <div class="total-amount-header ml-2 mr-1">{{ invoice.totalAmountDue | currency }}</div>
      </div>
      <div class="d-flex flex-row justify-content-end mt-3" *ngIf="false">
        <div>Late Fees</div>
        <div class="ml-2 mr-1">$0</div>
      </div>
    </div>
  </div>

  <div class="invoice-payment-section mt-3" *ngIf="!invoice.paid else paid">
    <div class="row">
      <div class="col">
        <p class="mt-2">Thank you for your purchase! Please pay within 10 days by one of the methods below. If you have any questions, please call us at 406-833-0117. Thanks again for your business - we appreciate it!</p>

        <p>Please choose a payment method below:</p>
        <nb-accordion>
          <nb-accordion-item>
            <nb-accordion-item-header>Pay By Credit Card</nb-accordion-item-header>
            <nb-accordion-item-body>
              <p class="mt-2">Angus Live charges a credit card processing fee of 5% for transactions. The calculated amount is below.</p>

              <div class="row mt-3">
                <div class="col col-md-8 col-lg-6">
                  <div class="d-flex flex-row justify-content-between">
                    <div>Processing Fees</div>
                    <div><strong>{{ invoice.totalAmountDue * 0.05 | currency }}</strong></div>
                  </div>
                  <div class="d-flex flex-row justify-content-between">
                    <div class="total-amount-header">Total Amount Due</div>
                    <div class="total-amount-header"><strong>{{ invoice.totalAmountDue * 1.05 | currency }}</strong></div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col col-md-8 col-lg-6">
                  <app-credit-card-entry-form [clientPaymentCode]="paymentClientSecret" [amountInCents]="(invoice.totalAmountDue * 100) * 1.05" (messageEvent)="receiveMessage($event)"></app-credit-card-entry-form>
                </div>
              </div>
            </nb-accordion-item-body>
          </nb-accordion-item>
          <nb-accordion-item>
            <nb-accordion-item-header>Pay by Personal or Cashiers Check</nb-accordion-item-header>
            <nb-accordion-item-body>
              <h6 class="mt-2">Instructions:</h6>
              <p>1. Print out this invoice:</p>
              <button nbButton status="primary" outline (click)="printInvoice()">Print<nb-icon icon="printer-outline"></nb-icon></button>

              <p class="mt-3">2. Enclose your check for the amount due along with the printed invoice. We do not accept any checks from outside the U.S.</p>

              <p class="mt-2">
                Mail checks to:<br />
                G2 Live LLC <br />
                41338 Ridgeway Road <br />
                Charlo, MT 59824
              </p>
            </nb-accordion-item-body>
          </nb-accordion-item>
        </nb-accordion>
      </div>
    </div>
  </div>
  <ng-template #paid>
    <h6 class="mt-3">Thank you for your payment!</h6>
  </ng-template>

  <div class="row mt-3">
    <div class="col">
      <dl class="row mt-3">
        <dt class="col-6 col-sm-3 data-label">Date Paid</dt>
        <dd class="col-6 col-sm-9">{{ datePaidFormatted }}</dd>
        <dt class="col-6 col-sm-3 data-label mt-2">Payment Method</dt>
        <dd class="col-6 col-sm-9 mt-2">{{ invoice.paymentMethod }}</dd>
        <dt class="col-6 col-sm-3 data-label mt-2">Amount</dt>
        <dd class="col-6 col-sm-9 mt-2">{{ (invoice.totalAmountPaid ? invoice.totalAmountPaid : 0) | currency}}</dd>
      </dl>
    </div>
  </div>
</div>