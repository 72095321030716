import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-settingssidebar',
  templateUrl: './settingssidebar.component.html',
  styleUrls: ['./settingssidebar.component.scss']
})
export class SettingssidebarComponent implements OnInit {
  errorMsg = "";
  processing: boolean = false;
  
  activeState = {
    1:true,
    2:false,
    3:false,
    4:false
  }

  menuChangeMessage = {
    1:"accountsettings",
    2:"mybidsandsaves",
    3:"following",
    4:"mysales"
  }

  @Output() messageEvent = new EventEmitter<any>();

  constructor(private router:Router) { }

  ngOnInit(): void {
    
  }
  
  getActiveStateStyle(menuNumber){
    return this.activeState[menuNumber]==true ? 'link-text-active' : 'link-text';
  }
  
  setActive(menuNumber){
    for(let key in this.activeState){
      this.activeState[key] = key==menuNumber ? true : false;
    }
    this.notifyMenuChange(menuNumber);
  }
  
  notifyMenuChange(menuNumber){
    var eventMsg = {}
    eventMsg['message'] = this.menuChangeMessage[menuNumber];
    this.messageEvent.emit(eventMsg);
  }
  

}
