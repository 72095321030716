<div class="container-fluid" id="lineagecontainer">
    
    <!-- Vertical line connecting paternal sire to dam -->
    <svg class="svgline" *ngIf="getDamSireLineCoord() != undefined">
        <line class="svglineelement" 
              #line 
              id="damsireline" 
              [attr.x1]="damSireLineCoord['x1']" 
              [attr.y1]="damSireLineCoord['y1']" 
              [attr.x2]="damSireLineCoord['x2']" 
              [attr.y2]="damSireLineCoord['y2']" />
    </svg>

    <!-- Vertical line connecting paternal grand sire to paternal grand dam -->
    <svg class="svgline" *ngIf="getGrandDamSireLineCoord() != undefined">
        <line class="svglineelement" 
              #line 
              id="grandsiredamsireline" 
              [attr.x1]="grandSireDamSireLineCoord['x1']" 
              [attr.y1]="grandSireDamSireLineCoord['y1']" 
              [attr.x2]="grandSireDamSireLineCoord['x2']" 
              [attr.y2]="grandSireDamSireLineCoord['y2']" />
    </svg>

    <svg class="svgline" *ngIf="getGrandDamDamLineCoord() != undefined">
        <line class="svglineelement" 
              #line 
              id="granddamdamsireline" 
              [attr.x1]="grandDamDamSireLineCoord['x1']" 
              [attr.y1]="grandDamDamSireLineCoord['y1']" 
              [attr.x2]="grandDamDamSireLineCoord['x2']" 
              [attr.y2]="grandDamDamSireLineCoord['y2']" />
    </svg>

    <!-- horiz line connecting lineage root point to sire/dam tree -->
    <svg class="svgline" *ngIf="getLineagePointCoord() != undefined">
        <line class="svglineelement" 
              #line 
              id="lineagepointhorizline" 
              [attr.x1]="lineageRootPointCoord['x1']" 
              [attr.y1]="lineageRootPointCoord['y1']" 
              [attr.x2]="lineageRootPointCoord['x2']" 
              [attr.y2]="lineageRootPointCoord['y2']" />
    </svg>
    
    <ng-container *ngIf="hasAllLineages()">
        <!-- Paternal Grand Sire -->
        <div class="row mb-1">
            <div class="col-1">
            </div>
            <div class="col-5">
            </div>
            <div class="col-5" id="paternalgrandsirecontainer">
                <app-lot-lineage-detail id="sireparentsireelement" *ngIf="sireParentSire != undefined" [detail]="sireParentSire"></app-lot-lineage-detail>
            </div>
            <div class="col"></div>
        </div> 
    
        <!-- Sire -->
        <div class="row mb-1">
            <div class="col-1">
            </div>
            <div class="col-5 justify-content-center align-self-center" id="sireelementcontainer">
                <app-lot-lineage-detail id="sireelement" *ngIf="sire != undefined" [detail]="sire"></app-lot-lineage-detail>
            </div>
            <div class="col-5">
            </div>
            <div class="col"></div>
        </div> 
    
        <!-- Paternal Grand Dam -->
        <div class="row mb-1">
            <div class="col-1">
            </div>
            <div class="col-5">
            </div>
            <div class="col-5" id="paternalgranddamcontainer">
                <app-lot-lineage-detail id="sireparentdamelement" *ngIf="sireParentDam != undefined" [detail]="sireParentDam"></app-lot-lineage-detail>
            </div>
            <div class="col"></div>
        </div> 
        
        <!-- Lineage root point -->
        <div class="row mb-1">
            <div class="col-1 justify-content-center align-items-center" id="lineagerootcontainer">
                <!--<span class="lineage-point"></span>-->
                <div>
                    <nb-icon id="lineagerooticon" icon="radio-button-on" status="primary" class="lineage-icon"></nb-icon> 
                </div>
            </div>
            <!--<div class="col-5"></div>-->
            <!--<div class="col-5"></div>-->
            <div class="col-5"></div>
        </div> 
        
        <!-- Maternal Grand Sire -->
        <div class="row mb-1">
            <div class="col-1">
            </div>
            <div class="col-5">
            </div>
            <div class="col-5" id="maternalgrandsirecontainer">
                <app-lot-lineage-detail id="damparentsireelement" *ngIf="damParentSire != undefined" [detail]="damParentSire"></app-lot-lineage-detail>
            </div>
            <div class="col"></div>
        </div> 
        
        <!-- Dam -->
        <div class="row mb-1">
            <div class="col-1">
            </div>
            <div class="col-5" id="damelementcontainer">
                <app-lot-lineage-detail id="damelement" *ngIf="dam != undefined" [detail]="dam"></app-lot-lineage-detail>
            </div>
            <div class="col-5">
            </div>
            <div class="col"></div>
        </div> 
    
        <!-- Maternal Grand Dam -->
        <div class="row mb-1">
            <div class="col-1">
            </div>
            <div class="col-5">
            </div>
            <div class="col-5" id="maternalgranddamcontainer">
                <app-lot-lineage-detail id="damparentdamelement" *ngIf="damParentDam != undefined" [detail]="damParentDam"></app-lot-lineage-detail>
            </div>
            <div class="col"></div>
        </div> 
    </ng-container> <!-- All lineages -->

    <ng-container *ngIf="hasParentLineagesOnly()">

        <!-- Sire -->
        <div class="row mb-1">
            <div class="col-1">
            </div>
            <div class="col-11 justify-content-center align-self-center" id="sireelementcontainer">
                <app-lot-lineage-detail id="sireelement" *ngIf="sire != undefined" [detail]="sire"></app-lot-lineage-detail>
            </div>
        </div> 
    
        <!-- Lineage root point -->
        <div class="row mb-1">
            <div class="col-1 justify-content-center align-self-center" id="lineagerootcontainer">
                <!--<span class="lineage-point"></span>-->
                <div>
                    <nb-icon id="lineagerooticon" icon="radio-button-on" status="primary" class="lineage-icon"></nb-icon> 
                </div>
            </div>
            <div class="col"></div>
        </div> 
        
        <!-- Dam -->
        <div class="row mb-1">
            <div class="col-1">
            </div>
            <div class="col-11" id="damelementcontainer">
                <app-lot-lineage-detail id="damelement" *ngIf="dam != undefined" [detail]="dam"></app-lot-lineage-detail>
            </div>
        </div> 
    
    </ng-container> <!-- Parent lineages only -->
    
    <ng-container *ngIf="hasSireGrandLineage() && hasDamLineage() && hasSireLineage() && !hasDamGrandLineage()">
        <!-- Paternal Grand Sire -->
        <div class="row mb-1">
            <div class="col-1">
            </div>
            <div class="col-5">
            </div>
            <div class="col-5" id="paternalgrandsirecontainer">
                <app-lot-lineage-detail id="sireparentsireelement" *ngIf="sireParentSire != undefined" [detail]="sireParentSire"></app-lot-lineage-detail>
            </div>
            <div class="col"></div>
        </div> 
    
        <!-- Sire -->
        <div class="row mb-1">
            <div class="col-1">
            </div>
            <div class="col-5 justify-content-center align-self-center" id="sireelementcontainer">
                <app-lot-lineage-detail id="sireelement" *ngIf="sire != undefined" [detail]="sire"></app-lot-lineage-detail>
            </div>
            <div class="col-5">
            </div>
            <div class="col"></div>
        </div> 
    
        <!-- Paternal Grand Dam -->
        <div class="row mb-1">
            <div class="col-1">
            </div>
            <div class="col-5">
            </div>
            <div class="col-5" id="paternalgranddamcontainer">
                <app-lot-lineage-detail id="sireparentdamelement" *ngIf="sireParentDam != undefined" [detail]="sireParentDam"></app-lot-lineage-detail>
            </div>
            <div class="col"></div>
        </div> 
        
        <!-- Lineage root point -->
        <div class="row mb-1">
            <div class="col-1 justify-content-center align-self-center" id="lineagerootcontainer">
                <!--<span class="lineage-point"></span>-->
                <div>
                    <nb-icon id="lineagerooticon" icon="radio-button-on" status="primary" class="lineage-icon"></nb-icon> 
                </div>
            </div>
            <div class="col"></div>
        </div> 
        
        <!-- Maternal Grand Sire -->
        <div class="row mb-4">
            <div class="col-1">
            </div>
            <div class="col-5">
            </div>
            <div class="col-5">
                <span>&nbsp;</span>
            </div>
            <div class="col"></div>
        </div> 
        
        <!-- Dam -->
        <div class="row mb-1">
            <div class="col-1">
            </div>
            <div class="col-5" id="damelementcontainer">
                <app-lot-lineage-detail id="damelement" *ngIf="dam != undefined" [detail]="dam"></app-lot-lineage-detail>
            </div>
            <div class="col-5">
            </div>
            <div class="col"></div>
        </div> 
    
    </ng-container> <!-- All lineages -->
    
    <ng-container *ngIf="!hasSireGrandLineage() && hasDamLineage() && hasSireLineage() && hasDamGrandLineage()">
        <!-- Paternal Grand Sire -->
        <div class="row mb-4">
            <div class="col-1">
            </div>
            <div class="col-5">
            </div>
            <div class="col-5">
                <span>&nbsp;</span>
            </div>
            <div class="col"></div>
        </div> 
    
        <!-- Sire -->
        <div class="row mb-1">
            <div class="col-1">
            </div>
            <div class="col-5 justify-content-center align-self-center" id="sireelementcontainer">
                <app-lot-lineage-detail id="sireelement" *ngIf="sire != undefined" [detail]="sire"></app-lot-lineage-detail>
            </div>
            <div class="col-5">
            </div>
            <div class="col"></div>
        </div> 
    
        <!-- Paternal Grand Dam -->
        <div class="row mb-4">
            <div class="col-1">
            </div>
            <div class="col-5">
            </div>
            <div class="col-5">
                <span>&nbsp;</span>
            </div>
            <div class="col"></div>
        </div> 
        
        <!-- Lineage root point -->
        <div class="row mb-1">
            <div class="col-1 justify-content-center align-self-center" id="lineagerootcontainer">
                <!--<span class="lineage-point"></span>-->
                <div>
                    <nb-icon id="lineagerooticon" icon="radio-button-on" status="primary" class="lineage-icon"></nb-icon> 
                </div>
            </div>
            <div class="col"></div>
        </div> 
        
        <!-- Maternal Grand Sire -->
        <div class="row mb-1">
            <div class="col-1">
            </div>
            <div class="col-5">
            </div>
            <div class="col-5" id="maternalgrandsirecontainer">
                <app-lot-lineage-detail id="damparentsireelement" *ngIf="damParentSire != undefined" [detail]="damParentSire"></app-lot-lineage-detail>
            </div>
            <div class="col"></div>
        </div> 
        
        <!-- Dam -->
        <div class="row mb-1">
            <div class="col-1">
            </div>
            <div class="col-5" id="damelementcontainer">
                <app-lot-lineage-detail id="damelement" *ngIf="dam != undefined" [detail]="dam"></app-lot-lineage-detail>
            </div>
            <div class="col-5">
            </div>
            <div class="col"></div>
        </div> 
    
        <!-- Maternal Grand Dam -->
        <div class="row mb-1">
            <div class="col-1">
            </div>
            <div class="col-5">
            </div>
            <div class="col-5" id="maternalgranddamcontainer">
                <app-lot-lineage-detail id="damparentdamelement" *ngIf="damParentDam != undefined" [detail]="damParentDam"></app-lot-lineage-detail>
            </div>
            <div class="col"></div>
        </div> 
    </ng-container> <!-- All lineages -->
    
    
</div> <!-- container-fluid -->
