<div class="modal-scroll auth-card">
    <nb-card>
            <nb-card-header style="padding:1rem 0px 1rem 0px" id="cardHeader">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col no-gutters">
                            <div class="login-center">
                                <div style="margin-right:auto;visibility:hidden">
                                    <nb-icon icon="close"></nb-icon>
                                </div>
                                <img class="login-header-img" src="../../assets/brand/logo_white.svg">
                                <div style="margin-left:auto;margin-top:-20px">
                                     <a [routerLink]="" (click)="close()" class="button-link"><nb-icon icon="close"></nb-icon></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <div class="login-center"><span class="login-header-text">Sign Up</span></div>
                        </div>
                    </div>
                </div> <!--END: container-fluid -->
            </nb-card-header>

            <nb-card-body style="padding:1rem 0px 1rem 0px" class="maincontent">
                <nb-stepper disableStepNavigation orientation="horizontal" #stepper>
                  <nb-step #step>
                    <form #f="ngForm">
                        <div class="container-fluid">
                            <div class="row mb-3">
                                <div class="col">
                                    <div class="msg-text" style="text-align:center">
                                        Sign up to place bids, subscribe to alerts, and manage your settings.
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-4">
                                <div class="col">
                                    <div class="msg-text" style="text-align:center">Already have an account? <a [routerLink]="" (click)="onSignIn()" class="login-link">Sign In</a></div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="label-text">FIRST NAME</div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col">
                                    <div class="form-control-group">
                                        <input type="text"
                                               nbInput
                                               fullWidth
                                               id="firstname"
                                               name="firstname"
                                               placeholder="Type your first name"
                                               [(ngModel)]="firstname"
                                               [ngClass]="{'inputError':!firstnameentry.valid && firstnameentry.touched}"
                                               #firstnameentry="ngModel"
                                               required>
                                        <div class="errorText" *ngIf="!firstnameentry.valid && firstnameentry.touched">Please provide first name.</div>
                                    </div> <!-- form-control-group -->
                                </div> <!-- col -->
                            </div> <!-- row -->

                            <div class="row">
                                <div class="col">
                                    <div class="label-text">LAST NAME</div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col">
                                    <div class="form-control-group">
                                        <input type="text"
                                               nbInput
                                               fullWidth
                                               id="lastname"
                                               name="lastname"
                                               placeholder="Type your last name"
                                               [(ngModel)]="lastname"
                                               [ngClass]="{'inputError':!lastnameentry.valid && lastnameentry.touched}"
                                               #lastnameentry="ngModel"
                                               required>
                                        <div class="errorText" *ngIf="!lastnameentry.valid && lastnameentry.touched">Please provide last name.</div>
                                    </div> <!-- form-control-group -->
                                </div> <!-- col -->
                            </div> <!-- row -->

                            <div class="row">
                                <div class="col">
                                    <div class="label-text">EMAIL ADDRESS</div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col">
                                    <div class="form-control-group">
                                        <input type="email"
                                               fullWidth
                                               nbInput
                                               id="email"
                                               name="email"
                                               placeholder="Type your email address"
                                               #emailentry="ngModel"
                                               [(ngModel)]="email"
                                               [ngClass]="{'inputError':!validEmail() && emailentry.touched}"
                                               required email>
                                        <div class="errorText" *ngIf="!validEmail() && emailentry.touched">Please provide a valid email address.</div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="label-text">PASSWORD</div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col">
                                    <div class="form-control-group">
                                        <nb-form-field>
                                            <input type="password"
                                                   nbInput
                                                   fullWidth
                                                   id="password"
                                                   name="password"
                                                   data-toggle="password"
                                                   placeholder="Type your password"
                                                   [type]="showPassword ? 'text' : 'password'"
                                                   [(ngModel)]="password"
                                                   [ngClass]="{'inputError':!passwordentry.valid && passwordentry.touched}"
                                                   #passwordentry="ngModel"
                                                   required>
                                            <button type="button" nbSuffix nbButton ghost (click)="onToggleShowPassword()" class="mr-4">
                                               <nb-icon [icon]="showPassword ? 'eye-outline' : 'eye-off-2-outline'"
                                                        pack="eva"
                                                        [attr.aria-label]="showPassword ? 'hide password' : 'show password'">
                                               </nb-icon>
                                            </button>
                                        </nb-form-field>
                                        <div class="errorText" *ngIf="!passwordentry.valid && passwordentry.touched">Please provide a password.</div>
                                    </div> <!-- form-control-group -->
                                </div> <!-- col -->
                            </div> <!-- row -->

                            <div class="row mb-2">
                                <div class="col">
                                    <table>
                                        <tr>
                                            <td style="width:1.5rem">
                                                <nb-icon *ngIf="passwordCharLength()" icon="checkmark-outline" style="vertical-align:bottom;color: #00e096"></nb-icon>
                                            </td>
                                            <td>
                                                <span class="msg-text">8 character minimum</span>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                            <div class="row mb-2">
                                <div class="col">
                                    <table>
                                        <tr>
                                            <td style="width:1.5rem">
                                                <nb-icon *ngIf="passwordUpperCase()" icon="checkmark-outline" style="vertical-align:bottom;color: #00e096"></nb-icon>
                                            </td>
                                            <td>
                                                <span class="msg-text">1 upper case letter</span>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                            <div class="row mb-2">
                                <div class="col">
                                    <table>
                                        <tr>
                                            <td style="width:1.5rem">
                                                <nb-icon *ngIf="passwordLowerCase()" icon="checkmark-outline" style="vertical-align:bottom;color: #00e096"></nb-icon>
                                            </td>
                                            <td>
                                                <span class="msg-text">1 lower case letter</span>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                            <div class="row mb-2">
                                <div class="col">
                                    <table>
                                        <tr>
                                            <td style="width:1.5rem">
                                                <nb-icon *ngIf="passwordNumber()" icon="checkmark-outline" style="vertical-align:bottom;color: #00e096"></nb-icon>
                                            </td>
                                            <td>
                                                <span class="msg-text">1 number</span>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                            <div class="row mb-2">
                                <div class="col">
                                    <table>
                                        <tr>
                                            <td style="width:1.5rem">
                                                <nb-icon *ngIf="passwordSpecialChars()" icon="checkmark-outline" style="vertical-align:bottom;color: #00e096"></nb-icon>
                                            </td>
                                            <td>
                                                <span class="msg-text">1 special character: !@#$%^&*()</span>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="label-text">CONFIRM PASSWORD</div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col">
                                    <div class="form-control-group">
                                        <nb-form-field>
                                            <input type="password"
                                                   nbInput
                                                   fullWidth
                                                   id="confirmpassword"
                                                   name="confirmpassword"
                                                   data-toggle="password"
                                                   placeholder="Type your password"
                                                   [type]="showConfirmPasswordText ? 'text' : 'password'"
                                                   [(ngModel)]="confirmpassword"
                                                   [ngClass]="{'inputError':!confirmpasswordentry.valid && confirmpasswordentry.touched}"
                                                   #confirmpasswordentry="ngModel"
                                                   required>
                                            <button *ngIf="!confirmPasswordCheck()" type="button" nbSuffix nbButton ghost (click)="onToggleShowConfirmPassword()" class="mr-4">
                                               <nb-icon [icon]="showConfirmPasswordText ? 'eye-outline' : 'eye-off-2-outline'" pack="eva"></nb-icon>
                                            </button>
                                            <nb-icon *ngIf="confirmPasswordCheck()" nbSuffix style="color: #00e096" icon="checkmark-outline" pack="eva" class="mr-4"></nb-icon>
                                        </nb-form-field>
                                        <div class="errorText" *ngIf="!confirmpasswordentry.valid && confirmpasswordentry.touched">Please confirm your new password.</div>
                                    </div> <!-- form-control-group -->
                                </div> <!-- col -->
                            </div> <!-- row -->

                            <div class="row">
                                <div class="col login-right">
                                    <button nbButton (click)="onSignupOneNext()" status="primary" [disabled]="!f.valid || !validPassword() || !confirmPasswordCheck() || !validEmail()">NEXT</button>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div *ngIf="errorMsg.length > 0"><span class="errorText">{{errorMsg}}</span></div>
                                </div>
                            </div>

                        </div> <!-- container-fluid  -->
                    </form>
                  </nb-step>
                  <nb-step #step>
                    <form #f="ngForm">
                        <div class="container-fluid">
                            <div class="row mb-3">
                                <div class="col">
                                    <div class="msg-text" style="text-align:center">Sign up to get recommendations, place bids, follow sales, subscribe to alerts and manage your settings.</div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <span class="label-text">PHONE NUMBER</span>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col">
                                    <div class="form-control-group">
                                        <input mask="(000) 000-0000"
                                               [dropSpecialCharacters]="false"
                                               type="text"
                                               nbInput
                                               fullWidth
                                               id="phone"
                                               name="phone"
                                               placeholder="Type your phone number"
                                               [ngClass]="{'inputError':!validPhone() && phoneentry.touched}"
                                               #phoneentry="ngModel"
                                               maxlength="14"
                                               minlength="14"
                                               required
                                               [(ngModel)]="phone">
                                    </div> <!-- form-control-group -->
                                    <div class="errorText" *ngIf="!validPhone() && phoneentry.touched">Please enter a valid phone number.</div>
                                </div> <!-- col -->
                            </div> <!-- row -->

                            <div class="row">
                                <div class="col">
                                    <div class="label-text">BUSINESS NAME (optional)</div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col">
                                    <div class="form-control-group">
                                        <input type="text"
                                               nbInput
                                               fullWidth
                                               id="businessname"
                                               name="businessname"
                                               placeholder="Type your business name"
                                               [(ngModel)]="businessname">
                                    </div> <!-- form-control-group -->
                                </div> <!-- col -->
                            </div> <!-- row -->

                            <div class="row">
                                <div class="col">
                                    <div class="label-text">ADDRESS LINE 1</div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col">
                                    <div class="form-control-group">
                                        <input type="text"
                                               nbInput
                                               fullWidth
                                               id="addressline1"
                                               name="addressline1"
                                               placeholder="Type your address"
                                               [(ngModel)]="addressline1"
                                               #address1="ngModel"
                                               required>
                                    </div> <!-- form-control-group -->
                                    <div class="errorText" *ngIf="!address1.valid && address1.touched">Please enter an address.</div>
                                </div> <!-- col -->
                            </div> <!-- row -->

                            <div class="row">
                                <div class="col">
                                    <div class="label-text">ADDRESS LINE 2 (optional)</div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col">
                                    <div class="form-control-group">
                                        <input type="text"
                                               nbInput
                                               fullWidth
                                               id="addressline2"
                                               name="addressline2"
                                               placeholder="Type your address"
                                               [(ngModel)]="addressline2">
                                    </div> <!-- form-control-group -->
                                </div> <!-- col -->
                            </div> <!-- row -->

                            <div class="row">
                                <div class="col">
                                    <div class="label-text">CITY</div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col">
                                    <div class="form-control-group">
                                        <input type="text"
                                               nbInput
                                               fullWidth
                                               id="city"
                                               name="city"
                                               placeholder="Type your city"
                                               [(ngModel)]="city"
                                               required>
                                    </div> <!-- form-control-group -->
                                </div> <!-- col -->
                            </div> <!-- row -->

                            <div class="row">
                                <div class="col">
                                    <div class="label-text">STATE</div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col">
                                    <div class="form-control-group">
                                        <nb-select  style="width:100%"
                                                    placeholder="Select State"
                                                    name="state"
                                                    id="state"
                                                    [(ngModel)]="state"
                                                    required>
                                            <nb-option *ngFor="let item of states" [value]="item">{{ item }}</nb-option>
                                        </nb-select>

                                        <!--<input #stateautoinput-->
                                        <!--       type="text"-->
                                        <!--       nbInput-->
                                        <!--       fullWidth-->
                                        <!--       id="state"-->
                                        <!--       name="state"-->
                                        <!--       (input)="onChange()"-->
                                        <!--       [nbAutocomplete]="auto"-->
                                        <!--       placeholder="Type your state"-->
                                        <!--       [ngClass]="{'inputError':!validState()}"-->
                                        <!--       [(ngModel)]="state">-->
                                        <!--<div class="errorText" *ngIf="!validState()">Please select a state.</div>-->
                                        <!--<nb-autocomplete #auto (selectedChange)="onStateSelectedChange($event)">-->
                                        <!--    <nb-option *ngFor="let item of filteredOptions$ | async" [value]="item">{{ item }}</nb-option>-->
                                        <!--</nb-autocomplete>-->

                                    </div> <!-- form-control-group -->
                                </div> <!-- col -->
                            </div> <!-- row -->

                            <div class="row">
                                <div class="col">
                                    <div class="label-text">ZIP CODE</div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col">
                                    <div class="form-control-group">
                                        <input type="text"
                                               nbInput
                                               fullWidth
                                               id="zip"
                                               name="zip"
                                               #zipentry="ngModel"
                                               placeholder="Type your zip code"
                                               maxlength="5"
                                               minlength="5"
                                               [ngClass]="{'inputError':!validZip() && zipentry.touched}"
                                               [(ngModel)]="zip"
                                               required>
                                        <div class="errorText" *ngIf="!validZip() && zipentry.touched">Please enter a 5-digit zip code.</div>
                                    </div> <!-- form-control-group -->
                                </div> <!-- col -->
                            </div> <!-- row -->

                            <div class="row">
                                <div class="col login-right">
                                    <button nbButton (click)="onPrevious()" outline appearance="ghost" status="primary">PREVIOUS</button>
                                    <button class="ml-2" nbButton (click)="onNext()" status="primary" [disabled]="!validBusinessInfo()">NEXT</button>
                                </div>
                            </div>
                        </div>
                    </form>
                  </nb-step>
                  <nb-step #step>
                    <form #f="ngForm">
                        <div class="container-fluid">
                            <div class="row mb-3">
                                <div class="col">
                                    <div class="msg-text" style="text-align:center">Sign up to get recommendations, place bids, follow sales, subscribe to alerts and manage your settings.</div>
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col">
                                    <nb-checkbox id="termsofservice"
                                                 name="termsofservice"
                                                 class="checkbox-align"
                                                 [(ngModel)]="termsofservice">I accept the <a class="link-text" routerLink="/buyers" fragment="terms-and-conditions" target="_blank">Terms of Service</a>.
                                    </nb-checkbox>
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col">
                                    <nb-checkbox id="receiveinformation"
                                                 name="receiveinformation"
                                                 class="checkbox-align"
                                                 [(ngModel)]="receiveinformation">I would like to receive information about upcoming sales and industry news from Angus Live G2.
                                    </nb-checkbox>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col login-right">
                                    <button nbButton (click)="onPrevious()" outline appearance="ghost" status="primary">PREVIOUS</button>
                                    <button class="ml-2" nbButton [nbSpinner]="processing" nbSpinnerStatus="control" (click)="onSignup()" status="primary"  [disabled]="!termsofservice">NEXT</button>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div *ngIf="errorMsg.length > 0"><span class="errorText">{{errorMsg}}</span></div>
                                </div>
                            </div>

                        </div>
                    </form>
                  </nb-step>

                  <nb-step #step>
                    <form #f="ngForm">
                        <div class="container-fluid">
                            <div class="row mb-3">
                                <div class="col">
                                    <div class="msg-text" style="text-align:center">
                                      <p><strong>Your account has been successfully created!</strong></p>
                                      To get started, get the verification code from the email we just sent you at {{ email }}, and enter it here to verify the email address and complete the sign up.
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col">
                                    <div class="form-control-group">
                                        <input type="text"
                                               nbInput
                                               fullWidth
                                               id="verificationcode"
                                               name="verificationcode"
                                               placeholder="Type your verification code"
                                               [(ngModel)]="verificationcode">
                                    </div> <!-- form-control-group -->
                                </div> <!-- col -->
                            </div> <!-- row -->

                            <div class="row">
                                <div class="col login-right">
                                    <!--<button nbButton (click)="onPrevious()" outline appearance="ghost" status="primary">PREVIOUS</button>                                -->
                                    <button class="ml-2" nbButton [nbSpinner]="processing" nbSpinnerStatus="control" (click)="onConfirm()" status="primary" [disabled]="verificationcode.length == 0">NEXT</button>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div *ngIf="errorMsg.length > 0"><span class="errorText">{{errorMsg}}</span></div>
                                </div>
                            </div>

                        </div>
                    </form>
                  </nb-step>

                  <nb-step #step>
                    <form #f="ngForm">
                        <div class="container-fluid">
                            <div class="row mb-3">
                                <div class="col">
                                    <span class="login-center"><nb-icon icon="done-all-outline" style="color:#00e096"></nb-icon></span>
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col">
                                    <div class="msg-text" style="text-align:center">You have successfully completed the sign up process. Please use the email and password you provided when you sign in again.</div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col login-center">
                                    <button nbButton (click)="onDone()" status="primary">DONE</button>
                                </div>
                            </div>

                        </div>
                    </form>
                  </nb-step>

                </nb-stepper>
            </nb-card-body>

            <nb-card-footer style="padding:1rem 0px 1rem 0px">
                <div class="row mb-4">
                    <div class="col"></div>
                </div>
            </nb-card-footer>

            <nb-card-footer style="padding:1rem 0px 1rem 0px" *ngIf="stepper.selectedIndex==0 && false">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col login-center">
                            <button style="width:100%" type="button" (click)="onFacebookSignin()" class="facebook-button">
                                <span>LOGIN WITH FACEBOOK</span>
                                <img src="../../../assets/images/facebook.svg">
                            </button>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col login-center">
                            <button style="width:100%" type="button" (click)="onGoogleSignin()" class="google-button">
                                <span>LOGIN WITH GOOGLE</span>
                                <img src="../../../assets/images/google.svg">
                            </button>

                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col login-center">
                            <button style="width:100%" type="button" (click)="onAppleSignin()" class="apple-button">
                                <span>LOGIN WITH APPLE</span>
                                <img src="../../../assets/images/apple.png">
                            </button>

                        </div>
                    </div>
                </div> <!-- container-fluid -->
            </nb-card-footer>
    </nb-card>
</div>
