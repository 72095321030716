import { Injectable } from '@angular/core';

import { PortalUser } from '../model/portaluser.model';

import Auth from '@aws-amplify/auth';
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { CognitoUserPool, CognitoUser } from '@aws-amplify/auth/dist/aws-amplify-auth.js';

import { environment } from '../../environments/environment'

@Injectable()
export class AuthService {
  idToken: string;
  accessToken: string;
  refreshToken: string;
  username: string;
  activeUser: PortalUser = undefined;
  region: string = "";
  graphqlregion: string = "";
  userPoolId: string = "";
  userPoolClientId: string = "";
  identityPoolId: string = "";
  authenticator: string = "";

  accessKeyId: string;
  secretAccessKey: string;
  sessionToken: string;

  amplifySignedIn: boolean = false;
  cognitoUser = undefined;
  awsconfig = environment.awsconfig;

  constructor(private portalUserService: PortalUser) {
      this.region = this.awsconfig['aws_cognito_region'];
      this.graphqlregion = this.awsconfig['aws_appsync_region'];
      this.userPoolId = this.awsconfig['aws_user_pools_id'];
      this.userPoolClientId = this.awsconfig['aws_user_pools_web_client_id'];
      this.identityPoolId = this.awsconfig['aws_cognito_identity_pool_id'];
      this.authenticator = `cognito-idp.${this.region}.amazonaws.com/${this.userPoolId}`;

      this.accessToken = undefined;
      this.idToken = undefined;
      this.refreshToken = undefined;
      this.username = undefined;
  }

  async signupUser(email: string, password: string, firstname: string, lastname: string) {
    try {
        email = email.toLowerCase();
        const {user } = await Auth.signUp({
          username: email,
          password: password,
          attributes: {email: email, given_name: firstname, family_name: lastname }
        });
    }
    catch (e) {
       console.log('signup failure',e);
       throw(e);
    }
  }

  async resendSignup(username: string) {
    try {
        await Auth.resendSignUp(username.toLowerCase());
    }
    catch (e) {
       console.log('resend signup failure',e);
       throw(e);
    }
  }

  async confirmUser(email: string, verificationCode: string){
    try {
        await Auth.confirmSignUp(email.toLowerCase(), verificationCode);
    }
    catch (e) {
       console.log('confirmUser failure',e);
       throw(e);
    }
  }

  async signinUser(username: string, password: string) {
    try {
        this.cognitoUser = await Auth.signIn(username,password);
        this.username = username.toLowerCase();
        if(this.cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED'){
            return this.cognitoUser;
        }
        await this.getCurrentSession();
        let localUserName = this.cognitoUser['attributes']['email'];
        this.activeUser = await this.portalUserService.me();
        return this.cognitoUser;
    }
    catch (e) {
      console.log('login failure',e);
      throw(e);
    }
  }

  async completeNewPassword(username: string, password: string, newpassword: string){
      try {
        const cognitoUser = await Auth.signIn(username,password);
        this.username = username.toLowerCase();

        if(this.cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED'){
          const optionalParms = {
            'email': this.username
          }
          this.cognitoUser = await Auth.completeNewPassword(cognitoUser,newpassword,optionalParms);
        }
        await this.getCurrentSession();
        return this.cognitoUser;
    }
    catch (e) {
      console.log('complete new password failure',e);
      throw(e);
    }

  }

  async loginFacebook(){
    console.log('loginFacebook');
    let localThis = this;
    Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Facebook})
        .then(function (credentials){
          console.log('facebook credentials',credentials);
          return true;
      })
        .then(function (user){
          console.log('facebook user',user);
      })
        .catch(function (err){
          console.log('facebook federatedSignIn error',err);
      });

  }

  async loginGoogle(){
    console.log('loginGoogle');
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google
    }).then(credentials => {
      console.log('google credentials',credentials);
      return true;
    })
    .then(function (user){
      console.log('google user',user);
      return true;
    })
    .catch(function (err){
      console.log('Google federatedSignIn error',err);
    });

  }

  async loginAmazon(){
    console.log('loginAmazon');
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Amazon
    }).then(credentials => {
      console.log('amazon credentials',credentials);
      return true;
    })
    .then(function (user){
      console.log('amazon user',user);
      return true;
    })
    .catch(function (err){
      console.log('amazon federatedSignIn error',err);
    });

  }

  async loginApple(){
    console.log('loginApple');
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Apple
    }).then(credentials => {
      console.log('apple credentials',credentials);
      return true;
    })
    .then(function (user){
      console.log('apple user',user);
      return true;
    })
    .catch(function (err){
      console.log('apple federatedSignIn error',err);
    });

  }

  async forgotPassword(username: string) {
    try {
        await Auth.forgotPassword(username.toLowerCase());
    }
    catch (e) {
       console.log('forgotPassword failure',e);
       throw(e);
    }
  }

  async confirmPasswordChangeAfterForgotPassword(verificationCode: string, password: string, username: string) {
    try {
        await Auth.forgotPasswordSubmit(username.toLowerCase(),verificationCode,password);
    }
    catch (e) {
       console.log('forgotPasswordSubmit failure',e);
       throw(e);
    }
  }


  logout() {
    let localThis = this;
    Auth.signOut()
        .then(function (data){
          localThis.amplifySignedIn = false;
          localThis.accessToken = undefined;
          localThis.idToken = undefined;
          localThis.refreshToken = undefined;
          localThis.username = undefined;
          localThis.accessKeyId = undefined;
          localThis.secretAccessKey = undefined;
          localThis.sessionToken = undefined;
          localThis.activeUser = undefined;
        })
        .catch(function (err){
          console.log('logout error',err);
        });
  }

  getToken() {
    return this.idToken;
  }

  async changePassword(oldPassword: string, newPassword: string){
      return new Promise((resolve, reject) => {
        Auth.currentAuthenticatedUser()
          .then(function (data){
            let cognitoUser = data;
            cognitoUser.changePassword(oldPassword,newPassword, function(err, result) {
                if (err) {
                    reject(err.message || JSON.stringify(err));
                    return;
                }
                else {
                    resolve(result);
                    return;
                }
            });
          })
          .catch(function (err){
              console.log('exception calling cognitoUser.changePassword',err);
              reject(err);
              return;
          });
      });
  }

  isAuthenticated() {
    return this.idToken != undefined;
  }

  getUsername(){
      return this.username != null ? this.username.toLowerCase() : "";
  }

  async getAuthenticatedUser() {
    try {
        let user = await Auth.currentAuthenticatedUser({bypassCache: false});
        await this.setAmplifySignIn();
        this.username = user['attributes']['email'];
        this.activeUser = await this.portalUserService.me();
        return user;
    }
    catch (e) {
       return undefined;
    }

  }

  setActivePortalUser(portalUser:PortalUser){
      this.activeUser = portalUser;
  }

  async setUserAttributes(user:CognitoUser){

    let localThis = this;
    return new Promise((resolve, reject) => {
            let myAttributes = {};
            user.getUserAttributes(function(err, result) {
              if (err) {
                  console.log('error on getUserAttributes',err);
                  reject(err);
              }
              else{
                result.forEach(attr => (myAttributes[attr.Name] = attr.Value));
                var firstName = myAttributes['given_name'] != undefined ? myAttributes['given_name'] : "";
                var lastName = myAttributes['family_name'] != undefined ? myAttributes['family_name'] : "";
                var email = myAttributes['email'] != undefined ? myAttributes['email'].toLowerCase() : "";
                // localThis.setActivePortalUser(new PortalUser(0,firstName,lastName,"",email,"","","","","","","","","",true,true,true,true,true,true,true,true));
                resolve(localThis.activeUser);
              }

        });
    });

  }

  setAmplifySignIn(){
    this.amplifySignedIn = true;
    let localThis = this;

    return new Promise((resolve, reject) => {
        Auth.currentAuthenticatedUser({bypassCache: false})
          .then(function (user){
              localThis.idToken = user.getSignInUserSession().getIdToken().getJwtToken();
              localThis.accessToken = user.getSignInUserSession().getAccessToken().getJwtToken();
              localThis.refreshToken = user.getSignInUserSession().getRefreshToken().getToken();
              localThis.username = user.getUsername();
              resolve(user);
          })
          .catch(function (err){
              console.log('error calling currentAuthenticatedUser',err);
              reject(err);
              return;
          });
    });

  }

  // async getAwsCredentials(userToken) {

  //     AWS.config.update({ region: this.region });

  //     AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  //       IdentityPoolId: this.identityPoolId,
  //       Logins: {
  //         [this.authenticator]: userToken
  //       }
  //     });

  //   return (<AWS.CognitoIdentityCredentials> AWS.config.credentials).getPromise();
  // }

  async getCurrentSession(){
      var localThis = this;
      return new Promise((resolve, reject) => {
        Auth.currentSession()
          .then(function (data){
              localThis.idToken = data['idToken']['jwtToken'];
              localThis.accessToken = data['accessToken']['jwtToken'];
              localThis.refreshToken = data['refreshToken']['token'];
              resolve(data);
          })
          .catch(function (err){
              console.log('exception calling Auth.currentSession',err);
              reject(err);
              return;
          });
      });

  }

  async getCurrentCredentials(){
      var localThis = this;
      return new Promise((resolve, reject) => {
        Auth.currentCredentials()
          .then(function (data){
            localThis.accessKeyId = data["accessKeyId"];
            localThis.secretAccessKey = data['secretAccessKey'];
            localThis.sessionToken = data["sessionToken"];
            resolve(data);
          })
          .catch(err => reject(err));
      });
  }

}
