import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BidModel, LotModel } from '../../model/auction.model';
import { AuctionStatus} from '../../API.service';

@Component({
  selector: 'app-auction-bidder-list',
  templateUrl: './auction-bidder-list.component.html',
  styleUrls: ['./auction-bidder-list.component.scss']
})
export class AuctionBidderListComponent implements OnInit {

  @Input() rows:BidModel[];
  @Input() timezone:string;
  @Input() lot:LotModel;
  
  constructor() { }

  ngOnInit(): void {
    // console.log('bidder list',this.rows);
    this.rows = this.rows != undefined ? this.rows : [];
    this.rows.sort(this.compareDescPrice);
  }
  
  compareDescPrice(a:BidModel,b:BidModel){
    let valueA = a.price;
    let valueB = b.price;
    return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
  }
  
  isCurrentBid(bid:BidModel){
    return bid.price == this.lot.currentBid;
  }

}
