<div class="container-fluid">

    <ng-container *ngIf="hasProductionValues()">
        <div class="row mb-1">
            <div class="col title-text" style="font-size:12px">
                Production EPDs
            </div>
        </div>

        <div class="row mb-2" n>
            <div class="col">
                <ng-container *ngFor="let item of EPDS_PRODUCTION;let i=index">
                    <div class="mr-2" style="display:inline-block" *ngIf="!isEmpty(lot.epdMeasures[i+EPDS_PRODUCTION_START]['value'])">
                        <div class="epdrectangle">
                            <span class="epdtext">{{ item.measure }}</span>
                        </div>
                        <div class="epddatarectangle">
                            <span class="epddatatext" *ngIf="lot.epdMeasures[i+EPDS_PRODUCTION_START]['value'].length > 0">{{ lot.epdMeasures[i+EPDS_PRODUCTION_START]['value'] }}</span>
                            <span class="epddatatext" *ngIf="lot.epdMeasures[i+EPDS_PRODUCTION_START]['value'].length == 0">&nbsp;</span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="hasMaternalValues()">
        <div class="row mb-1">
            <div class="col title-text" style="font-size:12px">
                Maternal EPDs
            </div>
        </div>

        <div class="row mb-2">
            <div class="col">
                <ng-container *ngFor="let item of EPDS_MATERNAL;let i=index">
                    <div class="mr-2" style="display:inline-block" *ngIf="!isEmpty(lot.epdMeasures[i+EPDS_MATERNAL_START]['value'])">
                        <div class="epdrectangle">
                            <span class="epdtext">{{ item.measure }}</span>
                        </div>
                        <div class="epddatarectangle">
                            <span class="epddatatext" *ngIf="lot.epdMeasures[i+EPDS_MATERNAL_START]['value'].length > 0">{{ lot.epdMeasures[i+EPDS_MATERNAL_START]['value'] }}</span>
                            <span class="epddatatext" *ngIf="lot.epdMeasures[i+EPDS_MATERNAL_START]['value'].length == 0">&nbsp;</span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="hasManagementValues()">
        <div class="row mb-1">
            <div class="col title-text" style="font-size:12px">
                Management EPDs
            </div>
        </div>

        <div class="row mb-2">
            <div class="col">
                <ng-container *ngFor="let item of EPDS_MANAGEMENT;let i=index">
                    <div class="mr-2" style="display:inline-block" *ngIf="!isEmpty(lot.epdMeasures[i+EPDS_MANAGEMENT_START]['value'])">
                        <div class="epdrectangle">
                            <span class="epdtext">{{ item.measure }}</span>
                        </div>
                        <div class="epddatarectangle">
                            <span class="epddatatext" *ngIf="lot.epdMeasures[i+EPDS_MANAGEMENT_START]['value'].length > 0">{{ lot.epdMeasures[i+EPDS_MANAGEMENT_START]['value'] }}</span>
                            <span class="epddatatext" *ngIf="lot.epdMeasures[i+EPDS_MANAGEMENT_START]['value'].length == 0">&nbsp;</span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="hasCarcassValues()">
        <div class="row mb-1">
            <div class="col title-text" style="font-size:12px">
                Carcass EPDs
            </div>
        </div>

        <div class="row mb-2">
            <div class="col">
                <ng-container *ngFor="let item of EPDS_CARCASS;let i=index">
                    <div class="mr-2" style="display:inline-block" *ngIf="!isEmpty(lot.epdMeasures[i+EPDS_CARCASS_START]['value'])">
                        <div class="epdrectangle">
                            <span class="epdtext">{{ item.measure }}</span>
                        </div>
                        <div class="epddatarectangle">
                            <span class="epddatatext" *ngIf="lot.epdMeasures[i+EPDS_CARCASS_START]['value'].length > 0">{{ lot.epdMeasures[i+EPDS_CARCASS_START]['value'] }}</span>
                            <span class="epddatatext" *ngIf="lot.epdMeasures[i+EPDS_CARCASS_START]['value'].length == 0">&nbsp;</span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="has$Values()">
        <div class="row mb-1">
            <div class="col title-text" style="font-size:12px">
                $Values Index
            </div>
        </div>

        <div class="row mb-2">
            <div class="col">
                <ng-container *ngFor="let item of EPDS_$VALUES;let i=index">
                    <div class="mr-2" style="display:inline-block" *ngIf="!isEmpty(lot.epdMeasures[i+EPDS_$VALUES_START]['value'])">
                        <div class="epdrectangle">
                            <span class="epdtext">{{ item.measure }}</span>
                        </div>
                        <div class="epddatarectangle">
                            <span class="epddatatext" *ngIf="lot.epdMeasures[i+EPDS_$VALUES_START]['value'].length > 0">{{ lot.epdMeasures[i+EPDS_$VALUES_START]['value'] }}</span>
                            <span class="epddatatext" *ngIf="lot.epdMeasures[i+EPDS_$VALUES_START]['value'].length == 0">&nbsp;</span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>

</div> <!-- container-fluid -->
