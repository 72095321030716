/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onTaskProgressUpdated = /* GraphQL */ `
  subscription OnTaskProgressUpdated($id: ID!) {
    onTaskProgressUpdated(id: $id) {
      id
      status
      payload
    }
  }
`;
export const onAuctionChanged = /* GraphQL */ `
  subscription OnAuctionChanged($auctionId: ID!) {
    onAuctionChanged(auctionId: $auctionId) {
      auctionId
      eventType
      auctionData {
        closingExtensionInterval
        closingExtensionTriggered
        status
        startTime
        endTime
        bidIncrement
        floorPrice
      }
      lotData {
        auctionId
        lotId
        startBid
        currentBid
        nextBid
        bidCount
        bidIncrement
        bidderCount
        bidderId
        bidderAccountId
        floorPrice
        biddingDisabled
      }
    }
  }
`;
