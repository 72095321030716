import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-seller-home',
  templateUrl: './seller-home.component.html',
  styleUrls: ['./seller-home.component.scss']
})
export class SellerHomeComponent implements OnInit {
  
  livestockPhotographyAds = [];

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.loadLivestockPhotographyAds();
    this.route.fragment.subscribe(f => {
      setTimeout(() => {
        const element = document.querySelector("#" + f);
        if (element) {
          const offset = -100;
          const y = element.getBoundingClientRect().top + window.pageYOffset + offset;
          window.scrollTo({ top: y, behavior: "smooth" });
        }
      }, 250)
    })
  }
  
  loadLivestockPhotographyAds(){
    this.livestockPhotographyAds = [];
    var adCard = {
      "logourl": "./assets/images/photographers_Swigart.png",
      "company": "SWIGART CATTLE PHOTOGRAPHY",
      "name": "Adam Swigart",
      "addressline1": "1927 N 3450 East Road",
      "addressline2": "Farmers City, Illinois 61842",
      "office": "(309) 826-3809",
      "email": "swigartcattle@gmail.com",
      "emailsubject": "Livestock Photography",
      "website":"www.swigartcattle.com"
    }
    this.livestockPhotographyAds.push(adCard);

    var adCard1 = {
      "logourl": "./assets/images/photographers_Focus.png",
      "company": "FOCUS MARKETING GROUP INC.",
      "name": "Kyla Copeland",
      "addressline1": "7301 Mission Rd, Ste 107",
      "addressline2": "Village, KS 66208",
      "cell": "(785) 213-5435",
      "website":"www.swigartcattle.com"
    }
    this.livestockPhotographyAds.push(adCard1);
    
  }
  

}
