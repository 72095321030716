import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Invoice, InvoiceModelService } from '../model/invoice.model';

const DATE_FORMAT = 'MM/DD/YYYY h:mm A';

@Component({
  selector: 'app-invoice',
  styleUrls: ['./invoice.component.scss'],
  templateUrl: './invoice.component.html',
})
export class InvoiceComponent implements OnInit {
  public invoiceId: string;
  public invoice: Invoice = {} as Invoice;
  public loading: boolean = false;
  public invoiceDateFormatted: string = '';
  public saleAuctionClosingDateFormatted: string = '';
  public datePaidFormatted: string = '';
  public paymentClientSecret: string;

  constructor(private route: ActivatedRoute, private invoiceModel: InvoiceModelService) {
    this.invoiceId = this.route.snapshot.params.id;
  }

  public async ngOnInit(): Promise<any> {
    this.loading = true;
    try {
      this.invoice = await this.invoiceModel.loadInvoice(this.invoiceId);
      if (this.invoice) {
        this.invoiceDateFormatted = this.formatDate(this.invoice.invoiceDate);
        this.saleAuctionClosingDateFormatted = this.formatDate(this.invoice.saleAuctionClosingDate);
        this.datePaidFormatted = this.formatDate(this.invoice.datePaid);
        this.paymentClientSecret = this.invoice.paid ? null : await this.invoiceModel.getPaymentClientSecret(this.invoice.invoiceId);
      }
      this.loading = false;
    } catch (e) {
      // TODO: Route to 404 page.
    }
  }

  public printInvoice(): void {
    window.print();
  }

  private formatDate(dateString: string): string {
    return dateString ? moment(dateString).format(DATE_FORMAT) : '';
  }

  public async receiveMessage($event) {
    if ($event.message === 'paymentSuccessful') {
      this.invoice.paid = true;
      this.invoice.totalAmountPaid = $event.data.amount / 100.0;
      const datePaid = moment.unix($event.data.created);
      if (datePaid.isValid()) {
        this.datePaidFormatted = datePaid.format(DATE_FORMAT)
      }
      this.invoice.paymentMethod = ($event.data.payment_method_types || []).includes('card') ? 'CREDIT CARD' : 'OTHER';
    }
  }

}
