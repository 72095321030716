<div class="container-horiz-padded container-vert-padded text-center">
  <div class="page-header py-4">
    <span class="align-middle">4</span>
    <img src="assets/brand/logo_white.svg" class="logo">
    <span class="align-middle">4</span>
  </div>
  <div class="main-content">
    <h6>Sorry! This page doesn’t exist or some other error occurred.</h6>
    <h6>We can help you get back to our <a href="/">home page.</a></h6>
  </div>
</div>
