import { Component, Input, OnInit } from '@angular/core';
import { AuctionResultLineItem } from 'src/app/model/auction.model';

@Component({
  selector: '[app-buyer-auction-win-list-detail]',
  templateUrl: './buyer-auction-win-list-detail.component.html',
  styleUrls: ['./buyer-auction-win-list-detail.component.scss']
})
export class BuyerAuctionWinListDetailComponent implements OnInit {

  @Input() lot: AuctionResultLineItem = {} as AuctionResultLineItem;

  constructor() { }

  ngOnInit(): void {
  }

  public formattedAdjustment() {
    const adjustmentValue = this.lot.finalPrice - (this.lot.quantity * this.lot.finalBid);
    if (adjustmentValue < 0) {
      return `($${adjustmentValue})`;
    } else {
      return `$${adjustmentValue}`;
    }
  }

  public getLotUrl() {
    const lotId = this.lot.lotId.replace("REPORT_LINE_ITEM#", "");
    return `/auctions/${this.lot.auctionId}/${lotId}`;
  }
}
