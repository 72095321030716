<div class="container-fluid" *ngIf="isAuction()">

    <div class="row no-gutters table-header-border">

	    <div class="col-5">
          <a [routerLink]="" class="table-header-text-link" (click)="onSort(2)"> AUCTION NAME
              <span class="sort-header-symbol" *ngIf="isSortAsc(2)">&nbsp;&#9650;</span>
              <span class="sort-header-symbol" *ngIf="isSortDesc(2)">&nbsp;&#9660;</span>
          </a>
	    </div>

	    <div class="col-2">
          <a [routerLink]="" class="table-header-text-link" (click)="onSort(3)"> START DATE
              <span class="sort-header-symbol" *ngIf="isSortAsc(3)">&nbsp;&#9650;</span>
              <span class="sort-header-symbol" *ngIf="isSortDesc(3)">&nbsp;&#9660;</span>
          </a>
	    </div>

	    <div class="col-2 flex-center">
          <a [routerLink]="" class="table-header-text-link" (click)="onSort(4)"> STATUS
              <span class="sort-header-symbol" *ngIf="isSortAsc(4)">&nbsp;&#9650;</span>
              <span class="sort-header-symbol" *ngIf="isSortDesc(4)">&nbsp;&#9660;</span>
          </a>
	    </div>

	    <div class="col-2">
	      <div><span class="table-header-text">  </span></div>
	    </div>

    </div> <!-- header -->

    <div class="row no-gutters">
    	<div class="col-12">
		  <div *ngFor="let item of filteredList | paginate: { id: 'playermatch_pagination',
	                                                  itemsPerPage: 10,
	                                                  currentPage: p,
	                                                  totalItems: filteredList.length }">
		     <app-auctiondetail [auction]="item" (messageEvent)="receiveMessage($event)" type="auction"></app-auctiondetail>
		     <div class="d-md-none mb-3"></div>
		   </div>
		</div>
	</div>

    <div class="row mt-5 mb-5 no-gutters">
        <div class="col-12">
          <pagination-controls id="playermatch_pagination" maxSize="10" class="list-custom-pagination" (pageChange)="p = $event" autoHide="true" previousLabel="" nextLabel=""></pagination-controls>
        </div>
        <div class="col"></div>
    </div>


</div> <!-- container-fluid -->

<div class="container-fluid" *ngIf="isSeller()">

    <div class="row no-gutters table-header-border">
	    <div class="col-4">
          <a [routerLink]="" class="table-header-text-link" (click)="onSort(2)"> AUCTION NAME
              <span *ngIf="isSortAsc(2)">&nbsp;&#9650;</span>
              <span *ngIf="isSortDesc(2)">&nbsp;&#9660;</span>
          </a>
	    </div>

	    <div class="col-2">
          <a [routerLink]="" class="table-header-text-link" (click)="onSort(3)"> START DATE
              <span *ngIf="isSortAsc(3)">&nbsp;&#9650;</span>
              <span *ngIf="isSortDesc(3)">&nbsp;&#9660;</span>
          </a>
	    </div>

	    <div class="col-2 flex-center">
          <a [routerLink]="" class="table-header-text-link" (click)="onSort(4)"> STATUS
              <span *ngIf="isSortAsc(4)">&nbsp;&#9650;</span>
              <span *ngIf="isSortDesc(4)">&nbsp;&#9660;</span>
          </a>
	    </div>

	    <div class="col-2">
	      <div><span class="table-header-text">  </span></div>
	    </div>

	    <div class="col-2">
	      <div><span class="table-header-text">  </span></div>
	    </div>

    </div> <!-- header -->

    <div class="row no-gutters">
    	<div class="col-12">
		  <div *ngFor="let item of filteredList | paginate: { id: 'playermatch_pagination',
	                                                  itemsPerPage: 10,
	                                                  currentPage: p,
	                                                  totalItems: filteredList.length }">
		     <app-auctiondetail [auction]="item" (messageEvent)="receiveMessage($event)" type="seller"></app-auctiondetail>
		     <div class="d-md-none mb-3"></div>
		   </div>
		</div>
	</div>

    <div class="row mt-5 mb-5 no-gutters">
        <div class="col-12">
          <pagination-controls id="playermatch_pagination" maxSize="10" class="list-custom-pagination" (pageChange)="p = $event" autoHide="true" previousLabel="" nextLabel=""></pagination-controls>
        </div>
        <div class="col"></div>
    </div>


</div> <!-- container-fluid -->
