<div class="container-fluid">

    <div class="row no-gutters">
	    <div class="col-3 mt-2 mb-2">
	      <div><span [ngClass]="getRowStyle()"> {{ getBidPrice() }} </span></div>
	    </div>

	    <div class="col-7 col-sm-3 mt-2 mb-2">
	      <div><span [ngClass]="getRowStyle()"> {{ bid.bidderFirstName }} {{ bid.bidderLastName }} </span></div>
	    </div>

	    <div class="col-2 mt-2 mb-2 d-none d-sm-block">
	      <div><span [ngClass]="getRowStyle()" *ngIf="bid.isAdmin()">Y</span></div>
	    </div>

	    <div class="col-2 mt-2 mb-2 d-none d-sm-block">
	      <div><span [ngClass]="getRowStyle()"> {{ bid.bidderId }}</span></div>
	    </div>

	    <div class="col-2 mt-2 mb-2">
	      <div><span class="flex-right" [ngClass]="getRowTimeStyle()"> {{ getTime(bid.bidTime) }}</span></div>
	    </div>

    </div> <!-- header -->
</div>
