import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { filter, map, mergeMap } from 'rxjs/operators'
import { EventService } from './services/event.service';
import { VisibilityService, VisibilityState } from './services/visibility.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'angus-g2';


  public headerVisible: boolean = true;
  public footerVisible: boolean = true;
  private timerWorker: Worker;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private visibilityService: VisibilityService,
    private eventService: EventService) {}

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    ).subscribe(data => {
        this.headerVisible = data.header !== false;
        this.footerVisible = data.footer !== false;
      }
    )

    this.visibilityService.message$.subscribe(event => {
      if (event === VisibilityState.PAGE_HIDDEN) {
        this.ngOnDestroy();
      }
      if (event === VisibilityState.PAGE_VISIBLE) {
        this.setupTimer();
      }
    });
    this.setupTimer();

  }

  ngOnDestroy() {
    if (this.timerWorker) this.timerWorker.terminate();
  }

  private setupTimer() {
    if (typeof Worker !== 'undefined') {
      // Create a centralized timer that works in spite of tab refreshes
      this.timerWorker = new Worker("./timer.worker", { type: "module" });
      this.timerWorker.onmessage = ({ data }) => {
        this.eventService.createEvent({ message: "update-timers" });
      };
      this.timerWorker.postMessage({ data: { refreshIntervalMilliseconds: 1000 }});
    } else {
      console.log("Web workers not supported, please upgrade your browser to take advantage of it.");
    }
  }
}
