
<nav class="navbar navbar-expand-sm navbar-light" [ngClass]="{ 'navbar-sticky': stickyHeader }">
  <a class="navbar-brand logo-link d-flex flex-row justify-content-center" routerLink="/">
    <img style="height:40px;width:auto" src="../../assets/brand/logo.svg" alt="Angus Live Logo">
  </a>

  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <span class="ml-auto">
      <ul class="navbar-nav navbar-right">
          <li class="header-nav nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
            <a class="header-nav-link" routerLink="/auctions" routerLinkActive="header-nav-link-active">Auctions</a>
          </li>

          <li class="header-nav ml-sm-2 ml-md-4 nav-item" data-toggle="collapse" data-target=".navbar-collapse.show" *ngIf="isSeller()">
            <a class="header-nav-link" [routerLink]="['/sellers', 'seller-portal']" routerLinkActive="header-nav-link-active">Seller Portal</a>
          </li>

          <li class="header-nav ml-sm-2 ml-md-4 nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
            <a class="header-nav-link" [routerLink]="['/buyers']" routerLinkActive="header-nav-link-active">Buyers</a>
          </li>

          <!--<li class="dropdown header-nav ml-sm-2 ml-md-4 nav-item d-sm-flex d-none" data-toggle="collapse" data-target=".navbar-collapse.show">-->
          <!--  <a  class="header-nav-link" href="#" id="sellerDropdown" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" [class.header-nav-link-active]="router.isActive('/sellers',false)">Sellers</a>-->
          <!--  <div class="dropdown-menu dropdown-link" aria-labelledby="sellerDropdown">-->
          <!--    <a class="dropdown-item dropdown-link" [routerLink]="['/sellers', 'seller-portal']" *ngIf="isSeller()">Seller Portal</a>-->
          <!--    <a class="dropdown-item dropdown-link" [routerLink]="['/sellers']" fragment="seller-info">Marketing and Sales on Angus Live</a>-->
          <!--    <a class="dropdown-item dropdown-link" [routerLink]="['/sellers']" fragment="seller-services">Services</a>-->
          <!--    <a class="dropdown-item dropdown-link" [routerLink]="['/sellers']" fragment="terms-and-conditions">Terms & Conditions</a>-->
          <!--  </div>-->
          <!--</li>-->

          <!--<ng-container class="d-sm-none">-->
          <!--  <li class="header-nav ml-3 d-sm-none nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">-->
          <!--    <a class="header-nav-link" href="#" id="sellerDropdown" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" [class.header-nav-link-active]="router.isActive('/sellers',false)">Sellers</a>-->
          <!--  </li>-->
          <!--  <li class="header-nav ml-3 d-sm-none nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">-->
          <!--    <a class="pl-2 header-nav-link header-nav-sublink" [routerLink]="['/sellers', 'seller-portal']" *ngIf="isSeller()">Seller Portal</a>-->
          <!--  </li>-->
          <!--  <li class="header-nav ml-3 d-sm-none nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">-->
          <!--    <a class="pl-2 header-nav-link header-nav-sublink" [routerLink]="['/sellers']" fragment="seller-info">Marketing and Sales on Angus Live</a>-->
          <!--  </li>-->
          <!--  <li class="header-nav ml-3 d-sm-none nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">-->
          <!--    <a class="pl-2 header-nav-link header-nav-sublink" [routerLink]="['/sellers']" fragment="seller-services">Services</a>-->
          <!--  </li>-->
          <!--  <li class="header-nav ml-3 d-sm-none nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">-->
          <!--    <a class="pl-2 header-nav-link header-nav-sublink" [routerLink]="['/sellers']" fragment="terms-and-conditions">Terms & Conditions</a>-->
          <!--  </li>-->
          <!--</ng-container>-->


          <!--<li class="dropdown header-nav ml-sm-2 ml-md-4 nav-item d-sm-flex d-none" data-toggle="collapse" data-target=".navbar-collapse.show">-->
          <!--  <a class="header-nav-link" href="#" id="buyerDropdown" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" [class.header-nav-link-active]="router.isActive('/buyers',false)">Buyers</a>-->
          <!--  <div class="dropdown-menu dropdown-link" aria-labelledby="buyerDropdown">-->
          <!--    <a class="dropdown-item dropdown-link" [routerLink]="['/buyers', 'bidding-portal']">Bidding Portal</a>-->
          <!--    <a class="dropdown-item dropdown-link" [routerLink]="['/buyers']">Buying on Angus Live</a>-->
          <!--    <a class="dropdown-item dropdown-link" [routerLink]="['/buyers']" fragment="delivery-payment">Delivery & Payment</a>-->
          <!--    <a class="dropdown-item dropdown-link" [routerLink]="['/buyers']" fragment="service-providers">Service Providers</a>-->
          <!--    <a class="dropdown-item dropdown-link" [routerLink]="['/buyers']" fragment="terms-and-conditions">Terms & Conditions</a>-->
          <!--  </div>-->
          <!--</li>-->
          <!--<ng-container class="d-sm-none">-->
          <!--  <li class="header-nav ml-3 d-sm-none nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">-->
          <!--    <a class="header-nav-link" href="#" id="buyerDropdown" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" [class.header-nav-link-active]="router.isActive('/buyers',false)">Buyers</a>-->
          <!--  </li>-->
          <!--  <li class="header-nav ml-3 d-sm-none nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">-->
          <!--    <a class="pl-2 header-nav-link header-nav-sublink" [routerLink]="['/buyers', 'bidding-portal']">Bidding Portal</a>-->
          <!--  </li>-->
          <!--  <li class="header-nav ml-3 d-sm-none nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">-->
          <!--    <a class="pl-2 header-nav-link header-nav-sublink" [routerLink]="['/buyers']">Buying on Angus Live</a>-->
          <!--  </li>-->
          <!--  <li class="header-nav ml-3 d-sm-none nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">-->
          <!--    <a class="pl-2 header-nav-link header-nav-sublink" [routerLink]="['/buyers']" fragment="delivery-payment">Delivery & Payment</a>-->
          <!--  </li>-->
          <!--  <li class="header-nav ml-3 d-sm-none nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">-->
          <!--    <a class="pl-2 header-nav-link header-nav-sublink" [routerLink]="['/buyers']" fragment="service-providers">Service Providers</a>-->
          <!--  </li>-->
          <!--  <li class="header-nav ml-3 d-sm-none nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">-->
          <!--    <a class="pl-2 header-nav-link header-nav-sublink" [routerLink]="['/buyers']" fragment="terms-and-conditions">Terms & Conditions</a>-->
          <!--  </li>-->
          <!--</ng-container>-->

          <!--<li class="header-nav ml-sm-2 ml-md-4 nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">-->
          <!--  <a class="header-nav-link" routerLink="/community" routerLinkActive="header-nav-link-active">Community</a>-->
          <!--</li>-->
          <li class="header-nav nav-item ml-md-5" *ngIf="!authService.isAuthenticated()" data-toggle="collapse" data-target=".navbar-collapse.show">
              <button nbButton shape="round" size="large" status="basic" class="ml-sm-2 ml-md-4 my-2 my-sm-0 sign-in-button" (click)="onSignin()">SIGN IN</button>
          </li>

          <!-- wide -->
          <li class="dropdown d-sm-block d-none" *ngIf="authService.isAuthenticated()">
              <a class="dropdown-toggle header-nav-link ml-5 my-2 my-sm-0" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="header-nav-link">Hi, {{ getFirstName() }}</span>
              </a>
              <div class="dropdown-menu dropdown-link" style="right: 0; left: auto;" aria-labelledby="navbarDropdown">
                <a class="dropdown-item dropdown-link" [routerLink]="['/accountsettings', '1']"><span> Account Settings</span></a>
                <a class="dropdown-item dropdown-link" (click)="onLogout()"><span> Sign Out</span></a>
              </div>
          </li>

          <!-- narrow -->
          <ng-container class="d-sm-none" *ngIf="authService.isAuthenticated()">
            <li class="header-nav ml-3 d-sm-none nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
              <a class="header-nav-link" [routerLink]="['/accountsettings', '1']"> Account Settings</a>
            </li>
            <li class="header-nav ml-3 d-sm-none nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
              <a class="header-nav-link" (click)="onLogout()"> Sign Out</a>
            </li>
          </ng-container>

      </ul>
    </span>
  </div>

</nav>