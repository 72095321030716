import { Component, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { PortalUser } from '../../model/portaluser.model';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-auctions-home',
  templateUrl: './auctions-home.component.html',
  styleUrls: ['./auctions-home.component.scss']
})
export class AuctionsHomeComponent implements OnInit {

  @Output() messageEvent = new EventEmitter<any>();

  constructor( private authService: AuthService,
               private router: Router) { }

  ngOnInit(): void {
    this.isLoggedOn();
  }

  receiveMessage($event) {
    var msg = $event['message'];
  }

  routeToPage(portalUser:PortalUser){
  }

  async isLoggedOn(){
    let user = await this.authService.getAuthenticatedUser();
    if(user!=undefined){
      this.routeToPage(this.authService.activeUser);
    }
  }

}
