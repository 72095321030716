<td [ngClass]="{ 'sold-lot-line-item': saleStatus === 'SOLD' }">
  <div class="column-text">{{ lot.lotNum }}</div>
</td>

<td>
  <div class="d-flex justify-content-between pr-2">
    <span class="column-text">{{ lot.title }}</span>
    <a (click)="adjustWinningBuyerInfo()" *ngIf="saleStatus == 'SOLD' && lot.paymentStatus == 'UNPAID'"><nb-icon status="basic" icon="edit-outline" class="edit-icon"></nb-icon></a>
  </div>
</td>

<td>
  <span class="column-text">${{ lot.finalBid }}</span>
</td>

<td *ngIf="saleStatus !== 'SOLD'">
  <div class="d-flex justify-content-between pr-2">
    <span class="column-text">{{ lot.winningBidderFirstName }} {{ lot.winningBidderLastName }}</span>
    <a (click)="adjustWinningBuyerInfo()" *ngIf="!isAuctionSettled()"><nb-icon status="basic" icon="edit-outline" class="edit-icon"></nb-icon></a>
  </div>
</td>

<td>
  <span class="column-text">{{ lot.quantity }}</span>
</td>

<td>
  <span class="column-text" [ngClass]="{'field-changed': lot.finalPrice != (lot.finalBid || 0) * (lot.quantity || 0)}">${{ lot.finalPrice }}</span>
</td>

<ng-container *ngIf="lot.saleStatus === 'SOLD'">
  <td>
    <button nbButton outline size="small" status="basic" (click)="adjustReport()" [disabled]="isAuctionSettled()">ADJUST</button>
  </td>
</ng-container>

<td>
  <nb-select placeholder="Select Sale Status" [(selected)]="lot.saleStatus" (selectedChange)="onUpdateSaleStatus()">
    <nb-option *ngFor="let item of soldStatusOptions" [value]="item.key" [disabled]="isAuctionSettled()">{{ item.value }}</nb-option>
  </nb-select>
</td>
