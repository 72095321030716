<div class="loading-shade" *ngIf="processing"></div>

<div class="container-fluid">

    <div class="row mt-3 mb-3 container-horiz-padded">
        <div class="col-md-6">
            <app-section-header type="primary">Seller Portal</app-section-header>
        </div>
    </div>

    <div class="row mb-4 container-horiz-padded" style="height:48px">
        <div class="col-md-12">
            <nb-tabset (changeTab)="selectTab($event)">
                <nb-tab tabTitle="SALES"></nb-tab>
            </nb-tabset>
        </div>
    </div>

    <div class="row container-horiz-padded">
        <div class="col-md-12">
              <app-auctionslist #currentlist [hidden]="!(current && openAuctionList.length > 0)" (messageEvent)="receiveMessage($event)" [filterStatus]="lastStatusFilter" type="auction"></app-auctionslist>
            <!--<app-auctionslist #archivelist *ngIf="!current && archiveAuctionList.length > 0" [rows]="archiveAuctionList" (messageEvent)="receiveMessage($event)" [filterStatus]="lastStatusFilter" type="auction"></app-auctionslist>-->
        </div>
    </div>

</div> <!-- container-fluid -->
