import { Component, OnInit, Input } from '@angular/core';
import { LotModel, LineageHierarchyDetail } from '../../model/auction.model';
import * as $ from 'jquery';

@Component({
  selector: 'app-lot-lineage',
  templateUrl: './lot-lineage.component.html',
  styleUrls: ['./lot-lineage.component.scss']
})
export class LotLineageComponent implements OnInit {
  
  @Input() lot:LotModel = undefined;
  
  dam:LineageHierarchyDetail = undefined;
  sire:LineageHierarchyDetail = undefined;
  damParentSire:LineageHierarchyDetail = undefined;
  damParentDam:LineageHierarchyDetail = undefined;
  sireParentSire:LineageHierarchyDetail = undefined;
  sireParentDam:LineageHierarchyDetail = undefined;
  
  damSireLineCoord:{} = undefined;
  grandSireDamSireLineCoord:{} = undefined;
  grandDamDamSireLineCoord:{} = undefined;
  lineageRootPointCoord:{} = undefined;

  constructor() { }

  ngOnInit(): void {
    this.damSireLineCoord = undefined;
    this.grandSireDamSireLineCoord = undefined;
    this.grandDamDamSireLineCoord = undefined;
    
    if(this.lot.lineageHierarchy['dam'] != undefined && this.lot.lineageHierarchy['dam'].length > 0){
      this.dam = {
        "name": this.lot.lineageHierarchy['dam'],
        "regNum": this.lot.lineageHierarchy['damRegNum'],
        "url": this.lot.lineageHierarchy['damUrl'],
        "sire":false,
        "parentAvail":this.hasDamGrandLineage()
      }
    }

    if(this.lot.lineageHierarchy['sire'] != undefined && this.lot.lineageHierarchy['sire'].length > 0){
      this.sire = {
        "name": this.lot.lineageHierarchy['sire'],
        "regNum": this.lot.lineageHierarchy['sireRegNum'],
        "url": this.lot.lineageHierarchy['sireUrl'],
        "sire":true,
        "parentAvail":this.hasSireGrandLineage()
      }
    }
    
    if(this.lot.lineageHierarchy['sireParents']['sire'] != undefined && this.lot.lineageHierarchy['sireParents']['sire'].length > 0){
      this.sireParentSire = {
        "name": this.lot.lineageHierarchy['sireParents']['sire'],
        "regNum": this.lot.lineageHierarchy['sireParents']['sireRegNum'],
        "url": this.lot.lineageHierarchy['sireParents']['sireUrl'],
        "sire":true,
        "parentAvail":false
      }
    }

    if(this.lot.lineageHierarchy['sireParents']['dam'] != undefined && this.lot.lineageHierarchy['sireParents']['dam'].length > 0){
      this.sireParentDam = {
        "name": this.lot.lineageHierarchy['sireParents']['dam'],
        "regNum": this.lot.lineageHierarchy['sireParents']['damRegNum'],
        "url": this.lot.lineageHierarchy['sireParents']['damUrl'],
        "sire":false,
        "parentAvail":false
      }
    }

    if(this.lot.lineageHierarchy['damParents']['dam'] != undefined && this.lot.lineageHierarchy['damParents']['dam'].length > 0){
      this.damParentDam = {
        "name": this.lot.lineageHierarchy['damParents']['dam'],
        "regNum": this.lot.lineageHierarchy['damParents']['damRegNum'],
        "url": this.lot.lineageHierarchy['damParents']['damUrl'],
        "sire":false,
        "parentAvail":false
      }
    }

    if(this.lot.lineageHierarchy['damParents']['sire'] != undefined && this.lot.lineageHierarchy['damParents']['sire'].length > 0){
      this.damParentSire = {
        "name": this.lot.lineageHierarchy['damParents']['sire'],
        "regNum": this.lot.lineageHierarchy['damParents']['sireRegNum'],
        "url": this.lot.lineageHierarchy['damParents']['sireUrl'],
        "sire":true,
        "parentAvail":false
      }
    }
  }
  
  hasSireGrandLineage(){
    return this.lot.lineageHierarchy['sireParents']['sire'] != undefined && 
       this.lot.lineageHierarchy['sireParents']['sire'].length > 0 &&
       this.lot.lineageHierarchy['sireParents']['dam'] != undefined && 
       this.lot.lineageHierarchy['sireParents']['dam'].length > 0;
  }

  hasDamGrandLineage(){
    return this.lot.lineageHierarchy['damParents']['sire'] != undefined && 
       this.lot.lineageHierarchy['damParents']['sire'].length > 0 &&
       this.lot.lineageHierarchy['damParents']['dam'] != undefined && 
       this.lot.lineageHierarchy['damParents']['dam'].length > 0;
  }

  hasDamLineage(){
    return this.lot.lineageHierarchy['dam'] != undefined && 
       this.lot.lineageHierarchy['dam'].length > 0 &&
       this.lot.lineageHierarchy['dam'] != undefined && 
       this.lot.lineageHierarchy['dam'].length > 0;
  }

  hasSireLineage(){
    return this.lot.lineageHierarchy['sire'] != undefined && 
       this.lot.lineageHierarchy['sire'].length > 0 &&
       this.lot.lineageHierarchy['sire'] != undefined && 
       this.lot.lineageHierarchy['sire'].length > 0;
  }
  
  hasAllLineages(){
    return this.hasDamLineage() && this.hasSireLineage() && this.hasDamGrandLineage() && this.hasSireGrandLineage();
  }

  hasParentLineagesOnly(){
    return this.hasDamLineage() && this.hasSireLineage() && !this.hasDamGrandLineage() && !this.hasSireGrandLineage();
  }
  
  getDamSireLineCoord(){
    var returnValue = undefined;
    var div1 = $('#sireelement');
    var div2 = $('#damelement');
    var div3 = $('#sireelementcontainer');
    var div4 = $('#damelementcontainer');
    var container = $('#lineagecontainer');

    if(container != undefined && 
      container.offset() != undefined &&
      div1 != undefined && 
      div1.offset() != undefined &&
      div2 != undefined &&
      div2.offset() != undefined){
        this.damSireLineCoord = {
          "x1": div1.offset().left - container.offset().left,
          "y1": div1.offset().top - container.offset().top + div3.height()/2,
          "x2": div2.offset().left - container.offset().left,
          "y2": div2.offset().top - container.offset().top + div4.height()/2
        };
        returnValue = this.damSireLineCoord;
    }
    return returnValue;
  }

  getGrandDamSireLineCoord(){
    var returnValue = undefined;
    var div1 = $('#sireparentsireelement');
    var div2 = $('#sireparentdamelement');
    var div3 = $('#paternalgrandsirecontainer');
    var div4 = $('#paternalgranddamcontainer');
    var container = $('#lineagecontainer');
    
    if(container != undefined && 
      container.offset() != undefined &&
      div1 != undefined && 
      div1.offset() != undefined &&
      div2 != undefined &&
      div2.offset() != undefined){
        this.grandSireDamSireLineCoord = {
          "x1": div1.offset().left - container.offset().left,
          "y1": div1.offset().top - container.offset().top + div3.height()/2,
          "x2": div2.offset().left - container.offset().left,
          "y2": div2.offset().top - container.offset().top + div4.height()/2
        };
        returnValue = this.damSireLineCoord;
    }
    return returnValue;
  }
  
  getGrandDamDamLineCoord(){
    var returnValue = undefined;
    var div1 = $('#damparentsireelement');
    var div2 = $('#damparentdamelement');
    var div3 = $('#maternalgrandsirecontainer');
    var div4 = $('#maternalgranddamcontainer');
    var container = $('#lineagecontainer');
    
    if(container != undefined && 
      container.offset() != undefined &&
      div1 != undefined && 
      div1.offset() != undefined &&
      div2 != undefined &&
      div2.offset() != undefined){
        this.grandDamDamSireLineCoord = {
          "x1": div1.offset().left - container.offset().left,
          "y1": div1.offset().top - container.offset().top + div3.height()/2,
          "x2": div2.offset().left - container.offset().left,
          "y2": div2.offset().top - container.offset().top + div4.height()/2
        };
        returnValue = this.grandDamDamSireLineCoord;
    }
    return returnValue;
  }

  getLineagePointCoord(){
    var returnValue = undefined;
    var damToSireLineCoord = this.getDamSireLineCoord();
    
    if(damToSireLineCoord != undefined){
      var x2 = damToSireLineCoord['x2'];
      // var y2 = (damToSireLineCoord['y1'] + damToSireLineCoord['y2'])/2;
      
      var div1 = $('#lineagerooticon');
      var div2 = $('#lineagerootcontainer');
      var container = $('#lineagecontainer');
      
      if(container != undefined && 
        container.offset() != undefined &&
        div1 != undefined && 
        div1.offset() != undefined &&
        div2 != undefined &&
        div2.offset() != undefined){
          this.lineageRootPointCoord = {
            "x1": div1.offset().left - container.offset().left + div1.position().left + div1.innerWidth() - 1,
            "y1": div2.position().top + div2.outerHeight()/2,
            "x2": x2,
            "y2": div2.position().top + div2.outerHeight()/2
          };
          returnValue = this.lineageRootPointCoord;
      }
      return returnValue;
    }
  }

}
