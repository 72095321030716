<div class="container-fluid container-horiz-padded container-vert-padded no-gutters">

  <div class="row">
    <div class="col-1 col-md-3 col-lg-2 seller-link-container d-none d-md-block">
      <div class="fixed">
        <div style="line-height:15px"><a routerLink="/sellers" fragment="seller-info" class="seller-link">Marketing & Sales on Angus<br> Live</a></div>
        <div class="mt-2" style="line-height:15px"><a routerLink="/sellers" fragment="why-angus-live" class="seller-link seller-link-focused">Advantages of Selling with<br> Angus Live</a></div>
        <div class="mt-3"><a routerLink="/sellers" fragment="seller-services" class="seller-link">Services</a></div>
        <div><a routerLink="/sellers" fragment="ready-to-book-your-sale" class="seller-link seller-link-focused">Ready to Book Your Sale?</a></div>
        <div><a routerLink="/sellers" fragment="seller-tips" class="seller-link seller-link-focused">Seller Tips</a></div>
        <div><a routerLink="/sellers" fragment="terms-and-conditions" class="seller-link seller-link-focused">Terms & Conditions</a></div>
        <div class="mt-3"><a routerLink="/sellers" fragment="service-providers" class="seller-link">Service Providers</a></div>
        <div><a routerLink="/sellers" fragment="livestock-photography" class="seller-link seller-link-focused">Livestock Photography</a></div>
      </div>
    </div>
    <div class="col-11 col-md-9 col-lg-10">
      <app-section-header class="anchor" id="seller-info" type="primary">Marketing and Sales on Angus Live</app-section-header>
      <img src="../../../assets/icons/Sales_Marketing_icon.png" />

      <p class="mt-2">
        Other livestock auction sites exist, but Angus Live is the premier online platform for buying and selling angus. Here’s why:        
      </p>
      
      <ul>
        <li>
          <strong>Unmatched Expertise In Angus.</strong> Owner Jeremy Haag is a third-generation angus breeder, was sales manager at the American Angus Hall Of Fame for 15 years, and has co-authored the definitive guide to angus breeding history. There simply isn’t another auction website with more Angus prowess or passion.
        </li>
        
        <li>
          <strong>Breed-Specific Focus.</strong> Angus Live is the first breed-specific auction website, and our team has conducted Angus auctions in 38 states, so all of our animals are listed at carefully appraised price-points.
        </li>
        
        <li>
          <strong>Easy To Use Bidding And Selling Features.</strong> Don’t be intimidated by attending your first online auction — the Angus Live Native Mobile App and bidding portal make buying or selling accessible and secure.
        </li>

        <li>
          <strong>Proven Track Record.</strong> Since our founding in 2012, we’ve prided ourselves on our customer successes, so you can be assured that your Angus Live auction experience will be a profitable one.          
        </li>
        
      </ul>

      <app-section-header class="anchor" id="why-angus-live" type="primary">Advantages of Selling with Angus Live</app-section-header>

      <p>
        As an emerging or established Angus seller, we would like to invite you to realize the advantages of using Angus Live as an auction management platform: saving you sale day operation costs; increasing your brand exposure; and leveraging our nation-wide connections to thousands of buyers.
      </p>
      
      <h6>More sales, with less effort</h6>
      

      <ul>
        <li>
          Friendly, helpful and professional service in setting up and running your auction          
        </li>
        
        <li>
          Deep sales knowledge about the Angus breed to help with arranging the lot order, sales descriptions, and marketing insights          
        </li>

        <li>
          A built-in invoicing system          
        </li>

        <li>
          Our web-based system records all buyers, prices, and auction details. We can re-rack a sale in very little time for you.
        </li>
      </ul>
      
      <h6>Reach more, and find new customers</h6>
      <ul>
        <li>
          Leveraging our contacts and database as well as our Facebook, Instagram, and Twitter feeds for extending awareness and to get your sale and contact links to the buying public.          
        </li>
        <li>
          A better experience for bidders
        </li>
        <li>
          Fast and reliable bidding platform for desktop and mobile, and a bespoke app for Android or iPhone.          
        </li>
      </ul>

      <app-section-header class="anchor" id="seller-services" type="primary">Services</app-section-header>
      <img src="../../../assets/icons/Services_icon.png" />
      
      <h6 class="mt-3">E-Mail Blasts</h6>
      <p>
        We are constantly refining our database as we criss-cross the country and run sales. Email marketing can add further traffic to your website or Facebook page and more interest in your upcoming sale.
      </p>

      <h6>Print Advertising</h6>
      <p>
        Print advertising includes magazines and newspapers that cover both regional and local audiences as well as national. We utilize publications that give us the greatest “bang for the buck.”        
      </p>

      <h6>Broadcast Advertising</h6>
      <p>
        Radio ads provide opportunities throughout many parts of the country and deliver a low cost/thousand audience. Selective broadcast spots can deliver new customers to an established program and bring an focus and awareness to a new program.        
      </p>

      <h6>Direct Mail Advertising</h6>
      <p>
        Brochures, newsletters and post cards can be expensive to produce however they are still cheaper to mail than a sale book. We have found it a good strategy to send out the direct mail first and ask for a sale book request.        
      </p>

      <h6>Personal Selling</h6>
      <p>
        While you may hire an auction platform like Angus Live to market and promote your sale, many customers still want to talk to you and get to know you before doing business, we can create channels for that communication to happen.        
      </p>

      <h6>Customer Service</h6>
      <p>
        In our industry we deal with mother nature and things don’t always turn out the way we want – we can the worry about how to handle unforeseen events. We take care of our customers, and always treat them fairly and responsibly.        
      </p>

      <h6>Social Media Marketing Packages</h6>
      <p>
        We have a three-tiered marketing package offerings and various a la carte service available. Choose from our included starter package to our deluxe Pathfinder Package, to publicize your sale through our marketing channels and your own. We’ll provide you will assets you can post to your Facebook and Instgram feeds and post the same to our own. More details available on inquiry.        
      </p>

      <h6>Sale Management Services</h6>
      <p>
        Our complete service includes all pre-sale planning and implementation of advertising, sale promotion, as well as invoicing and collections post-sale.<br>
        Our partial service usually means the principal handles all post-sale duties and we charge a reduced fee. Our customers include individual producers, National and State Breed Associations for production and consignment sales.        
      </p>

      <h6>Marketing Plan</h6>
      <p>
        All marketing ventures should start with a plan. This plan will outline the goals, budget and details of the project so that marketing costs stay in-line with those goals and net income can be maximized. Regardless if we are planning a production, consignment or a private treaty sale, we will assist you with a detailed marketing plan and program utilizing our over 15 years of experience. We are sensitive to production and marketing costs and will work with you to keep them all within reasonable levels while still able to reach the best price for your cattle.        
      </p>

      <app-section-header class="anchor" id="ready-to-book-your-sale" type="primary">Ready to book your sale?</app-section-header>
      <p class="mt-3">
        Reach over 10,000 livestock producers with one email! Our staff will custom design an email and social media broadcast that can include links to your website or facebook page, auction listing, and Youtube or Vimeo video page. This eye-appealing marketing piece can then be sent to the largest list in the Angus industry!        
      </p>

      <p>
        Multiple social media posts and emails will increase the number of eyes and awareness of your sale. We can highlight your premium lots, provide updates to sale information, or simply drive attention to your sale and program.        
      </p>

      <p>
        Our experience and commitment in full service sales management and marketing of live and frozen genetics offers established and emerging Angus breeders the opportunity to continually build their program at a lower cost than in-person sales.        
      </p>
      
      <app-section-header class="anchor" id="seller-tips" type="primary">Ready to book your sale?</app-section-header>
      <p>
        Developing and maintaining a marketing program needs almost as much attention and effort as a breeding program. Regardless whether your program’s end product is feeder cattle or seedstock understanding, growing awareness of your brand and program and increasing your buyer base will give you an advantage and a much greater chance for success.
      </p>
      
      <p>
        There are two truths that you will deal with in your marketing program. “Perception is Reality” and “You can have the best cattle in the world, but if nobody knows about them, how can you sell them.” Marketing is more than advertising.
      </p>

      <p>
        Measurements are important to most buyers, whether commercial, performance or show breeders. Planning your sales effort with your ability to acquire and record that data is important to give potential buyers the information they require to make buying decisions. They are looking for genetics and breeding that fit into their programs, just like you. Planning your breeding program so that you can acquire necessary data in time for your sale is important.
      </p>

      <p>
        It is important to differentiate your brand or product to buyers so they can relate to the genetics you provide them. While thousands of people breed, grow and sell Angus Cattle – not all programs are the same and not all genetics are the same. Product differentiation allows your buyers to zero in on the attributes and benefits of your genetics. Lessons learned from other industries apply here - knowing your story and what your mission and vision are can help you tell potential buyers why they should buy from you, or why they should pay the price you’re asking them to pay.
      </p>

      <p>
        Well documented production and performance information adds significant value to your product and increases price. Sure, some buyers are only interested in phenotype but most in today’s industry want to utilize the tools in the toolbox to make a decision. EPDs and lineage matter to certain buyers, however the best pedigree and the best cattle “on paper” will not consistently bring the best dollar if you don’t pay attention to the details of records and management to add value to your product.
      </p>

      <app-section-header class="anchor" id="terms-and-conditions" type="primary">Terms & Conditions</app-section-header>
      <p>
        “Seller” shall mean the party who is placing the lot(s) or merchandise for sale.<br>
        “Bidder” shall mean the party submitting an offer to purchase the lot(s) or merchandise for sale.<br>
        “Buyer” shall mean “Bidder” who has submitted the successful offer to purchase the lot(s) or merchandise for sale.<br>
        “Sale Manager/Management” shall mean G2 Live LLC.<br>
      </p>
      <h6>Registration Papers & Delivery</h6>
      <p>
        All lots purchased through G2 Live LLC will become the property of the Buyer at the conclusion of the auction and, as such, all risk of damage, death or injury to lots purchased becomes the Buyer’s risk. Sale Management urges all new buyers to take out insurance on new purchases concluding the close of the sale.
      </p>
      <p>
        Delivery of all lots purchased through G2 Live LLC must be coordinated between the Buyer and Seller. Any fees incurred with delivery are the sole responsibility of the Buyer, unless other arrangements are agreed upon subsequent to the auction between the Buyer and Seller. Any questions regarding delivery should be directed to the Seller. If Seller delivers any lot(s) sold to the Buyer before payment has been received, collecting payment for said lot(s) becomes the sole responsibility of the Seller.        
      </p>

      <h6>Registration & Health Papers</h6>
      <p>
        Registration papers shall be delivered from the Seller to the Buyer within 45 days of cleared payment. The Seller is responsible for the transfer of registration papers. Sale Management assumes no responsibility for the completion of this transfer.
      </p>
      <p>
        Health papers may be furnished by the Seller, at the request of the Buyer. Any fees related to the furnishing of health papers are the sole responsibility of the Buyer 
      </p>
      
      <h6>Liability</h6>
      <p>
        Sale Management shall not be liable for lost profit or special, incidental or consequential damages out of or in connection with any sale transaction. Bidders, Buyers and Sellers hereby agree to indemnify and hold G2 Live LLC, its members, parent subsidiaries, affiliates, officers and employees, harmless from any claim or demand, including reasonable attorney’s fees, made by any third party due to or arising out of Bidder’s, Buyer’s or Seller’s use of the this service, the violation of these terms of service, for any information transmitted by any of the aforementioned on the G2 Live LLC sales site.
      </p>
      <p>
        Sale Management has the sole and final discretion to determine winning bidders and, in the event of a dispute between parties, the decision of the Sale Manager is final. Sale Manager reserves the right to reopen bidding to settle matters related to disputed bids or ties. Sale Manager reserves the right to postpone or cancel a sale for any reason.
      </p>
      <p>
        Sale Manager does not guarantee continuous, uninterrupted or secure access to online sales services, and operation of the online sale site may be interfered with by numerous factors outside of Sale Management’s control. Furthermore, due to the nature of online services, Sale Manager cannot guarantee or confirm the authentic identity of Bidders, Buyer or Sellers and shall have no liability for any false or misleading information transmitted by the aforementioned on the G2 Live LLC auction site.
      </p>
      <p>
        Sale Manager is not responsible for any accidents or injury related to the sale, purchase or delivery of any lot(s) sold.
      </p>
      <p>
        Sale Manager may refuse services to anyone at anytime at its sole discretion. If a Bidder provides false information, such as a false name or fraudulent contact information, he/she or it will be suspended or permanently banned from G2 Live LLC sales.
      </p>
      
      <app-section-header class="anchor" id="service-providers" type="primary">Service Providers</app-section-header>
      <img src="../../../assets/icons/serviceProviders.png" />

      <p class="mt-2">Professional photography services can enhance the price discovery on your top sale lots.</p>

      <app-section-header class="anchor" id="livestock-photography" type="primary">Livestock Photography</app-section-header>
      <div class="row mt-3">
        <div class="col d-flex flex-row flex-wrap photo-group-container">
          <div *ngFor="let data of livestockPhotographyAds" class="photo-card-container">
            <app-ad-card [data]="data"></app-ad-card>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>
