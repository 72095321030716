<div class="container-fluid">

    <ng-container *ngIf="getLotNum().length > 0">
        <div class="row mb-2">
            <div class="col-md-12 auction-label-text">
                LOT {{ getLotNum() }}
            </div>
        </div> 
    </ng-container>

    <ng-container *ngIf="getTitle().length > 0">
        <div class="row mb-2">
            <div class="col-md-12 info-text" style="font-size:13px">
                {{ getTitle() }}
            </div>
        </div> 
    </ng-container>
    
    <ng-container *ngIf="getCategory().length > 0">
        <div class="row">
            <div class="col-md-12 info-text" style="font-size:13px">
                {{ getCategory() }}
            </div>
        </div> 
    </ng-container>
    
</div> <!-- container-fluid -->
