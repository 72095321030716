import { Component, OnInit } from '@angular/core';
import { AuctionResultLineItem } from 'src/app/model/auction.model';
import { AccountService, PortalUser } from 'src/app/model/portaluser.model';
import moment from 'moment';

@Component({
  selector: 'app-buyer-wins',
  templateUrl: './buyer-wins.component.html',
  styleUrls: ['./buyer-wins.component.scss']
})
export class BuyerWinsComponent implements OnInit {

  constructor(private portalUserService: PortalUser) { }

  public timeFrameOptions: any = [
    { key: 1, value: 'Last 1 Month' },
    { key: 3, value: 'Last 3 Months' },
    { key: 12, value: 'Last Year' },
    { key: 1200, value: 'All' }
  ];

  public timeFrameOption: number = 1;
  public wonLineItems: AuctionResultLineItem[] = [];

  ngOnInit(): void {
  }

  public loadPageData() {
    this.filterResults();
  }

  public async filterResults() {
    const calculatedAsOfDate = moment().add(-1 * this.timeFrameOption, 'months').toISOString();
    await this.portalUserService.me(calculatedAsOfDate)
    this.wonLineItems = this.portalUserService.wins || [];
  }
}
