<div class="container-fluid" id="lineagedetailcontainer">
    <div class="row">
        <div class="col-1 justify-content-center align-self-center nopadding">
            <div class="connector"></div>
        </div>
        <div class="col-11 justify-content-center align-self-center nopadding" [ngClass]="getConnectorClass()" [style]="getStyleStr()">
            
            <div style="flex-direction:column">
                <div class="info-text">
                    <div>
                        <span class="mr-1" *ngIf="detail.sire">(Sire)</span>
                        <span class="mr-1" *ngIf="!detail.sire">(Dam)</span>
                        {{ detail.name }}
                    </div>
                    <div>
                        <span *ngIf="detail.regNum != undefined && getRegUrl().length > 0" class="link-text"><a target="_blank" [href]="detail.url" class="link-text">{{ detail.regNum }}</a></span>
                        <span *ngIf="detail.regNum != undefined && getRegUrl().length == 0" class="info-data-text">{{ detail.regNum }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</div> <!-- container-fluid -->
