import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { PasswordCheck } from '../passwordcheck';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  errorMsg = "";
  processing = false;

  showPassword:boolean = false;
  showNewPasswordText:boolean = false;
  showConfirmPasswordText:boolean = false;

  changePassword = false;
  confirmation = false;
  login = true;

  /* Form entry */
  email:string = "";
  password:string = "";
  newpassword:string = "";
  confirmpassword:string = "";
  portalUser = undefined;
  verificationcode:string = "";
  
  @Output() messageEvent = new EventEmitter<any>();

  constructor(private authService: AuthService){}

  ngOnInit(): void {
    this.showLogin();
  }
  
  passwordCharLength(){
    return PasswordCheck.passwordCharLength(this.newpassword);
  }

  passwordUpperCase(){
    return PasswordCheck.passwordUpperCase(this.newpassword);
  }

  passwordLowerCase(){
    return PasswordCheck.passwordLowerCase(this.newpassword);
  }

  passwordSpecialChars(){
    return PasswordCheck.passwordSpecialChars(this.newpassword);
  }

  passwordNumber(){
    return PasswordCheck.passwordNumber(this.newpassword);
  }
  
  validPassword(){
    return PasswordCheck.validPassword(this.newpassword);
  }
  
  confirmPasswordCheck(){
    return (this.newpassword.length > 0) && (this.newpassword == this.confirmpassword);
  }
  
  showLogin(){
    this.login = true;
    this.changePassword = false;
    this.confirmation = false;
  }

  showNewPassword(){
    this.login = false;
    this.changePassword = true;
    this.confirmation = false;
  }

  showConfirmation(){
    this.login = false;
    this.changePassword = false;
    this.confirmation = true;
  }

  onToggleShowPassword(){
    this.showPassword = !this.showPassword;
  }

  onToggleShowNewPassword(){
    this.showNewPasswordText = !this.showNewPasswordText;
  }
  
  onToggleShowConfirmPassword(){
    this.showConfirmPasswordText = !this.showConfirmPasswordText;
  }
  
  close(){
    var eventMsg = {}
    eventMsg['message'] = 'closelogin';
    this.messageEvent.emit(eventMsg);
  }
  
  onForgotPassword(){
    var eventMsg = {}
    eventMsg['message'] = 'forgotpassword';
    // eventMsg['message'] = 'changepassword';
    this.messageEvent.emit(eventMsg);
  }

  onSignUp(){
    var eventMsg = {}
    eventMsg['message'] = 'signup';
    this.messageEvent.emit(eventMsg);
  }

  onSignIn(){
    this.processing = true;
    this.errorMsg = "";
    this.authService.signinUser(this.email, this.password).then(response => {
        this.processing = false;
        this.processLoginResponse(response);
    }, error => {
        this.processing = false;
        this.processLoginException(error);
        return;
    });
  }
  
  
  onSignInNewPassword(){
    this.errorMsg = "";
    if(this.newpassword != this.confirmpassword){
      this.errorMsg = "New password and confirmation password do not match";
      return;
    }

    this.processing = true;
    this.authService.completeNewPassword(this.email, this.password, this.newpassword).then(response => {
        this.processing = false;
        this.signinSuccessful();
      }, error => {
        this.processing = false;
        this.processLoginException(error);
        return;
    });
  }
  
  onConfirm(){
    this.errorMsg = "";
    this.processing = true;
    
    this.authService.confirmUser(this.email,this.verificationcode).then(response => {
        this.errorMsg = "";
        this.onSignIn();
    }, error => {
        console.log('error confirming',error);
        this.errorMsg = error.message;
        this.processing = false;
        return;
    });
    
  }
  
  async processLoginResponse(response){
    if(response.challengeName == "NEW_PASSWORD_REQUIRED"){
      this.showNewPassword();
    }
    else {
      this.signinSuccessful();
    }
  }

  processLoginException(exception){
    if(exception.code == 'PasswordResetRequiredException'){
      this.showNewPassword();
    }
    else if(exception.code == 'UserNotConfirmedException'){
      this._resendSignup();
    }
    else if(exception.code == 'NotAuthorizedException'){
      this.errorMsg = "The email and password you entered did not match our records. Please double-check and try again.";
      this.showLogin();
    }
    else if(exception.code == 'UserNotFoundException'){
      this.errorMsg = "We couldn’t find your account.";
      this.showLogin();
    }
    else {
      this.errorMsg = exception.message==undefined ? "" : exception.message;
    }
  }
  
  resetPassword(){
    var eventMsg = {}
    eventMsg['message'] = 'forgotpassword';
    eventMsg['email'] = this.email;
    this.messageEvent.emit(eventMsg);
  }

  async signinSuccessful(){
    await this.authService.getAuthenticatedUser();
    var eventMsg = {}
    eventMsg['message'] = 'signinsuccessful';
    eventMsg['portaluser'] = this.authService.activeUser;
    this.messageEvent.emit(eventMsg);
  }
  
  onAppleSignin(){
    this.authService.loginApple();
  }

  onFacebookSignin(){
    this.authService.loginFacebook();
  }

  onGoogleSignin(){
    console.log('onGoogleSignin');
    this.authService.loginGoogle();
  }

  _resendSignup(){
    this.errorMsg = "";
    this.processing = true;
    
    this.authService.resendSignup(this.email).then(response => {
        this.processing = false;
        this.showConfirmation();
    }, error => {
        console.log('error confirming',error);
        this.errorMsg = error.message;
        this.processing = false;
        return;
    });
    
  }


}
