import { Component, OnInit, Input } from '@angular/core';
import { InvoiceLineItem } from '../../model/invoice.model';

@Component({
  selector: 'app-invoice-line-item-list',
  templateUrl: './invoice-line-item-list.component.html',
  styleUrls: ['./invoice-line-item-list.component.scss']
})
export class InvoiceLineItemListComponent implements OnInit {

  @Input() lineItems: InvoiceLineItem[] = [];

  constructor() { }

  ngOnInit(): void {

  }

}
