import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgImageSliderComponent } from 'ng-image-slider';
import Plyr from 'plyr';
import { LotModel } from '../../model/auction.model';
import { EventService } from '../../services/event.service';

interface MediaObject {
  image: string;
  thumbImage: string;
  title: string;
}

interface VideoSource {
  src: string;
  provider: string;
  type: string;
}

@Component({
  selector: 'app-lotdetail',
  templateUrl: './lotdetail.component.html',
  styleUrls: ['./lotdetail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LotdetailComponent implements OnInit {
  errorMsg = "";
  processing: boolean = false;

  @Input() authenticated: boolean;
  @Input() lot: LotModel;
  @Input() previewMode: boolean = false;
  @Input() disableLotLink: boolean = false;
  @Input() detailedView: boolean = false;
  @Input() showFootnote: boolean = true;
  @Output() messageEvent = new EventEmitter<any>();


  @ViewChild('bidding') bidding;
  @ViewChild('imageslider') slider: NgImageSliderComponent;

  maxbid:string;

  imageObjects: MediaObject[] = [];
  videoObjects: MediaObject[] = [];
  mediaCurrentlyExpanded: boolean = false;

  player: Plyr;

  videoSources: Plyr.Source[] = []

  constructor(private router: Router,
    public eventService: EventService) { }

  ngOnInit(): void {
    this.loadImageObjects();
    this.loadVideoObjects();
  }

  isEmpty(obj: any) {
    return (typeof obj === 'undefined') || obj === '' || obj === null;
  }

  loadVideoObjects() {
    const videoLink = this.getVideoLink();
    if (!this.isEmpty(videoLink)) {
      this.videoSources.push(this.extractVideoSource(videoLink));
    }

    const uploadedVideos = this.lot.videos;
    if (!this.isEmpty(uploadedVideos) && uploadedVideos.length > 0){
      for (let item of uploadedVideos){
        this.videoSources.push(this.extractVideoSource(this.lot.getVideoUrl(item)));
      }
    }
  }

  extractVideoSource(videoLink: string): Plyr.Source {
    const linkComponents = videoLink.replace("https://", "").replace("http://", "").split("/");
    var result = {} as Plyr.Source;
    if (videoLink.includes("vimeo")) {
      result.provider = "vimeo";
      if (linkComponents.length < 2) {
        console.error(`Could not extract Vimeo link at ${videoLink}.`);
      } else {
        result.src = linkComponents[linkComponents.length - 1];
      }
    } else if (videoLink.includes("youtube") || videoLink.includes("youtu.be")) {
      result.provider = "youtube";
      result.src = linkComponents[linkComponents.length - 1];
    } else {
      // Assume it's a standard video.
      result.src = videoLink;
      result.type = 'video/' + videoLink.split('.').pop();
    }
    return result;
  }

  loadImageObjects(){
    this.imageObjects = [];
    if(this.lot.photos != undefined && this.lot.photos.length > 0){
      for(let item of this.lot.photos){
        var imageInfo = {} as MediaObject;
        imageInfo['image'] = this.lot.getPhotoUrl(item);
        imageInfo['thumbImage'] = this.lot.getPhotoUrl(item);
        imageInfo['title'] = item.caption;
        this.imageObjects.push(imageInfo);
      }
    }

    const videoLink = this.getVideoLink();
    if(videoLink != undefined && videoLink.length > 0 && this.isSupportedVideoLink(videoLink)){
      var imageInfo = {} as MediaObject;
      imageInfo['video'] = videoLink;
      imageInfo['alt'] = "external video link";
      this.videoObjects.push(imageInfo);
    }

    if(this.lot.videos != undefined && this.lot.videos.length > 0){
      for(let item of this.lot.videos){
        var imageInfo = {} as MediaObject;
        imageInfo['video'] = this.lot.getVideoUrl(item);
        imageInfo['alt'] = "internal video link";
        imageInfo['title'] = item.caption;
        this.videoObjects.push(imageInfo);
      }
    }
  }

  isSupportedVideoLink(videoLink: string): boolean {
    return videoLink && !videoLink.includes("vimeo");
  }

  getVideoLink(){
    return this.lot.externalVideoUrl != undefined && this.lot.externalVideoUrl.length > 0 ? this.lot.externalVideoUrl : "";
  }

  onOpenVideoLink(){
    if(this.getVideoLink().length > 0){
      window.open(this.getVideoLink(),"_blank");
    }
  }

  // public openVideoLightbox() {
  //   this.mediaCurrentlyExpanded = true;
  //   this.eventService.createEvent({ message: 'unstick-header' });
  //   this.videoSlider.showLightbox();
  // }

  public openImageLightbox(index: number) {
    this.mediaCurrentlyExpanded = true;
    this.eventService.createEvent({ message: 'unstick-header' });
    this.slider.imageOnClick(index);
  }

  public getDetailsLink() {
    if (!this.lot) return '/auctions';

    if(this.previewMode) {
      return ['/previewauction', this.lot.auctionId, this.lot.lotId];
    }
    else {
      return ['/auctions',this.lot.auctionId,this.lot.lotId];
    }
  }

  async receiveMessage($event) {
    this.messageEvent.emit($event);
  }

  public loadPageData() {
    this.bidding.loadPageData();
  }


  imageClicked() {
    this.eventService.createEvent({ message: 'unstick-header' });
  }

  fullScreenImageClosed() {
    this.mediaCurrentlyExpanded = false;
    this.eventService.createEvent({ message: 'stick-header' });
  }

  mediaExpanded() {
    return this.mediaCurrentlyExpanded;
  }

  play(): void {
    this.player.play(); // or this.plyr.player.play()
  }

  played(event: Plyr.PlyrEvent) {

  }

  showVideoPlayer(){
    return this.player != undefined ? this.player.fullscreen.active : false;
  }

  openVideoPlayer(){
    this.player.fullscreen.enter();
    this.player.play();
  }
}
