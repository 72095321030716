<div *ngFor="let auctionKey of auctionKeys()" class="auction-group">
  <div class="auction-group-title">{{ groupListing[auctionKey].name }}</div>
  <h6 class="auction-group-date">{{ formatAuctionDate(auctionKey) }}</h6>
  <div *ngFor="let sellerKey of sellerKeys(auctionKey)">
    <div class="d-flex flex-row justify-content-between align-items-center winning-bidder-container">
      <div class="d-flex flex-row">
        <div class="winning-bidder-header">Seller</div>
        <div class="ml-3">{{ groupListing[auctionKey].sellers[sellerKey].name }}</div>
      </div>
      <div class="d-flex flex-row align-items-center">
        <ng-container *ngIf="invoicePaid(auctionKey, sellerKey); else invoice_statuses">
          <div class="paid-container d-flex">
            <nb-icon status="basic" icon="done-all-outline"></nb-icon>
            <span class="ml-2"><strong>Paid {{ formatAsMonthYear(groupListing[auctionKey].sellers[sellerKey].paymentDate) }}</strong></span>
          </div>
        </ng-container>
        <ng-template #invoice_statuses>

        </ng-template>
        <ng-container *ngIf="invoiceCreated(auctionKey, sellerKey)">
          <button nbButton status="basic" size="small" class="d-flex align-self-center" (click)="onViewInvoice(auctionKey, sellerKey)">Invoice</button>
        </ng-container>
      </div>
    </div>
    <div class="table-responsive winning-bidder-table">
      <table class="table">
        <thead>
          <tr>
            <th scope="col" class="table-header-text-link" style="min-width: 4rem;">LOT #</th>
            <th scope="col" class="table-header-text-link" style="min-width: 18.75rem;">NAME</th>
            <th scope="col" class="table-header-text-link text-right" style="width: 10rem;">FINAL BID</th>
            <th scope="col" class="table-header-text-link text-right">QTY</th>
            <th scope="col" class="table-header-text-link text-right">ADJUSTMENT</th>
            <th scope="col" class="table-header-text-link text-right">FINAL PRICE</th>
          </tr>
        </thead>
        <tbody>
            <tr app-buyer-auction-win-list-detail *ngFor="let item of groupListing[auctionKey].sellers[sellerKey].lineItems" [lot]="item"></tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
