import { Component, OnInit } from '@angular/core';
import { AuctionStatus, SaleStatus } from 'src/app/API.service';
import { AuctionReportLineItem, AuctionReportModel } from '../../model/auction.model';
import { AuctionModelService } from '../../model/auction.model';

interface ISortingConfig {
  [key: string]: {
    field: string,
  };
}

const DESC = 'DESC';
const ASC = 'ASC';

@Component({
  selector: 'app-auction-report-lot-list',
  styleUrls: ['./auction-report-lot-list.component.scss'],
  templateUrl: './auction-report-lot-list.component.html',
})
export class AuctionReportLotListComponent implements OnInit {

  constructor(private auctionModel: AuctionModelService) {

  }

  public processing: boolean = false;
  public filteredList: AuctionReportLineItem[] = [];
  public sortingConfig: ISortingConfig;
  private sortField = 'finalBid';
  private sortDirection = DESC;
  private lotLimit = 50;
  private lastItemId = null;
  public auctionReport: AuctionReportModel = new AuctionReportModel();

  public ngOnInit(): void {
    this.sortingConfig = {
      finalBid: {
        field: 'FINAL_BID',
      },
      winningBidder: {
        field: 'WINNING_BUYER',
      },
    };
  }

  public get auctionStatus() {
    return this.auctionModel && this.auctionModel.activeAuction && this.auctionModel.activeAuction.status;
  }

  public onSort(element: string): void {
    this.sortDirection = this.sortDirection === DESC ? ASC : DESC;
    this.sortField = element;
    this.refreshList();
  }

  public isSortedAsc(fieldName: string): boolean {
    return this.sortField === fieldName && this.sortDirection === ASC;
  }

  public isSortedDesc(fieldName: string): boolean {
    return this.sortField === fieldName && this.sortDirection === DESC;
  }

  public async refreshList(): Promise<any> {
    this.auctionReport = await this.auctionModel.loadAuctionReport(
      this.auctionModel.activeAuction.auctionId,
      this.sortingConfig[this.sortField].field,
      this.sortDirection,
      this.lotLimit,
      this.lastItemId
    )
    // console.log("auctionReport",this.auctionReport);
  }

  public filterByStatus(lineItems: AuctionReportLineItem[], saleStatus: SaleStatus): AuctionReportLineItem[] {
    return lineItems.filter(x => x.saleStatus === saleStatus);
  }

  public async receiveMessage($event) {
    if ($event['message'] === 'updatePaymentStatus') {
      const lot = $event['lot'];

      const winningBidderEmail = lot.winningBidderEmail;


    }
  }
  
  getTotalGrossSales(){
    return this.auctionReport != undefined && this.auctionReport.totalGrossSales != undefined  ? this.auctionReport.totalGrossSales.toLocaleString("en-US") : "";
  }
  
}
