import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-auctionreport',
  styleUrls: ['./auctionreport.component.scss'],
  templateUrl: './auctionreport.component.html',
})
export class AuctionreportComponent implements OnInit {
  @ViewChild('auctionReportLotList') auctionReportLotList;

  constructor() { }

  public ngOnInit(): void {

  }

  public async loadPageData() {
    this.auctionReportLotList.refreshList();
  }

}
