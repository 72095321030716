import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ad-card',
  templateUrl: './ad-card.component.html',
  styleUrls: ['./ad-card.component.scss']
})
export class AdCardComponent implements OnInit {
  
  @Input() data;

  constructor() { }

  ngOnInit(): void {
  }
  
  getMailToUrl(){
    let subject = this.data.emailsubject != undefined ? "?subject=" + this.data.emailsubject : "";
    return ("mailto:" + this.data.email + subject);
  }

  getWebSiteUrl(){
    let returnValue = this.data.website;
    if(returnValue.length > 0){
      var isHttp = returnValue.indexOf("http://") != -1 || returnValue.indexOf("https://") != -1 ? true : false;
      returnValue = isHttp ? returnValue : "https://" + returnValue;
    }
    return returnValue;
  }

  
}
