<div class="modal-scroll auth-card">
    <nb-card>
            <nb-card-header style="padding:1rem 0px 1rem 0px">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col no-gutters">
                            <div class="login-center">
                                <div style="margin-right:auto;visibility:hidden">
                                    <nb-icon icon="close"></nb-icon>
                                </div>
                                <img class="login-header-img" src="../../assets/brand/logo_white.svg">
                                <div style="margin-left:auto;margin-top:-20px">
                                     <a [routerLink]="" (click)="close()" class="button-link"><nb-icon icon="close"></nb-icon></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <div class="login-center"><span class="login-header-text">Reset your Password</span></div>
                        </div>
                    </div>

                </div> <!--END: container-fluid -->
            </nb-card-header>

            <nb-card-body style="padding:1rem 0px 1rem 0px" class="maincontent" [nbSpinner]="processing" nbSpinnerStatus="primary" nbSpinnerSize="giant">
              <form #f="ngForm" (ngSubmit)="onChangePassword()">
                <div class="container-fluid">

                    <div class="row mb-4">
                        <div class="col">
                            <div class="msg-text" style="text-align:center">Enter a new password.</div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <div class="label-text">CURRENT PASSWORD</div>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            <div class="form-control-group">
                                <nb-form-field>
                                    <input type="password"
                                           nbInput
                                           fullWidth
                                           id="password"
                                           name="password"
                                           data-toggle="password"
                                           placeholder="Type your password"
                                           [type]="showPassword ? 'text' : 'password'"
                                           [(ngModel)]="currentpassword"
                                           [ngClass]="{'inputError':!passwordentry.valid && passwordentry.touched}"
                                           #passwordentry="ngModel"
                                           required>
                                    <button type="button" nbSuffix nbButton ghost (click)="onToggleShowPassword()">
                                       <nb-icon [icon]="showPassword ? 'eye-outline' : 'eye-off-2-outline'"
                                                pack="eva"
                                                [attr.aria-label]="showPassword ? 'hide password' : 'show password'">
                                       </nb-icon>
                                    </button>
                                </nb-form-field>
                                <div class="errorText" *ngIf="!passwordentry.valid && passwordentry.touched">Please provide a password.</div>
                            </div> <!-- form-control-group -->
                        </div> <!-- col -->
                    </div> <!-- row -->

                    <div class="row">
                        <div class="col">
                            <div class="label-text">NEW PASSWORD</div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            <div class="form-control-group">
                                <nb-form-field>
                                    <input type="password"
                                           nbInput
                                           fullWidth
                                           id="newpassword"
                                           name="newpassword"
                                           data-toggle="password"
                                           placeholder="Type your password"
                                           [type]="showNewPassword ? 'text' : 'password'"
                                           [(ngModel)]="newpassword"
                                           [ngClass]="{'inputError':!newpasswordentry.valid && newpasswordentry.touched}"
                                           #newpasswordentry="ngModel"
                                           required>
                                    <button type="button" nbSuffix nbButton ghost (click)="onToggleShowNewPassword()">
                                       <nb-icon [icon]="showNewPassword ? 'eye-outline' : 'eye-off-2-outline'"
                                                pack="eva"
                                                [attr.aria-label]="showNewPassword ? 'hide password' : 'show password'">
                                       </nb-icon>
                                    </button>
                                </nb-form-field>
                                <div class="errorText" *ngIf="!newpasswordentry.valid && newpasswordentry.touched">Please provide a new password.</div>
                            </div> <!-- form-control-group -->
                        </div> <!-- col -->
                    </div> <!-- row -->

                    <div class="row mb-2">
                        <div class="col">
                            <table>
                                <tr>
                                    <td style="width:1.5rem">
                                        <nb-icon *ngIf="passwordCharLength()" icon="checkmark-outline" style="vertical-align:bottom;color: #00e096"></nb-icon>
                                    </td>
                                    <td>
                                        <span class="msg-text">8 character minimum</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col">
                            <table>
                                <tr>
                                    <td style="width:1.5rem">
                                        <nb-icon *ngIf="passwordUpperCase()" icon="checkmark-outline" style="vertical-align:bottom;color: #00e096"></nb-icon>
                                    </td>
                                    <td>
                                        <span class="msg-text">1 upper case letter</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col">
                            <table>
                                <tr>
                                    <td style="width:1.5rem">
                                        <nb-icon *ngIf="passwordLowerCase()" icon="checkmark-outline" style="vertical-align:bottom;color: #00e096"></nb-icon>
                                    </td>
                                    <td>
                                        <span class="msg-text">1 lower case letter</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col">
                            <table>
                                <tr>
                                    <td style="width:1.5rem">
                                        <nb-icon *ngIf="passwordNumber()" icon="checkmark-outline" style="vertical-align:bottom;color: #00e096"></nb-icon>
                                    </td>
                                    <td>
                                        <span class="msg-text">1 number</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col">
                            <table>
                                <tr>
                                    <td style="width:1.5rem">
                                        <nb-icon *ngIf="passwordSpecialChars()" icon="checkmark-outline" style="vertical-align:bottom;color: #00e096"></nb-icon>
                                    </td>
                                    <td>
                                        <span class="msg-text">1 special character: !@#$%^&*()</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <div class="label-text">RE-ENTER NEW PASSWORD</div>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            <div class="form-control-group">
                                <nb-form-field>
                                    <input type="password"
                                           nbInput
                                           fullWidth
                                           id="confirmpassword"
                                           name="confirmpassword"
                                           data-toggle="password"
                                           placeholder="Type your password"
                                           [type]="showConfirmPassword ? 'text' : 'password'"
                                           [(ngModel)]="confirmpassword"
                                           [ngClass]="{'inputError':!confirmpasswordentry.valid && confirmpasswordentry.touched}"
                                           #confirmpasswordentry="ngModel"
                                           required>
                                    <button *ngIf="!confirmPasswordCheck()" type="button" nbSuffix nbButton ghost (click)="onToggleShowConfirmPassword()">
                                       <nb-icon [icon]="showConfirmPassword ? 'eye-outline' : 'eye-off-2-outline'" pack="eva"></nb-icon>
                                    </button>
                                    <nb-icon *ngIf="confirmPasswordCheck()" nbSuffix style="color: #00e096" icon="checkmark-outline" pack="eva"></nb-icon>
                                </nb-form-field>
                                <div class="errorText" *ngIf="!confirmpasswordentry.valid && confirmpasswordentry.touched">Please confirm your new password.</div>
                            </div> <!-- form-control-group -->
                        </div> <!-- col -->
                    </div> <!-- row -->

                    <div class="row mb-2">
                        <div class="col">
                            <button type="submit" [nbSpinner]="processing" nbSpinnerStatus="control" nbButton status="primary"  [disabled]="!f.valid || processing || !confirmPasswordCheck()">RESET</button>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col">
                            <div *ngIf="errorMsg.length > 0"><span class="errorText">{{errorMsg}}</span></div>
                        </div>
                    </div>

                </div> <!-- container-fluid -->
              </form>
            </nb-card-body>
    </nb-card>
</div>