<div class="container-fluid">

    <div class="row no-gutters">
	    <div class="col-3">
          <span class="auction-label-text">CURRENT BID</span>
	    </div>

	    <div class="col-7 col-sm-3">
          <span class="auction-label-text">BIDDER NAME</span>
	    </div>

	    <div class="col-2 d-none d-sm-block">
          <span class="auction-label-text">ADMIN</span>
	    </div>

	    <div class="col-2 d-none d-sm-block">
          <span class="auction-label-text">BIDDER #</span>
	    </div>

	    <div class="col-2">
          <span class="auction-label-text flex-right">TIME</span>
	    </div>

    </div> <!-- header -->

    <div class="row no-gutters">
    	<div class="col-12">
		  <div *ngFor="let item of rows">
		     <app-auction-bidder-detail [bid]="item" [timezone]="timezone" type="auction" [currentBid]="isCurrentBid(item)"></app-auction-bidder-detail>
		     <div class="d-md-none mb-3"></div>
		   </div>
		</div>
	</div>

</div> <!-- container-fluid -->
