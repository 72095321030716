import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { LotModel } from '../../model/auction.model';
import { NbDialogService } from '@nebular/theme';
import { AuthhomeComponent } from '../../auth/authhome/authhome.component';
import { AuctionModelService } from '../../model/auction.model';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-bidding',
  templateUrl: './bidding.component.html',
  styleUrls: ['./bidding.component.scss']
})
export class BiddingComponent implements OnInit {
  errorMsg = "";
  processing: boolean = false;

  @Input() authenticated:boolean;
  @Input() lot:LotModel;
  @Input() previewMode:boolean = false;

  maxbid:string;
  activeBidderId:string=undefined;

  @ViewChild('apptimecounter') apptimecounter;

  @Output() messageEvent = new EventEmitter<any>();

  constructor(private dialogService: NbDialogService,
              private auctionModel: AuctionModelService,
              private authService:AuthService) { }

  ngOnInit(): void {
    this.maxbid = "";
  }

  async setMaxBid(){
    this.processing = true;
    this.errorMsg = "";
    try {
      var maxbidFloat = parseFloat(this.maxbid);
      this.lot = await this.lot.setMaxBid(maxbidFloat);
      this.processing = false;
    }
    catch(err){
      this.processing = false;
      this.errorMsg = "System error while setting max bid.";
      console.log('error while setting max bid',err);
    }

  }

  async bidNow(){
    this.processing = true;
    this.errorMsg = "";

    try {
      this.lot = await this.lot.bidNow(this.authService.activeUser.accountId);
      this.processing = false;
    }
    catch(err){
      console.log(err);
      this.processing = false;
      this.errorMsg = "Error submitting bid. Please try again.";
    }
  }

  onSignIn(){
    this.openAuth("signin");
  }

  openAuth(pageName){
    let localThis = this;
    this.dialogService.open(AuthhomeComponent, {
        hasScroll:false,
        closeOnBackdropClick:false,
        closeOnEsc:false,
        context:{
          authPageName:pageName
        }
      }).onClose.subscribe(event => localThis.processMsg(event));
  }

  getCurrentBid(){
    var returnValue = "";
    if(this.lot.currentBid != undefined && this.lot.startBid != undefined){
      returnValue = "" + (this.lot.currentBid > this.lot.startBid ? this.lot.currentBid : this.lot.startBid);
    }
    else if(this.lot.currentBid == undefined && this.lot.startBid != undefined){
      returnValue = "" + this.lot.startBid;
    }
    return returnValue;
  }

  getFinalBid(){
    return this.lot.currentBid != undefined ? "$" + this.lot.currentBid : "";
  }
  
  processMsg(event){
    if(event != undefined){
      var msg = event['message'];
      if(msg == "forgotpassword"){
        this.openAuth("forgotpassword");
      }
      else if(msg== "signup"){
        this.openAuth("signup");
      }
      else if(msg== "changepassword"){
        this.openAuth("changepassword");
      }
      else if(msg== "signin"){
        this.openAuth("signin");
      }
      else if(msg== "signupcomplete"){
      }
      else if(msg== "signinsuccessful"){
        var eventMsg = {}
        eventMsg['message'] = 'signinsuccessful';
        this.messageEvent.emit(eventMsg);
      }
    }
  }

  getBidNowStr(){
    var returnValue = "";
    if(this.lot.nextBid != undefined ){
      returnValue = " BID $" + this.lot.nextBid + " NOW";
    }
    else if(this.lot.nextBid == undefined && this.lot.startBid != undefined){
      returnValue = " BID $" + this.lot.startBid + " NOW";
    }

    return returnValue;
  }

  getCurrentBidTitle(){
    return (this.lot.bidCount != undefined && this.lot.bidCount > 0) ? "CURRENT BID" : "STARTING BID"
  }

  isWinner(){
    var returnValue = false;
    if(this.lot.bidderAccountId != undefined &&
       this.authService.activeUser != undefined &&
       this.authService.activeUser.accountId != undefined){
          returnValue = this.lot.bidderAccountId == this.authService.activeUser.accountId;
    }
    return returnValue;
  }

  isBidderDisabled(){
    var returnValue = false;
    if(this.lot.biddingDisabled != undefined){
      returnValue = this.lot.biddingDisabled;
    }
    return returnValue;
  }

  isLive(){
    return this.auctionModel.activeAuction.isLive();
  }

  isClosed(){
    return this.auctionModel.activeAuction.isClosed();
  }

  bidButtonDisabled(){
    return this.isWinner() || this.isBidderDisabled() || !this.isLive() || this.getBidNowStr().length==0 || this.previewMode;
  }

  public getSubtotal() {
    return parseFloat(this.getCurrentBid()) * (this.lot.quantity || 0);
  }

  public hasMultipleQuantities() {
    return (this.lot.quantity || 0) > 1;
  }

  public loadPageData() {
    this.apptimecounter.loadPageData();
  }

}
