import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { AuctionModelService, AuctionModel } from '../../model/auction.model';

import * as moment from 'moment';

@Component({
  selector: 'app-auctions-list',
  templateUrl: './auctions-list.component.html',
  styleUrls: ['./auctions-list.component.scss']
})
export class AuctionsListComponent implements OnInit {
  errorMsg = "";
  processing: boolean = false;

  auctionList:AuctionModel[] = [];
  auctionListByMonths = {};
  sortedAuctionMonths = [];

  numberOfItemsOnPage: number = 24;
  featuredRowSize: number = 2;
  rowSize: number = 4;

  constructor(private auctionModel: AuctionModelService) { }

  ngOnInit(): void {
    this.loadAuctions(true);
  }

  async loadAuctions(forcereload=false){
    this.processing = true;
    try {
      this.auctionList = await this.auctionModel.loadOpenAuctions(forcereload);
      this.auctionList = this.auctionList.sort(this.compareEndTimeAsc);
      this.separateByMonths();
      this.createArrayByNumberOfItemsOnRow();
      this.processing = false;
    }
    catch(err){
      this.processing = false;
      console.log('error while retrieving open auctions list');
    }
  }

  createArrayByNumberOfItemsOnRow(){
    for(var key in this.auctionListByMonths){
      let auctionListInChunks = [];
      let chunkArray = [];
      let ctr = 0;

      for (let i = 0; i < this.auctionListByMonths[key].length; i++) {
        const item = this.auctionListByMonths[key][i];

        chunkArray.push(this.auctionListByMonths[key][i]);
        ctr++;
        let atEndOfRow = false;

        if (item.featured) {
          atEndOfRow = this.atEndOfFeaturedRowSize(ctr);
        } else {
          atEndOfRow = this.atEndOfRowSize(ctr);
        }

        if (atEndOfRow || this.atEndOfList(i, key)){
          auctionListInChunks.push(chunkArray);
          chunkArray = []
        }
      }
      this.auctionListByMonths[key] = auctionListInChunks;
      auctionListInChunks = [];
    }
  }

  private atEndOfFeaturedRowSize(currentCounter: number) {
    return (currentCounter % this.featuredRowSize) == 0
  }

  private atEndOfRowSize(currentCounter: number) {
    return (currentCounter % this.rowSize) == 0
  }

  private atEndOfList(currentIndex: number, currentKey: string) {
    return this.auctionListByMonths[currentKey].length == currentIndex + 1;
  }

  compareEndTimeAsc(a: AuctionModel, b: AuctionModel) {
    let firstDate = moment(a.endTime).toDate();
    let secondDate = moment(b.endTime).toDate();

    if ( firstDate > secondDate ){
      return 1;
    }
    else if ( firstDate < secondDate ){
      return -1;
    }
    else {
      return 0;
    }
  }

  separateByMonths(){
    let monthName = "";
    this.sortedAuctionMonths = [];
    this.auctionListByMonths = {};

    for(let item of this.auctionList){
      let endTimeMoment = moment(item.endTime);
      let currentMonth = endTimeMoment.format('MMMM YYYY');
      if(currentMonth != monthName){
        monthName = currentMonth;
        this.auctionListByMonths[currentMonth] = []
        this.sortedAuctionMonths.push(monthName);
      }
      const featured = endTimeMoment.diff(moment(), 'days') <= 7;
      this.auctionListByMonths[monthName].push({ featured: featured, item: item });
    }
  }

}
