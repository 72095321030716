
<div *ngIf="authPageId==1">
    <app-signin (messageEvent)="receiveMessage($event)"></app-signin>
</div>

<div *ngIf="authPageId==2">
    <app-changepassword (messageEvent)="receiveMessage($event)"></app-changepassword>
</div>

<div *ngIf="authPageId==3">
    <app-forgotpassword (messageEvent)="receiveMessage($event)"></app-forgotpassword>
</div>

<div *ngIf="authPageId==4">
    <app-signup (messageEvent)="receiveMessage($event)"></app-signup>
</div>
