import { Component, OnInit, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { Timezones } from '../../services/timezones';
import { AuctionModel } from '../../model/auction.model';
import { isEmpty } from '../../utils/object.util';
@Component({
  selector: 'app-auction-details-banner',
  templateUrl: './auction-details-banner.component.html',
  styleUrls: ['./auction-details-banner.component.scss']
})
export class AuctionDetailsBannerComponent implements OnInit {
  @Input() auction: AuctionModel;
  timezones;
  timezoneRecord;

  @ViewChild('apptimecounter') apptimecounter;

  constructor() { }

  ngOnInit(): void {
    this.timezones = Timezones.timezones;
    this.timezoneRecord = this.timezones.find((el)=>{return el.timezone == (this.auction || {}).timezone});
    if(this.timezoneRecord == undefined){
      this.timezoneRecord = this.timezones.find((el)=>{return el.timezone == "US/Eastern"});
    }
  }

  getFormattedDate(dateString: string){
      var date = Timezones.getDateWithTimezoneApplied_moment(dateString, this.timezoneRecord.timezone);
      return Timezones.readableDateTimeMoment(date) + " " + this.timezoneRecord['short'];
  }

  getLocation(){
      const city = this.tryGetString(this.auction.sellerCity);
      const state = this.tryGetString(this.auction.sellerState);

      return city + (!isEmpty(city) && !isEmpty(state) ? ", " : "") + state;
  }

  tryGetString(value: any): string {
    return !isEmpty(value) ? value : "";
  }

  valueExists(value: any) {
    return !isEmpty(value);
  }

  isConsignmentSale() {
    return this.auction.businesses && this.auction.businesses.length > 0;
  }

  public loadPageData() {
    this.apptimecounter.loadPageData();
  }

}
