<div class="auction-background">
  <div class="auction-banner-background"></div>
</div>
<div class="auction-banner-container" *ngIf="auction">
  <!--<div class="auction-banner-header" *ngIf="!isConsignmentSale()">{{ auction.businessName }}</div>-->
  <div class="auction-banner-body d-flex flex-wrap">
    <div class="auction-banner-logo-container">
      <img class="auction-banner-logo" src="{{ auction.getLogoUrl() }} ">
    </div>
    <div class="col pt-5 px-0 px-sm-3">
      <div class="auction-banner-time">Sale Ends:</div>
      <div class="auction-banner-time">{{ getFormattedDate(auction.endTime) }}</div>
      <div class="auction-banner-title mt-4">{{ auction.title }}</div>
      <ng-container *ngIf="valueExists(getLocation())">
        <div class="row mt-3">
          <div class="col flex-row align-items-center">
            <nb-icon icon="pin-outline" class="location-icon"></nb-icon>
            <span class="auction-banner-location ml-2">{{ getLocation() }}</span>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="auction-time-remaining-counter-container d-md-block d-none">
      <app-auction-time-remaining-counter #apptimecounter></app-auction-time-remaining-counter>
    </div>
  </div>
</div>