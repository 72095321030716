import { EventEmitter, Injectable, Output } from '@angular/core';

export interface Event {
  message: string;
  payload?: any;
}

@Injectable()
export class EventService {

  @Output() message$: EventEmitter<Event> = new EventEmitter();

  public createEvent(event: Event) {
    this.message$.emit(event);
  }
}