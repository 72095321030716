import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-buyer-home',
  templateUrl: './buyer-home.component.html',
  styleUrls: ['./buyer-home.component.scss']
})
export class BuyerHomeComponent implements OnInit {

  livestockInsuranceAdCards = [];
  truckingAdCards = [];

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.loadLivestockInsuranceAdCards();
    this.loadTruckingAdCards();
    
    this.route.fragment.subscribe(f => {
      setTimeout(() => {
        const element = document.querySelector("#" + f);
        if (element) {
          const offset = -100;
          const y = element.getBoundingClientRect().top + window.pageYOffset + offset;
          window.scrollTo({ top: y, behavior: "smooth" });
        }
      }, 250)
    })
  }
  
  loadLivestockInsuranceAdCards(){
    this.livestockInsuranceAdCards = [];
    var adCard = {
      "logourl": "./assets/images/sp_jamesFBesslerInc.png",
      "company": "James F. Bessler Inc.",
      "addressline1": "518 Brownstone Drive",
      "addressline2": "St. Charles, IL 60174-2807",
      "cell": "(815) 762-2641",
      "office": "(630) 945-3483",
      "fax": "(630) 945-3584",
      "email": "Jim@JamesFBessler.com",
      "emailsubject": "Livestock Insurance"
    }
    this.livestockInsuranceAdCards.push(adCard);

    var adCard1 = {
      "logourl": "./assets/images/sp_dakotaAGInsurance.png",
      "company": "Dakota AG Insurance",
      "name": "Jarod Mohnen",
      "cell": "(815) 762-2641",
      "office": "(630) 945-3483",
      "fax": "(630) 945-3584",
      "email": "jm@dakotaaginsurance.com",
      "emailsubject": "Livestock Insurance"
    }
    this.livestockInsuranceAdCards.push(adCard1);
    
  }
  
  loadTruckingAdCards(){
    this.truckingAdCards = [];
    var adCard = {
      "logourl": "./assets/images/sp_lathropLivestockTransporation.png",
      "company": "Lathrop Livestock Transporation",
      "name": "Randy Lathrop",
      "addressline1": "35W090 Lathrop Lane",
      "addressline2": "Dundee, IL 60118",
      "cell": "(847) 426-5009",
      "fax": "(847) 428-3788",
    }
    this.truckingAdCards.push(adCard);
    
  }
}
