import { AuctionModel } from '../model/auction.model';
import moment from 'moment-timezone';

export class AuctionTimeStatistics {
  private auction: AuctionModel

  constructor(auction: AuctionModel) {
    this.auction = auction;
    if (!moment.utc(auction.startTime).isValid()) throw new Error("Invalid start date.")
    if (!moment.utc(auction.endTime).isValid()) throw new Error("Invalid end date.")
  }

  public isBeforeAuction(): boolean {
    return moment.utc(this.auction.startTime).isAfter(moment.utc())
  }

  public isAfterAuctionStart(): boolean {
    return moment.utc(this.auction.startTime).isSameOrBefore(moment.utc())
  }

  public isBeforeEndOfAuction(): boolean {
    return moment.utc(this.auction.endTime).isSameOrAfter(moment.utc())
  }

  public isClosed(): boolean {
    return moment.utc().isSameOrAfter(moment.utc(this.auction.endTime))
  }

  public numDaysBeforeAuctionStart(): number {
    return Math.round(this.durationBeforeAuctionStart().asDays())
  }

  public numSecondsBeforeAuctionStart(): number {
    return Math.round(this.durationBeforeAuctionStart().asSeconds())
  }

  public numHoursBeforeAuctionStart(): number {
    return Math.round(this.durationBeforeAuctionStart().asHours())
  }

  public numMinutesBeforeAuctionStart(): number {
    return Math.round(this.durationBeforeAuctionStart().asMinutes())
  }

  public numMinutesSecondsBeforeAuctionStart(): string {
    return moment.utc(this.durationBeforeAuctionStart().as('milliseconds')).format('mm:ss')
  }

  public numDaysBeforeAuctionEnd(): number {
    return Math.round(this.durationBeforeAuctionEnd().asDays())
  }

  public numSecondsBeforeAuctionEnd(): number {
    return moment.utc(this.auction.endTime).diff(moment.utc(), 'seconds')
  }

  public durationBeforeAuctionStart(): moment.Duration {
    return moment.duration(moment.utc(this.auction.startTime).diff(moment.utc()))
  }

  public durationBeforeAuctionEnd(): moment.Duration {
    return moment.duration(moment.utc(this.auction.endTime).diff(moment.utc()))
  }

  public numHoursMinutesBeforeAuctionStart(): string {
    const duration = this.durationBeforeAuctionStart()
    return `${Math.floor(duration.asHours())}h ${moment.utc(duration.asMilliseconds()).format("m")}m`
  }

  public numHoursBeforeAuctionEnd(): number {
    return this.durationBeforeAuctionEnd().asHours()
  }

  public numMinutesBeforeAuctionEnd(): number {
    return this.durationBeforeAuctionEnd().asMinutes()
  }

  public numMinutesSecondsBeforeAuctionEnd(): string {
    const momentBeforeAuctionEnd = moment.utc(this.durationBeforeAuctionEnd().as('milliseconds'))
    return momentBeforeAuctionEnd.format('mm:ss')
  }

  public numHoursMinutesBeforeAuctionEnd(): string {
    const duration = this.durationBeforeAuctionEnd()
    return `${Math.floor(duration.asHours())}h ${moment.utc(duration.asMilliseconds()).format("m")}m`
  }

  public auctionCurrentlyActive(): boolean {
    return this.isAfterAuctionStart() && this.isBeforeEndOfAuction()
  }

  public multipleDaysRemainBeforeStartOrEnd(): boolean {
    return (this.isBeforeAuction() && this.numHoursBeforeAuctionStart() > 24) || (this.auctionCurrentlyActive() && this.numHoursBeforeAuctionEnd() > 24)
  }

  public closingExtensionActive(): boolean {
    return this.auction.closingExtensionEnabled && this.auction.closingExtensionTriggered
  }
}