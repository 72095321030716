<div class="container-fluid">
  <!--<div class="auction-background">-->
  <!--  <img srcset="-->
  <!--      assets/images/header/header_xl.png 2450w,-->
  <!--      assets/images/header/header_l.png 1199w,-->
  <!--      assets/images/header/header_m_992.png 992w,-->
  <!--      assets/images/header/header_s_768.png 771w,-->
  <!--      assets/images/header/header_xs_576.png 577w"-->
  <!--    sizes="-->
  <!--    (max-width: 576px) 480px,-->
  <!--      100%"-->
  <!--    src="assets/images/header/header_xl.png"-->
  <!--    alt="Auction header background"-->
  <!--    class="img-background">-->

  <!--  <div class="title">Your online source for<br/>QUALITY GENETICS.</div>-->
  <!--</div>-->

  <app-auctions-list></app-auctions-list>
</div> <!-- END container-fluid -->