import { Component, OnInit, EventEmitter, Output, Input, QueryList, ViewChildren } from '@angular/core';
import { AuctionModel, LotModel } from '../../model/auction.model';
// import cloneDeep from 'lodash/cloneDeep';


@Component({
  selector: 'app-lotslist',
  templateUrl: './lotslist.component.html',
  styleUrls: ['./lotslist.component.scss']
})
export class LotslistComponent implements OnInit {
  @Input() auction:AuctionModel;
  @Input() authenticated:boolean;
  @Input() previewMode:boolean = false;
  // myLots:LotModel[] = undefined;

  @Output() messageEvent = new EventEmitter<any>();

  @ViewChildren("lotdetail") lotDetailComponents: QueryList<any>;

  constructor() { }

  ngOnInit(): void {
  }

  async receiveMessage($event) {
    this.messageEvent.emit($event);
  }

  public loadPageData() {
    this.lotDetailComponents.forEach(lotDetail => lotDetail.loadPageData());
  }

  // public loadLots(auction: AuctionModel) {
  //   // this.myLots = [];
  //   // this.myLots = [...auction.lots];
  //   console.log('loadLots');
  //   this.myLots = cloneDeep(this.auction.lots);
  //   console.log(this.myLots);
  // }

  // getLots(){
  //   if(this.myLots == undefined && this.lotsExist()){
  //     console.log('getLots : cloneDeep');
  //     this.myLots = cloneDeep(this.auction.lots);
  //     // this.myLots = [...this.auction.lots];
  //   }
  //   return this.myLots;
  // }

  lotsExist(){
    return this.auction != undefined && this.auction.lots != undefined && this.auction.lots.length > 0;
  }

}
