import { Component, OnInit, ViewChild, EventEmitter, Output, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuctionModelService, AuctionModel, LotModel } from '../../model/auction.model';
import { AuthService } from '../../services/auth.service';
import { AuctionChangeEventType } from '../../API.service'
import { VisibilityService, VisibilityState } from 'src/app/services/visibility.service';
import { EventService } from 'src/app/services/event.service';
import { ConnectivityService, ConnectivityState } from 'src/app/services/connectivity.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-auctiondetailshome',
  templateUrl: './auctiondetailshome.component.html',
  styleUrls: ['./auctiondetailshome.component.scss']
})
export class AuctiondetailshomeComponent implements OnInit {
  errorMsg = "";
  processing: boolean = false;

  _TAB_LABEL_LOTS             = "LOTS";
  _TAB_LABEL_SALE_INFORMATION = "SALE INFORMATION";
  _MAX_RETRIES                = 5;
  _PREVIEW_ROUTE              = "/previewauction";

  private auctionId: string = null;
  previewMode:boolean = false;
  auction:AuctionModel = undefined;
  displaystate = {}

  sub;

  @ViewChild('lotslist') lotslist;
  @ViewChild('lotsalesinformation') lotsalesinformation;
  @ViewChild('banner') banner;
  @Output() messageEvent = new EventEmitter<any>();

  constructor(private router: Router,
              private visibilityService: VisibilityService,
              private connectivityService: ConnectivityService,
              public authService: AuthService,
              private auctionModel: AuctionModelService,
              private eventService: EventService,
              private location: Location,
              private appRef: ApplicationRef) { }

  ngOnInit(): void {
      var localThis = this;

      var urlParms = this.location.path().split('/');
      this.auctionId = urlParms[urlParms.length - 1];

      if (this.router.url.startsWith(this._PREVIEW_ROUTE)) {
        this.previewMode = true;
      }
      this.loadAuction();

      this.visibilityService.message$.subscribe(async event => {
        if (event === VisibilityState.PAGE_HIDDEN) {
          this.eventService.createEvent({ message: "hide-timers" });

          localThis.ngOnDestroy();
        }
        if (event === VisibilityState.PAGE_VISIBLE) {
          var urlParms = this.location.path().split('/');
          this.auctionId = urlParms[urlParms.length - 1];
          await localThis.loadAuction();
          this.appRef.tick();
          this.eventService.createEvent({ message: "show-timers" });
        }
      });

      this.connectivityService.message$.subscribe(async event => {
        if (event === ConnectivityState.OFFLINE) {
          this.eventService.createEvent({ message: "hide-timers" });
        }
        if (event === ConnectivityState.ONLINE) {
          await localThis.loadAuction();
          this.appRef.tick();
          this.eventService.createEvent({ message: "show-timers" });
        }
      });
  }

  ngOnDestroy() {
     this.auctionModel.unsubscribeToAuctionUpdates();
  }

  lotsPosted(){
    var returnValue = false;
    if(this.previewMode){
      returnValue = true;
    }
    else{
      returnValue =  this.auction != undefined ? this.auction.lotsPosted() : false;
    }
    return returnValue;
  }

  saleInfoPosted(){
    var returnValue = false;
    if(this.previewMode){
      returnValue = true;
    }
    else{
      returnValue =  this.auction != undefined ? this.auction.saleInfoPosted() : false;
    }
    return returnValue;
  }

  isLive(){
    var returnValue = false;
    if(this.previewMode){
      returnValue = true;
    }
    else{
      returnValue = this.auction != undefined ? this.auction.isLive() : false;
    }
    return returnValue;
  }

  async receiveMessage($event) {
    if($event['message']=='accountsettings'){
      console.log($event);
    }
    else if($event['message']=='mybidsandsaves'){
      console.log($event);
    }
    else if($event['message']=='following'){
      console.log($event);
    }
    else if($event['message']=='mysales'){
      console.log($event);
    }
    else if($event['message']=='signinsuccessful'){
      this.setUserBiddingStatus();
    }
  }

  async loadAuction(){
    this.processing = true;
    try {
      await this.isLoggedOn();
      this.auction = await this.auctionModel.loadAuction(this.auctionId);
      // console.log('auction loaded');
      // this.auction = this.auctionModel.activeAuction;
      this.setUserBiddingStatus();

      // if (this.lotslist) {
      //   this.lotslist.loadLots(this.auction);
      // }

      if(!this.lotsPosted()){
        this.showPage(2);
      }

      await this.startPolling();
      this.processing = false;
    }
    catch(err){
      this.processing = false;
      console.log('error while retrieving auction',err);
    }
  }

  async reloadProfile(){
    this.processing = true;
    if(!this.authService.isAuthenticated()){
      return;
    }

    try {
      await this.authService.activeUser.me();
      this.processing = false;
    }
    catch(err){
      this.processing = false;
      console.log('error while retrieving auction',err);
    }
  }

  selectTab($event) {
    if($event.tabTitle ===this._TAB_LABEL_LOTS){
      this.showPage(1);
    }
    else if($event.tabTitle ===this._TAB_LABEL_SALE_INFORMATION){
      this.showPage(2);
    }
  }

  showPage(page:number){
    this.displaystate = {
      1: page==1 ? true : false,
      2: page==2 ? true : false
    }
  }

  isPageDisplayed(page:number){
    return this.displaystate[page] && this.auction != undefined;
  }

  async isLoggedOn(){
    let user = await this.authService.getAuthenticatedUser();
  }

  async startPolling(){
    try {
      var localThis = this;
      await this.auctionModel.subscribeToAuctionUpdates(this.auctionId,localThis, this.handleAuctionChangeNotification);
    }
    catch(err){
      this.errorMsg = "System error while starting bid subscription.";
      console.log('error while starting bid subscription',err);
    }
  }

  handleAuctionChangeNotification(updatedData, localThis){
    localThis.updateLotAfterAuctionUpdate(updatedData, localThis);
  }

  updateLotAfterAuctionUpdate(updatedData, localThis){
    // console.log('updateLotAfterAuctionUpdate',updatedData);
    if ([AuctionChangeEventType.LOT, AuctionChangeEventType.ALL].includes(updatedData.eventType)) {
      const bidData = updatedData.lotData;

      let lotIndex = this.auction.lots.findIndex((el)=>{return (el.auctionId == bidData.auctionId && el.lotId == bidData.lotId)});
      if(lotIndex != -1){
        this.auction.lots[lotIndex].bidCount = bidData.bidCount;
        this.auction.lots[lotIndex].bidIncrement = bidData.bidIncrement;
        this.auction.lots[lotIndex].bidderAccountId = bidData.bidderAccountId;
        this.auction.lots[lotIndex].bidderId = bidData.bidderId;
        this.auction.lots[lotIndex].currentBid = bidData.currentBid;
        this.auction.lots[lotIndex].nextBid = bidData.nextBid;
        this.auction.lots[lotIndex].startBid = bidData.startBid;
        this.auction.lots[lotIndex].biddingDisabled = bidData.biddingDisabled;

        if(this.authService.isAuthenticated()){
            this.auction.lots[lotIndex].winMsg = "";
            this.auction.lots[lotIndex].outBidMsg = "";
            let bidderInfo = this.authService.activeUser.activeBidderIds.find((el)=>{return (el.auctionId == bidData.auctionId && el.lotId == bidData.lotId)});

            if(bidData.bidderAccountId == this.authService.activeUser.accountId && bidderInfo != undefined){
              this.auction.lots[lotIndex].winMsg = LotModel.WINNING_BID_STR;
            }
            else if(bidData.bidderAccountId == this.authService.activeUser.accountId && bidderInfo == undefined){
              this.auction.lots[lotIndex].winMsg = LotModel.WINNING_BID_STR;
              this.reloadProfile();
            }
            else if (bidData.bidderAccountId != this.authService.activeUser.accountId && bidderInfo != undefined){
              this.auction.lots[lotIndex].outBidMsg = LotModel.OUT_BID_STR;
            }
          }
        }
    }
    if ([AuctionChangeEventType.AUCTION, AuctionChangeEventType.ALL].includes(updatedData.eventType)) {
      const activeAuction = localThis.auctionModel.activeAuction;
      const auctionData = updatedData.auctionData;

      if (activeAuction.auctionId === updatedData.auctionId) {
        Object.keys(auctionData).forEach(key => activeAuction[key] = auctionData[key] );
      }
      const auctionToUpdate = localThis.auctionModel.openAuctionsList.find(auction => auction.auctionId === updatedData.auctionId);
      if (auctionToUpdate) {
        Object.keys(auctionData).forEach(key => auctionToUpdate[key] = auctionData[key]);
      }

      localThis.messageEvent.emit({ message: "auction-updated" });

      this.banner.loadPageData();
      this.lotslist.loadPageData();
    }
  }

  setUserBiddingStatus(){
    if(this.authService.isAuthenticated()){
      for(let lot of this.auction.lots){
        lot.winMsg = "";
        lot.outBidMsg = "";
        let bidderInfo = this.authService.activeUser.activeBidderIds.find((el)=>{return (el.auctionId == lot.auctionId && el.lotId == lot.lotId)});
        if(bidderInfo != undefined){
          if(bidderInfo.bidderId == lot.bidderId){
            lot.winMsg = LotModel.WINNING_BID_STR;
          }
          else{
            lot.outBidMsg = LotModel.OUT_BID_STR;
          }
        }
      }
    }
  }

}
