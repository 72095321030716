<div>
  <div class="label mt-2">Select Time Frame</div>
  <nb-select size="medium" placeholder="Select Time Frame" [(selected)]="this.timeFrameOption" (selectedChange)="filterResults()">
    <nb-option *ngFor="let item of timeFrameOptions" [value]="item.key">{{ item.value }}</nb-option>
  </nb-select>

  <div class="mt-4">
    <app-buyer-auction-win-list [lineItems]="wonLineItems"></app-buyer-auction-win-list>
  </div>
</div>
