import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { PasswordCheck } from '../passwordcheck';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss']
})
export class ChangepasswordComponent implements OnInit {
  errorMsg = "";
  processing = false;
  
  showPassword:boolean = false;
  showNewPassword:boolean = false;
  showConfirmPassword:boolean = false;

  currentpassword:string = "";
  newpassword:string = "";
  confirmpassword:string = "";
  resetEmail:string;

  @Output() messageEvent = new EventEmitter<any>();

  constructor(private authService: AuthService){}

  ngOnInit(): void {
  }
  
  passwordCharLength(){
    return PasswordCheck.passwordCharLength(this.newpassword);
  }

  passwordUpperCase(){
    return PasswordCheck.passwordUpperCase(this.newpassword);
  }

  passwordLowerCase(){
    return PasswordCheck.passwordLowerCase(this.newpassword);
  }

  passwordSpecialChars(){
    return PasswordCheck.passwordSpecialChars(this.newpassword);
  }

  passwordNumber(){
    return PasswordCheck.passwordNumber(this.newpassword);
  }
  
  validPassword(){
    return PasswordCheck.validPassword(this.newpassword);
  }
  
  confirmPasswordCheck(){
    return (this.newpassword.length > 0) && (this.newpassword == this.confirmpassword);
  }

  close(){
    var eventMsg = {}
    eventMsg['message'] = 'closelogin';
    this.messageEvent.emit(eventMsg);
  }

  onToggleShowPassword(){
    this.showPassword = !this.showPassword;
  }
  
  onToggleShowNewPassword(){
    this.showNewPassword = !this.showNewPassword;
  }
  
  onToggleShowConfirmPassword(){
    this.showConfirmPassword = !this.showConfirmPassword;
  }
  
  
  onChangePassword(){
    this.errorMsg = "";

    if(this.newpassword != this.confirmpassword){
      this.errorMsg = "New password and confirmation password do not match";
      return;
    }
    
    this.processing = true;
    
    this.authService.changePassword(this.currentpassword,this.newpassword).then(response => {
        this.processing = false;
        this.errorMsg = "";
        this.signinSuccessful();
    }, error => {
        this.processing = false;
        this.errorMsg = error;
        return;
    });
  }
  
  async signinSuccessful(){
    await this.authService.getAuthenticatedUser();
    var eventMsg = {}
    eventMsg['message'] = 'signinsuccessful';
    eventMsg['portaluser'] = this.authService.activeUser;
    this.messageEvent.emit(eventMsg);
  }
  
  

}
