<div class="container-fluid container-horiz-padded r-top-margin">
  <div class="auction-list-card-container">
    <app-section-header underlineThickness="thick" type="primary">Auctions</app-section-header>

    <ng-container *ngFor="let month of sortedAuctionMonths">
        <div class="month-header">
            {{ month }}
        </div>

        <ng-container *ngFor="let auctionGroup of auctionListByMonths[month]">
          <div class="row mt-3">
            <div class="col d-flex flex-row flex-wrap auction-group-container">
              <div *ngFor="let auction of auctionGroup" class="auction-card-container">
                <app-auction-card class="auction-card" [auction]="auction.item" [featured]="auction.featured"></app-auction-card>
              </div>
            </div>
          </div>
        </ng-container>
    </ng-container>
  </div>
</div>
