import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { loadStripe, Stripe, StripeCardElement, StripeElement } from '@stripe/stripe-js';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-credit-card-entry-form',
  templateUrl: './credit-card-entry-form.component.html',
  styleUrls: ['./credit-card-entry-form.component.scss']
})
export class CreditCardEntryFormComponent implements OnInit {

  constructor() {}

  @Input() amountInCents: number;
  @Input() clientPaymentCode: string;
  @Output() messageEvent = new EventEmitter<any>();
  @ViewChild('cardNumber') cardNumberElement: ElementRef;
  @ViewChild('cardExpiry') cardExpiryElement: ElementRef;
  @ViewChild('cardCvc') cardCvcElement: ElementRef;

  private stripe: Stripe;
  private cardNumber: any;
  private cardExpiry: any;
  private cardCvc: any;
  public cardErrors;

  public loading = false;
  public confirmation: string;


  async ngOnInit() {
    this.stripe = await loadStripe(environment.stripe_publishable_key);

    const elements = this.stripe.elements();

    this.cardNumber = elements.create('cardNumber', {
      style: {
        base: {
          fontFamily: 'Open Sans, Segoe UI, sans-serif',
          fontSize: '16px',
          color: '#666666',
        }
      }
    });
    this.cardNumber.mount(this.cardNumberElement.nativeElement);

    this.cardExpiry = elements.create('cardExpiry', {
      style: {
        base: {
          fontFamily: 'Open Sans, Segoe UI, sans-serif',
          fontSize: '16px',
          color: '#666666',
        }
      }
    });
    this.cardExpiry.mount(this.cardExpiryElement.nativeElement);

    this.cardCvc = elements.create('cardCvc', {
      style: {
        base: {
          fontFamily: 'Open Sans, Segoe UI, sans-serif',
          fontSize: '16px',
          color: '#666666',
        }
      }
    });
    this.cardCvc.mount(this.cardCvcElement.nativeElement);

    this.cardNumber.on("change", ({ error }) => {
      this.cardErrors = error && error.message;
    });
  }

  async handleForm(e) {
    e.preventDefault();

    this.loading = true;
    const result = await this.stripe.confirmCardPayment(this.clientPaymentCode, {
      payment_method: {
        card: this.cardNumber
      }
    })

    if (result.error) {
      this.cardErrors = result.error.message;
    } else {
      if (result.paymentIntent.status === "succeeded") {
        this.messageEvent.emit({ message: "paymentSuccessful", data: result.paymentIntent })
      }
    }
    this.loading = false;
  }
}
