export class PasswordCheck {
    
  static passwordCharLength(text:string){
    return text.length >= 8;
  }

  static passwordUpperCase(text:string){
    return /[A-Z]{1,}/g.test(text);
  }

  static passwordLowerCase(text:string){
    return /[a-z]{1,}/g.test(text);
  }

  static passwordSpecialChars(text:string){
    return /[!@#$%^&*()]{1,}/g.test(text);
  }

  static passwordNumber(text:string){
    return /[0-9]{1,}/g.test(text);
  }
  
  static validPassword(text:string){
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/g.test(text);
  }
}