import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if(this.authService.isAuthenticated()===false){
      let user = await this.authService.getAuthenticatedUser();
    }

    if(this.authService.isAuthenticated()===false){
      // you can save redirect url so after authing we can move them back to the page they requested
      // console.log('AuthGuard:canActivate : state.url', state.url);
      // this.router.navigate([state.url]);
      this.router.navigate(['/']);
      return false;    
    }
    else{
      return true;
    }
  }
}

@Injectable()
export class AuthAdminGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if(this.authService.isAuthenticated()===false){
      let user = await this.authService.getAuthenticatedUser();
    }

    if(this.authService.isAuthenticated() && this.authService.activeUser != undefined && this.authService.activeUser.isAdmin()){
      return true;
    }
    else {
      this.router.navigate(['/']);
      return false;
    }
  }
}

@Injectable()
export class AuthSellerGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if(this.authService.isAuthenticated()===false){
      let user = await this.authService.getAuthenticatedUser();
    }
    
    if(this.authService.isAuthenticated() && this.authService.activeUser != undefined && this.authService.activeUser.isSeller()){
      return true;
    }
    else {
      this.router.navigate(['/']);
      return false;
    }
  }
}


