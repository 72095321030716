import { Component, OnInit, Input } from '@angular/core';
import { BidModel } from '../../model/auction.model';
import { Timezones } from '../../auth/timezones';

@Component({
  selector: 'app-auction-bidder-detail',
  templateUrl: './auction-bidder-detail.component.html',
  styleUrls: ['./auction-bidder-detail.component.scss']
})
export class AuctionBidderDetailComponent implements OnInit {

  @Input() bid:BidModel;
  @Input() timezone:string;
  @Input() currentBid:boolean=false;
  timezoneRecord;
  
  timezones;
  
  constructor() { }

  ngOnInit(): void {
    console.log('bidder detail',this.bid);
    this.timezones = Timezones.timezones;
    this.timezoneRecord = this.timezones.find((el)=>{return el.timezone == this.timezone});
    if(this.timezoneRecord == undefined){
      this.timezoneRecord = this.timezones.find((el)=>{return el.timezone == "US/Eastern"});
    }
  }
  
  getTime(datetimestr:string){
    let date = Timezones.getDateWithTimezoneApplied_moment(datetimestr,this.timezoneRecord.timezone);
    return Timezones.ampm_moment(date,this.timezoneRecord);
  }
  
  getBidPrice(){
    return this.bid.price != undefined ? "$" + this.numberWithCommas(this.bid.price) : "";
  }
  
  numberWithCommas(x:number) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }  
  
  getRowStyle(){
    return this.currentBid==true ? 'table-column-text-bold' : 'table-column-text';
  }

  getRowTimeStyle(){
    return this.currentBid==true ? 'table-column-time-text-bold' : 'table-column-time-text';
  }
  
}
