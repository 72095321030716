<div class="container-fluid">
    <div class="main-content" style="z-index:4">
      <app-header *ngIf="headerVisible"></app-header>
      <nb-layout>
        <nb-layout-column>
           <router-outlet></router-outlet>
        </nb-layout-column>
      </nb-layout>
      <app-footer *ngIf="footerVisible"></app-footer>
    </div>
</div>