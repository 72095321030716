<div class="container-fluid">

    <div class="row mb-3 mt-2">
        <div class="col center">
            <app-auction-time-remaining-counter #apptimecounter></app-auction-time-remaining-counter>
        </div>
    </div>

    <!--<div class="row mb-3">-->
    <!--    <div class="col center">-->
    <!--        <button nbButton outline size="tiny" status="primary"><nb-icon icon="external-link-outline" (click)="onShare()"></nb-icon></button>-->
    <!--        <span class="label-text ml-2">SHARE</span>-->
    <!--    </div> -->
    <!--</div>-->

    <div class="row mb-3" *ngIf="!auctionRunning">
        <div class="col center">
            <button nbButton outline size="tiny" status="primary" (click)="onFollowAuction()">FOLLOW AUCTION</button>
        </div>
    </div>

    <div class="row mb-3" *ngIf="auctionRunning && !authenticated">
        <div class="col center">
            <button nbButton size="tiny" status="primary" (click)="onSignIn()">SIGN IN TO BID</button>
        </div>
    </div>

</div>