import { Component, OnInit, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { AuctionModel } from '../../model/auction.model';
import { NbDialogService } from '@nebular/theme';
import { AuthhomeComponent } from '../../auth/authhome/authhome.component';
import * as moment from 'moment';

@Component({
  selector: 'app-auctiondetailssocial',
  templateUrl: './auctiondetailssocial.component.html',
  styleUrls: ['./auctiondetailssocial.component.scss']
})
export class AuctiondetailssocialComponent implements OnInit {
  errorMsg = "";
  processing: boolean = false;

  @Input() authenticated:boolean;
  @Input() auctionRunning:boolean;
  @Input() auction:AuctionModel;

  @ViewChild('apptimecounter') apptimecounter;

  constructor(private dialogService: NbDialogService) { }

  ngOnInit(): void {
  }

  onShare(){

  }

  onFollowAuction(){

  }

  onSignIn(){
    this.openAuth("signin");
  }

  openAuth(pageName){
    console.log('pageName',pageName);
    let localThis = this;
    this.dialogService.open(AuthhomeComponent, {
        hasScroll:false,
        closeOnBackdropClick:false,
        closeOnEsc:false,
        context:{
          authPageName:pageName
        }
      }).onClose.subscribe(event => localThis.processMsg(event));
  }

  processMsg(event){
    // console.log('processMsg:',event);
    if(event != undefined){
      var msg = event['message'];
      if(msg == "forgotpassword"){
        this.openAuth("forgotpassword");
      }
      else if(msg== "signup"){
        this.openAuth("signup");
      }
      else if(msg== "changepassword"){
        this.openAuth("changepassword");
      }
      else if(msg== "signin"){
        this.openAuth("signin");
      }
      else if(msg== "signupcomplete"){
      }
      else if(msg== "signinsuccessful"){
      }
      else if(msg== "auction-started"){
      }
      else if(msg== "auction-closed"){
      }
    }
  }

  public loadPageData() {
    this.apptimecounter.loadPageData();
  }
}
