<div class="loading-shade" *ngIf="processing"></div>

<div class="container-fluid" *ngIf="!auctionIsLive()">
    <div class="row mb-5">
        <div class="col-12">
            <span class="content-text">
                Auction is not live. Bid activity will display when the auction becomes live.
            </span>
        </div>
    </div>
</div>

<div class="container-fluid" *ngIf="auctionIsLive()">
    <div class="row mt-2 mb-4">
        <div class="col-lg-9">
            <div class="row mb-4">
                <div class="col">
                    <span class="content-text" style="font-weight:600">Lot Details</span>
                </div>
            </div>

            <div class="row">
                <div class="col-12 auction-label-text">
                    LOT
                </div>
            </div>

            <div class="row mb-4">
                <div class="col-12">
                    <nb-select  style="width:100%"
                                placeholder="Select Lot"
                                name="auctionlot"
                                id="auctionlot"
                                fullWidth
                                (selectedChange)="onSelectedLotChange($event)"
                                [(ngModel)]="activelotId">
                        <nb-option *ngFor="let item of auctionModel.activeAuction.lots" [value]="item.lotId">Lot {{ item.lotNum }} - {{ item.title }}</nb-option>
                    </nb-select>
                </div>
            </div>

            <!--<div class="row mt-4 ml-3 mb-4" *ngIf="activeLot != undefined">-->
            <!--    <div class="col-12 center">-->
            <!--        <app-lot-detail-desc [lot]="activeLot"></app-lot-detail-desc>-->
            <!--    </div>-->
            <!--</div>-->

            <div class="row">
                <div class="col-8 r-top center">
                    <table width="100%">
                        <tr>
                            <th class="auction-label-text">STARTING PRICE</th>
                            <th class="auction-label-text">FLOOR PRICE</th>
                            <th class="auction-label-text">INCREMENT</th>
                            <th></th>
                        </tr>
                        <tr>
                            <td class="table-column-text"> {{ getStartingBid() }}</td>
                            <td *ngIf="!editFloorPrice" class="table-column-text"> {{ getFloorPrice() }}</td>

                            <td *ngIf="editFloorPrice">
                                <nb-form-field>
                                    <nb-icon nbPrefix icon="dollar-sign" pack="fas" status="primary"></nb-icon>
                                    <input nbInput
                                           fullWidth
                                           style="width:150px"
                                           type="number"
                                           id="floorPrice"
                                           name="floorPrice"
                                           placeholder="Reserve Price"
                                           [(ngModel)]="activeLot.floorPrice">
                                </nb-form-field>
                            </td>

                            <td *ngIf="!editIncrement" class="table-column-text"> {{ getIncrement() }}</td>
                            <td *ngIf="editIncrement" class="table-column-text">
                                <nb-form-field>
                                    <nb-icon nbPrefix icon="dollar-sign" pack="fas" status="primary"></nb-icon>
                                    <input nbInput
                                           fullWidth
                                           style="width:150px"
                                           type="number"
                                           id="bidincrement"
                                           name="bidincrement"
                                           placeholder="Bid Increment"
                                           [(ngModel)]="activeLot.bidIncrement">
                                </nb-form-field>
                            </td>

                        </tr>
                        <!--<tr>-->
                        <!--    <td class="table-column-text"></td>-->
                        <!--    <td *ngIf="!editFloorPrice"><a [routerLink]="" class="link-text" style="font-weight:700" (click)="onEditFloorPrice()">EDIT</a></td>-->
                        <!--    <td *ngIf="editFloorPrice" style="padding-top:10px">-->
                        <!--        <button nbButton [nbSpinner]="processing" nbSpinnerStatus="control" size="tiny" status="primary" (click)="onUpdateFloorPrice()">UPDATE</button>-->
                        <!--        <button class="ml-3" nbButton outline size="tiny" status="primary" (click)="onCancelEditFloorPrice()">CANCEL</button>-->
                        <!--    </td>-->

                        <!--    <td *ngIf="!editIncrement"><a [routerLink]="" class="link-text" style="font-weight:700" (click)="onEditBidIncrement()">EDIT</a></td>-->
                        <!--    <td *ngIf="editIncrement" style="padding-top:10px">-->
                        <!--        <button nbButton [nbSpinner]="processing" nbSpinnerStatus="control" outline size="tiny" status="primary" (click)="onUpdateBidIncrement()">UPDATE</button>-->
                        <!--        <button class="ml-3" nbButton outline size="tiny" status="primary" (click)="onCancelEditBidIncrement()">CANCEL</button>-->
                        <!--    </td>-->
                        <!--</tr>-->
                    </table>
                </div>
                <div class="col-4 flex-right">
                    <table width="100%">
                        <tr>
                            <th class="auction-label-text" style="text-align:right">BIDDERS</th>
                            <th class="auction-label-text" style="text-align:right">BIDS</th>
                        </tr>
                        <tr>
                            <td class="label-text" style="font-size:22px;text-align:right"> {{ getBidderCount() }}</td>
                            <td class="label-text" style="font-size:22px;text-align:right"> {{ getBidCount() }}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="row mt-2 mb-2">
                <div class="col-12">
                    <hr>
                </div>
            </div>

            <!--<div class="row mb-2">-->
            <!--    <div class="col-5 auction-label-text">-->
            <!--        BIDDER NAME-->
            <!--    </div>-->
            <!--    <div class="col-5 flex-right">-->
            <!--        <a *ngIf="isAuctionLive()" [routerLink]="" class="link-text" style="font-weight:700" (click)="onNewBidder()">+ ADD BIDDER</a>-->
            <!--    </div>-->
            <!--</div>-->

            <!--<div class="row mb-2" *ngIf="hasBidders()">-->
            <!--    <div class="col-10 center">-->
            <!--        <nb-select  style="width:100%"-->
            <!--                    placeholder="Select Bidder"-->
            <!--                    name="bidderaccountid"-->
            <!--                    id="bidderaccountid"-->
            <!--                    fullWidth-->
            <!--                    (selectedChange)="onSelectedBidderChange($event)"-->
            <!--                    [(ngModel)]="bidderAccountId">-->
            <!--            <nb-option *ngFor="let item of auctionModel.activeAuction.bidders" [value]="item.bidderAccountId">{{ item.bidderFirstName }} {{ item.bidderLastName }}</nb-option>-->
            <!--        </nb-select>-->
            <!--    </div>-->
            <!--    <div class="col-2 flex-right justify-content-center align-self-center">-->
            <!--        <button *ngIf="isAuctionLive()" class="bid-button" [nbSpinner]="processing" nbButton size="small" status="primary" (click)="onBid()" [disabled]="!biddingOpen()"> {{ getBidStr() }}</button>-->
            <!--    </div>-->
            <!--</div>-->

            <div class="row mb-2" *ngIf="winMsg.length > 0">
                <div class="col-12">
                    <span class="successful-bid-text">{{ winMsg }}</span>
                </div>
            </div>

            <div class="row mb-2" *ngIf="outBidMsg.length > 0">
                <div class="col-12">
                    <span class="outbid-text">{{ outBidMsg }}</span>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-12" *ngIf="activeLot != undefined">
                    <app-auction-bidder-list #bidderlist [rows]="activeLot.bids" [timezone]="auctionModel.activeAuction.timezone" [lot]='activeLot'></app-auction-bidder-list>
                </div>
            </div>

            <div class="row mt-2 mb-2">
                <div class="col-12">
                    <hr>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <button nbButton size="small" shape="rectangle" (click)="onPreviousBidders()" [disabled]="!previousBidsAvail()">
                        <nb-icon *ngIf="previousBidsAvail()" status="primary" icon="arrow-ios-back-outline"></nb-icon>
                        <nb-icon *ngIf="!previousBidsAvail()" icon="arrow-ios-back-outline"></nb-icon>
                    </button>
                    <button nbButton size="small" shape="rectangle" (click)="onNextBidders()" [disabled]="!nextBidsAvail()">
                        <nb-icon *ngIf="nextBidsAvail()" status="primary" icon="arrow-ios-forward-outline"></nb-icon>
                        <nb-icon *ngIf="!nextBidsAvail()" icon="arrow-ios-forward-outline"></nb-icon>
                    </button>
                </div>
            </div>


        </div> <!-- left side content -->

        <!-- rigth side list of lot cards -->
        <div class="col-lg-3 pt-4">
          <div class="row mb-3">
            <div class="col">
              <span class="content-text" style="font-weight:600">Lot Summary</span>
            </div>
          </div>
          <div class="row mb-3">
              <div class="col-12">
                  <button nbButton size="small" (click)="onFilterByLotsStatus(1)" [status]="getStatus(1)" style="width:75px">LATEST 5</button>
                  <button nbButton size="small" (click)="onFilterByLotsStatus(2)" [status]="getStatus(2)" style="width:60px">TOP 5</button>
                  <button nbButton size="small" (click)="onFilterByLotsStatus(3)" [status]="getStatus(3)" style="width:75px">ALL LOTS</button>
              </div>
          </div>

          <ng-container *ngIf="lotSummaryList.length > 0">
              <div class="row mb-2" *ngFor="let item of lotSummaryList">
                  <div class="col-12">
                      <a [routerLink]="" (click)="onSelectLot(item.lotId)">
                          <app-lot-card [lot]="item" [timezone]="auctionModel.activeAuction.timezone" [bidder]="getBidder(item)"></app-lot-card>
                      </a>
                  </div>
              </div>
          </ng-container>


            <!-- <div class="row mb-4">
                <div class="col-12 center">
                    <span class="content-text ml-2" style="font-weight:600">Lot Summary</span>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12 center">
                    <button nbButton size="small" (click)="onFilterByLotsStatus(1)" [status]="getStatus(1)" style="width:75px">LATEST 5</button>
                    <button nbButton size="small" (click)="onFilterByLotsStatus(2)" [status]="getStatus(2)" style="width:60px">TOP 5</button>
                    <button nbButton size="small" (click)="onFilterByLotsStatus(3)" [status]="getStatus(3)" style="width:75px">ALL LOTS</button>
                </div>
            </div>

            <ng-container *ngIf="lotSummaryList.length > 0">
                <div class="row mb-2" *ngFor="let item of lotSummaryList">
                    <div class="col-12 center">
                        <a [routerLink]="" (click)="onSelectLot(item.lotId)">
                            <app-lot-card [lot]="item" [timezone]="auctionModel.activeAuction.timezone" [bidder]="getBidder(item)"></app-lot-card>
                        </a>
                    </div>
                </div>
            </ng-container> -->

        </div> <!-- rigth side list of lot cards -->

    </div>

</div> <!-- container-fluid -->
