<div class="container-fluid footer">
  <div class="row mt-4">
    <div class="col-sm-4 mb-2">
        <div class="row footer-block">
            <div class="col">
                <app-section-header class="footer-header">Auction Platform Office</app-section-header>
                <div class="footer-address-line">(406) 571-3006</div>
                <div class="footer-address-line mt-1"><a href="mailto:sales@g2landauctions.com">sales@g2landauctions.com</a></div>
            </div>
        </div>

    </div>

    <div class="col-sm-4 footer-side-border">
      <div class="row footer-block">
          <div class="col">
            <app-section-header class="footer-header">Physical Address</app-section-header>
            <div class="footer-address-line">41338 Ridgeway Road</div>
            <div class="footer-address-line">Charlo, MT 59824</div>
          </div>
      </div>

      <div class="row footer-block mt-3 mt-sm-3">
          <div class="col">
            <p class="version-number">v1.1.8</p>
          </div>
      </div>

    </div>

    <!--<div class="col-sm-4 footer-side-border">-->
    <!--  <div class="row footer-block">-->
    <!--      <div class="col">-->
    <!--          <div class="footer-nav-link"><a routerLink="/auctions">Auctions</a></div>-->
    <!--          <div class="footer-nav-link mt-2"><a routerLink="/buyers">Buyers</a></div>-->
    <!--          <div class="footer-nav-link mt-2"><a routerLink="/sellers">Sellers</a></div>-->
    <!--          <div class="footer-nav-link mt-2"><a routerLink="/community">Community</a></div>-->
    <!--      </div>-->
    <!--  </div>-->
    <!--  <div class="row footer-block mt-3">-->
    <!--      <div class="col">-->
    <!--          <app-section-header class="footer-header">Services</app-section-header>-->
    <!--          <div class="footer-address-line">The Angus Live Story</div>-->
    <!--          <div class="footer-address-line mt-1">Our Team</div>-->
    <!--      </div>-->
    <!--  </div>-->
    <!--</div>-->

    <!--<div class="col-sm-4 footer-side-border mt-3 mt-sm-0">-->
    <!--  <div class="row footer-block footer-block-side-border">-->
    <!--    <div class="col">-->
    <!--      <div>-->
    <!--        <a target="_blank" href="https://www.facebook.com/g2landauctionsAuction"><img src="../../../assets/images/facebook-fill.svg" class="social-button"></a>-->
    <!--        <a target="_blank" href="https://www.instagram.com/g2landauctionsg2/"><img src="../../../assets/images/instagram-fill.png" class="social-button ml-4"></a>-->
    <!--        <a target="_blank" href="https://twitter.com/live_angus"><img src="../../../assets/images/twitter-fill.svg" class="social-button ml-4"></a>-->
    <!--      </div>-->
    <!--      <div class="mt-4">-->
    <!--        <a target="_blank" href="https://www.youtube.com/channel/UCZUxDN5gyn_MGwfG3CBupVg/featured"><img src="../../../assets/images/youtube-fill.svg" class="youtube-button"></a>-->
    <!--      </div>-->
    <!--      <div class="mt-4">-->
    <!--        <p class="version-number">v1.1.6</p>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--  </div>-->

    <!--</div>-->
  </div>
</div>