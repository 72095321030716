<form (submit)="handleForm($event)">
  <div class="row mt-3">
    <div class="col">
      <div #cardNumber id="card-number" class="credit-card-entry-item"></div>
    </div>
  </div>
  <div class="row small-gutter mt-3">
    <div class="col-5 card-item">
      <div #cardExpiry id="card-expiry" class="credit-card-entry-item"></div>
    </div>
    <div class="col-3 card-item">
      <div #cardCvc id="card-cvc" class="credit-card-entry-item"></div>
    </div>
    <div class="col-4 card-item text-right">
      <input id="zip" data-tid="elements_examples.form.postal_code_placeholder" class="credit-card-entry-item zip-item" placeholder="94107">
    </div>
  </div>
  <p>{{ cardErrors }}</p>
  <button nbButton [nbSpinner]="loading" status="primary" class="mt-2" type="submit">PAY {{ amountInCents / 100.0 | currency }} NOW</button>
</form>